export const USER_ROLES = {
  ADMIN: 1,
  WORKER: 2,
};

// export const API_URL = "http://127.0.0.1:8000/api";
export const API_URL = "https://backend.haningejouren.se/api";

export const AuthErrors = {
  TOKEN_EXPIRED: "token-expired",
  INVALID_CREDENTIALS: "invalid-credentials",
  TOKEN_NOT_FOUND: "token-not-found",
};

export const API_ERROR = {
  NOT_FOUND: "not-found",
  NOT_AUTHORIZED: "not-authorized",
  UNKNOWN_ERROR: "unkown-error",
};

export const ERRORS = {
  NULL_OR_EMPTY: "null-or-empty",
};
