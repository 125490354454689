import { useState, useEffect, useContext } from "react";
import {
  Typography,
  TextField,
  Grid,
  Card,
  IconButton,
  InputAdornment,
  Button,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { MobileStateContext } from "../contexts/MobileContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/hooks/useAuth";
import { SnackbarContext } from "../contexts/SnackbarProvider";
import { AuthErrors } from "../utils/constants";

interface inputProps {
  email: string | null;
  password: string | null;
  [key: string]: any;
}
interface LocationState {
  from: {
    pathname: string;
  };
}

/**
 * Login form
 * @returns
 */
export default function Login() {
  const navigate = useNavigate();

  const location = useLocation();

  const from = (location.state as LocationState)?.from;

  const snackBar = useContext(SnackbarContext);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [state, setState] = useState<inputProps>({
    email: null,
    password: null,
  });

  const auth = useAuth();

  // Navigate to admin site or jour report when user logged in
  useEffect(() => {
    if (!auth.loading && auth.user) {
      if (auth.isAdmin()) {
        if (from) navigate(from);
        else navigate("/orders");
      } else navigate("/report");
    }
  }, [auth]);

  /**
   * Update form variables
   */
  const handleChange = (id: string, newValue: string) => {
    setState({
      ...state,
      [id]: newValue,
    });
  };

  //   /**
  //    * Sends the form and logs in the user
  //    */
  //   const fetchData = () => {
  //     getAllUsers(auth.tokens).then((res) => {
  //       console.log(res.data);
  //     });
  //   };

  /**
   * Sends the form and logs in the user
   */
  const sendForm = async (e: any) => {
    e.preventDefault();
    if (!state.email || !state.password) {
      return;
    }
    const res = await auth.login(state.email, state.password);
    console.log(res);
    if (!res.success) {
      // snackBar.show("Felaktiga inloggningsuppgifter", "error");
      snackBar.show(`${res.errorMessage}`, "error");
    } else {
      snackBar.show("Inloggning lyckades!", "success");
    }
  };

  //   if (auth.user) {
  //     return (
  //       <Box>
  //         Logged in!
  //         <Button onClick={() => auth.logout()}>Log out</Button>
  //         <Button onClick={getCompanies}>HÄMTA ANVÄNDARE</Button>
  //       </Box>
  //     );
  //   }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={6}>
        <Card>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Logga in
          </Typography>
          <CardContent>
            <form onSubmit={(e) => sendForm(e)}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={11}>
                  <TextField
                    value={state.email || ""}
                    label="Email"
                    color="secondary"
                    onChange={(event) =>
                      handleChange("email", event.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    // error={error ? setError("Email", error) : false}
                    // helperText={error ? setErrorText("Email", error) : ""}
                    type="email"
                    required
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    value={state.password || ""}
                    type={showPassword ? "text" : "password"}
                    label="Lösenord"
                    color="secondary"
                    onChange={(event) =>
                      handleChange("password", event.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    required
                    // error={error ? setError("Password", error) : false}
                    // helperText={error ? setErrorText("Password", error) : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            //   onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Link to="/reset-password">
                    <Typography>Glömt ditt lösenord?</Typography>
                  </Link>
                </Grid>
                <Grid item xs={11}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    size="large"
                    type="submit"
                    disabled={auth.loading}
                  >
                    {auth.loading ? (
                      <CircularProgress size={"1.5rem"} />
                    ) : (
                      "Logga in"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
