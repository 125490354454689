import { Grid } from "@mui/material";

interface PopupProps {
  children: React.ReactElement;
}

export default function CenteredGrid({ children }: PopupProps) {
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Grid>
  );
}
