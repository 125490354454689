import { useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import { MobileStateContext } from "../contexts/MobileContext";
import CompanyInfo from "../components/companies/CompanyInfo";
import { Company } from "../utils/types";
import CompanyFormModal from "../components/companies/CompanyFormModal";
import CompanyInfoModal from "../components/companies/CompanyInfoModal";
import { useAPI } from "../API/useAPI";
import { SnackbarContext } from "../contexts/SnackbarProvider";
import { useAuth } from "../auth/hooks/useAuth";
import CompanyCard from "../components/companies/CompanyCard";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

/**
 * Component for Companies
 * @returns
 */
export default function Companies() {
  const auth = useAuth();
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);

  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);
  const [activeCompany, setActiveCompany] = useState<Company | undefined>();
  const [companies, setCompanies] = useState<Array<Company>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState<Company | undefined>(
    undefined
  );
  const [error, setError] = useState<boolean>(false);
  const [modals, setModals] = useState({
    companyForm: false,
    companyInfo: false,
  });

  function changeModal(type: string, value: boolean) {
    setModals({
      ...modals,
      [type]: value,
    });
  }

  /**
   * Check for auth updates and call getCompanies function
   */
  useEffect(() => {
    if (auth.isAdmin()) {
      getCompanies();
    }
  }, [auth]);

  /**
   * Get companies from api
   * Update isLoading variable
   */
  const getCompanies = async () => {
    console.log("get Companies");
    setIsLoading(true);
    if (auth.isAdmin()) {
      const res = await api.companies.all();
      if (res.error) {
        snackBar.show("Kunde inte hämta bolag.", "error");
      } else {
        const companies = res.data!.data
        const non_archived = companies.filter(c => !c.archived)
        setCompanies(non_archived);
      }
    }
    closeInfoModal();
    setIsLoading(false);
  };

  function handleNewCompanyButton() {
    setEditCompany(undefined);
    changeModal("companyForm", true);
  }

  function closeInfoModal() {
    changeModal("companyForm", false);
    setActiveCompany(undefined);
  }

  return (
    <Grid display="flex" sx={isMobile ? { p: "10px" } : { p: "30px 70px" }}>
      <Grid sx={isDesktop ? { width: "60%" } : { width: "100%" }}>
        <Typography variant="h2">Bolag</Typography>
        {/* Show circular progress when companies are fetching */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {error ? (
              <Typography variant="h4">
                Något gick fel. Kunde inte ladda innehåll.
              </Typography>
            ) : (
              <Grid container spacing={3}>
                {companies &&
                  companies.map((company, index) => (
                    <CompanyCard
                      key={index}
                      company={company}
                      active={company.id === activeCompany?.id}
                      changeModal={changeModal}
                      setActiveCompany={setActiveCompany}
                    />
                  ))}
                {/* NEW COMPANY BUTTON */}
                <Grid item xs={isMobile ? 6 : 4}>
                  <Button
                    variant="outlined"
                    onClick={() => handleNewCompanyButton()}
                    sx={[
                      {
                        borderRadius: "10px",
                        p: 2,
                        width: "100%",
                        height: "100%",
                        border: "1px dashed",
                        alignItems: "flex-start",
                        borderColor: "#e0e0e0",
                        "&:hover": {
                          background: "#f0f0f0",
                          borderColor: "#e0e0e0",
                        },
                      },
                    ]}
                  >
                    <Grid sx={{ width: "100%" }}>
                      <Typography
                        align="left"
                        sx={[
                          isMobile
                            ? { textAlign: "center" }
                            : {
                                marginBottom: 2,
                              },
                          {
                            overflowWrap: "break-word",
                            fontSize: "20px",
                            textTransform: "none",
                            fontWeight: 400,
                          },
                        ]}
                      >
                        Nytt bolag
                      </Typography>
                      {!isMobile && (
                        <Grid
                          sx={{
                            display: "flex",
                            m: "5px",
                            alignItems: "center",
                            padding: "5px",
                            border: 1,
                            borderRadius: "5px",
                            borderColor: "#e0e0e0",
                          }}
                          item
                          // md={12}
                          // xl={3}
                          xs={12}
                        >
                          <AddBoxOutlinedIcon
                            sx={{ color: "#3A3A3CCC", marginRight: "10px" }}
                          />
                          <Typography
                            sx={{ textTransform: "none", fontWeight: 600 }}
                          >
                            {/* {variable} {showText && text} */}
                            Skapa nytt bolag
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {isDesktop && (
        <Divider
          orientation="vertical"
          flexItem
          sx={{ m: "20px" }}
          textAlign="left"
        />
      )}
      {activeCompany ? (
        !isDesktop ? (
          <CompanyInfoModal
            companyName={activeCompany.name}
            open={modals.companyInfo}
            // handleClose={() => changeModal("companyInfo", false)}
            handleClose={closeInfoModal}
          >
            {/* <Grid sx={{ width: "40%" }}> */}
            <CompanyInfo
              company={activeCompany}
              getCompanies={getCompanies}
              setEditCompany={setEditCompany}
              showCompanyForm={() => changeModal("companyForm", true)}
            />
            {/* </Grid> */}
          </CompanyInfoModal>
        ) : (
          <Grid sx={{ width: "40%" }}>
            <CompanyInfo
              company={activeCompany}
              getCompanies={getCompanies}
              setEditCompany={setEditCompany}
              showCompanyForm={() => changeModal("companyForm", true)}
            />
          </Grid>
        )
      ) : (
        isDesktop && (
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "40%" }}
          >
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              Tryck på ett bolag för mer information
            </Typography>
          </Grid>
        )
      )}

      {modals.companyForm && (
        <CompanyFormModal
          open={modals.companyForm}
          handleClose={() => changeModal("companyForm", false)}
          company={editCompany}
          getCompanies={getCompanies}
        />
      )}
    </Grid>
  );
}
