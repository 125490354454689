import React, { useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  placeholder: string;
  searchValue: string | null;
  setSearchValue: (s: string | null) => void;
  // filterData: (value: string) => void;
}
/**
 * Component for searching and filtering data
 * @returns Searchbar component
 */
export default function SearchBar(props: Props) {
  const [searchValue, setSearchValue] = React.useState<string>("");

  // add delay to
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      props.setSearchValue(
        // if the search string is an empty string, send back null instead
        // since that will not be included in query params
        !searchValue || searchValue === "" ? null : searchValue
      );
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  return (
    <TextField
      id="search-field"
      sx={{
        // m: 1,
        boxSizing: "border-box",
        p: 1,
        maxWidth: "100%",
        borderRadius: 1,
        backgroundColor: "#ededed",
      }}
      onChange={(e) => {
        setSearchValue(e.target.value);
        // props.filterData(e.target.value);
      }}
      value={searchValue || ""}
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        disableUnderline: true, // <== added this
      }}
      fullWidth
      placeholder={props.placeholder}
    />
  );
}
