import { User, UserUpdate } from "./types";

export const userFullname = (user: User | UserUpdate) => {
  return `${user.first_name} ${user.last_name}`;
};

export const removeDuplicates = (a: any[]) => {
  return a.filter(function (item, pos) {
    return a.indexOf(item) === pos;
  });
};

export const isNullOrEmpty = (val: string | undefined) => {
  if (val === undefined) return true;
  return val === null || val.trim() === "";
};

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();
