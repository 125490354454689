import { Alert, AlertTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
  handleClose: () => void;
  open: boolean;
  title: string;
  onConfirm: () => void;
}
/**
 * A confirm popup
 * @param {function} props.handleClose - function to handle when answer is given
 * @param {boolean} props.open - bool if open or not
 * @param {string} props.title - title
 * @param {onConfirm} propr.onConform - action when delete is confirmed
 * @returns ConfirmPopup component
 */
export default function ConfirmDelete(props: Props) {
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Alert severity="error">
            <AlertTitle>Varning!</AlertTitle>
            Datan du håller på att radera kommer inte kunna fås tillbaka. Är du
            säker på att du vill radera?
          </Alert>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Avbryt</Button>
          <Button onClick={props.onConfirm} variant="contained" color="error">
            Radera
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
