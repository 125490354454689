import { useState, useEffect, useContext } from "react";
import { IconButton, Typography, Button, Grid, TableHead } from "@mui/material";
import { useAPI } from "../../API/useAPI";
import {
  dayOfWeek,
  months,
  addDays,
  weeksOfMonth,
  YYMMDD,
} from "../../utils/time";
import { JourDate, User } from "../../utils/types";
import { userFullname } from "../../utils/helpFunctions";
import CellInfo from "./CellInfo";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import BookEmployee from "../jour/BookEmploye";
import ConfirmDelete from "../shared/ConfirmDelete";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { MobileStateContext } from "../../contexts/MobileContext";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

interface Props {
  activeDate: Date;
  activeMonth: number;
  edit: boolean;
}
export default function JourTableMobile(props: Props) {
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const [jourare, setJourare] = useState<User[]>([]);
  const [bookEmploye, setBookEmployee] = useState<boolean>(false);
  const [jourDates, setJourDates] = useState<any[]>([]);
  const [deleteJourDate, setDeleteJourDate] = useState<number>();
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [addDate, setAddDate] = useState<Date>(new Date());
  const [deleteWeekStart, setDeleteWeekStart] = useState<Date>();
  const [deleteWeek, setDeleteWeek] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
    getJourDates();
  }, []);

  const getUsers = async () => {
    const res = await api.users.all();
    console.log(res); 
    if (!res.data) return;

    setJourare(res.data.data);
  };

  const getJourDates = async () => {
    const res = await api.jours.all();
    if (!res.data) return;

    setJourDates(res.data.data);
  };
  console.log(jourDates);

  /**
   * For each row in table
   * @param startDate start of intervall
   * @returns array of 7 dates from startDate
   */
  function getRow(startDate: Date) {
    let iterator = startDate;

    const res: Date[] = [];
    for (let i = 0; i < 7; i++) {
      // console.log(iterator);
      res.push(iterator);
      iterator = addDays(iterator, 1);
    }

    return res;
  }

  /**
   * Check if a jourdate exists on date
   * @param date
   * @returns worker
   */
  function findJourDate(date: Date) {
    const jourDate = jourDates.filter(
      (jourDate) => jourDate.date === YYMMDD(date)
    )[0];

    return jourDate ? jourDate : null;
  }

  /**
   * Open dialog to confirm delete
   * @param jourDate - jourdate to delete
   */
  const showDeleteDialog = (jourDate: JourDate, wholeWeek = false) => {
    setDeleteWeek(wholeWeek);
    console.log(jourDate);
    setDeleteJourDate(jourDate.id);
    if (wholeWeek) {
      setDialogTitle(
        `Är du säker på att du vill avboka ${userFullname(
          jourDate.worker
        )} en hel vecka med start ${jourDate.date}? `
      );
    } else {
      setDialogTitle(
        `Är du säker på att du vill avboka ${userFullname(
          jourDate.worker
        )} på datum ${jourDate.date}? `
      );
    }
    setConfirmDialog(true);
  };

  /**
   * Delete jour date from database
   */
  const permDeleteJourDate = async () => {
    // e.preventDefault();

    if (deleteJourDate) {
      const res = await api.jours.deleteOne(deleteJourDate);
      if (!res.data) {
        snackBar.show("Kunde inte avboka. Försök igen.", "error");
      } else {
        snackBar.show("Användaren har avbokats", "success");
      }

      getJourDates();
      setConfirmDialog(false);
    }
  };

  /**
   * Delete jour date and coming 7 jour dates if exists from database
   */
  const deleteWholeWeek = async () => {
    // setLoading(true);

    let weekDays: Date[] = [];
    if (deleteWeekStart) weekDays = getRow(deleteWeekStart);

    let deleteJourDates: string[] = [];
    for (const weekDay of weekDays) {
      console.log(`Weekday ${weekDay}`);
      let jourDate = findJourDate(weekDay);
      console.log(jourDate);
      if (jourDate) deleteJourDates.push(YYMMDD(new Date(jourDate.date)));
    }

    if (deleteJourDates) {
      const res = await api.jours.deleteMany(deleteJourDates);
      if (!res.data) {
        snackBar.show("Kunde inte avboka. Försök igen.", "error");
      } else {
        snackBar.show("Användaren har avbokats", "success");
      }

      getJourDates();
      setConfirmDialog(false);
    }
  };

  function handleDeleteWeek(startDate) {
    console.log("handleDeleteWeek");
    showDeleteDialog(findJourDate(startDate), true);
    setDeleteWeekStart(startDate);
  }

  return (
    <>
      {weeksOfMonth(props.activeDate, props.activeMonth).map((week) => (
        <Grid
          container
          display="flex"
          alignItems="center"
          key={week.id}
          flexDirection={"row"}
        >
          <Grid item xs={12}>
            <Grid container display="flex" justifyContent="center">
              <Typography variant="h3" sx={{ textAlign: "center", m: "15px" }}>
                {week.start.getDate()} {months[week.start.getMonth()]} -{" "}
                {week.end.getDate()} {months[week.end.getMonth()]}
              </Typography>

              <IconButton onClick={() => handleDeleteWeek(week.start)}>
                <PersonRemoveIcon />
              </IconButton>
            </Grid>

            {getRow(week.start).map((date, index) => (
              <Grid key={index}>
                <Typography sx={{ textAlign: "center", color: "#3A3A3C80" }}>
                  {dayOfWeek[index]}
                </Typography>
                {findJourDate(date) ? (
                  <Grid>
                    <Typography
                      sx={{
                        textAlign: "center",
                        border: 1,
                        p: 2,
                        m: 1,
                        //   borderColor: "#e0e0e0",
                      }}
                    >
                      {userFullname(findJourDate(date).worker)}
                      {/* {props.edit && ( */}
                      <IconButton
                        onClick={() => showDeleteDialog(findJourDate(date))}
                      >
                        <PersonRemoveIcon />
                      </IconButton>
                      {/* )} */}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    display="flex"
                    justifyContent="center"
                    sx={{
                      borderBottom: "1px dashed rgba(58, 58, 60, 0.3);",
                      p: "15px",
                    }}
                  >
                    <Button
                      sx={{
                        border: "1px solid rgba(58, 58, 60, 0.1)",
                        borderRadius: "10px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      startIcon={<AddBoxOutlinedIcon />}
                      onClick={() => {
                        setAddDate(date);
                        setBookEmployee(true);
                      }}
                    >
                      Lägg till jourare
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
      <BookEmployee
        open={bookEmploye}
        handleClose={() => setBookEmployee(false)}
        users={jourare}
        getJourDates={() => getJourDates()}
        date={addDate}
      />
      {confirmDialog && (
        <ConfirmDelete
          // onConfirm={() => permDeleteJourDate()}
          onConfirm={
            deleteWeek ? () => deleteWholeWeek() : () => permDeleteJourDate()
          }
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            getUsers();
          }}
          title={dialogTitle}
        />
      )}
    </>
  );
}
