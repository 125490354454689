import MenuItem from "@mui/material/MenuItem";
import {
  InputLabel,
  Select,
  FormControl,
  Typography,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  selectData: any;
  setSelectData: (arg0: string) => void;
  label: string;
  data: Array<any>;
}
/**
 * Generic select button for chosing from data
 * @param {string} label - label for textfield
 * @param {Array<any>} data - all choises for select
 * @param {any} selectData - state for selected value
 * @param {function} setSelectData - update chosen value
 * @returns
 */
export default function GenericSelect(props: Props) {
  /**
   * Clear value
   */
  const handleClearClick = () => {
    props.setSelectData("");
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="genericSelect">{props.label}</InputLabel>
        <Select
          sx={{ borderRadius: "10px" }}
          labelId="genericSelect"
          id="genericSelect"
          value={props.selectData || ""}
          label={props.label}
          onChange={(event) => props.setSelectData(event.target.value)}
          endAdornment={
            <IconButton
              sx={{ display: props.selectData ? "" : "none" }}
              onClick={handleClearClick}
            >
              <ClearIcon />
            </IconButton>
          }
        >
          {props.data.length > 0 ? (
            props.data.map(
              (object, index) =>
                object && (
                  <MenuItem key={index} value={object}>
                    <Typography> {object} </Typography>
                  </MenuItem>
                )
            )
          ) : (
            // <MenuItem>
            <Typography sx={{ p: "10px" }}>Inga alternativ</Typography>
            // </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
}
