import { Grid, Typography } from "@mui/material";

/**
 * 404 page
 *
 */
export default function NotFound() {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh" }}
    >
      <Typography variant="h1" sx={{ fontSize: "100px" }}>
        404
      </Typography>
      <Typography align="center">Sidan kunde inte hittas.</Typography>
    </Grid>
  );
}
