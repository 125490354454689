import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth/hooks/useAuth";
import LoadingScreen from "./shared/LoadingScreen";

/**
 * AdminRoute can be used instead of the Route within a BrowserRouter to make sure the user trying to reach a path is an admin user
 * @returns
 */
export const AdminRoute = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  console.log(auth);
  //|| !auth.user
  if (auth.loading) {
    return <LoadingScreen />;
  } else {
    return auth.user && auth.isAdmin() ? (
      children
    ) : (
      <Navigate to="/login" state={{ from: location.pathname }} />
    );
  }
};
