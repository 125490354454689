import { Typography, TableCell, Grid, Button, IconButton } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { userFullname } from "../../utils/helpFunctions";
import { User, JourDate } from "../../utils/types";

interface Props {
  edit: boolean;
  jourDate: JourDate;
  showDeleteDialog;
  openModal: () => void;
}
export default function CellInfo(props: Props) {
  return (
    <TableCell
      align="center"
      sx={{
        height: "100%",
        m: 0,
        p: 0,
        borderBottom: 0,
        borderLeft: "1px solid rgba(58, 58, 60, 0.15)",
      }}
    >
      {props.jourDate ? (
        <Grid
          container
          display="flex"
          alignItems="center"
          sx={{
            borderTop: "1px solid rgba(58, 58, 60, 0.3)",
            borderBottom: "1px solid rgba(58, 58, 60, 0.3)",
            // m: 0,
            p: "15px 0",
            height: "100%",
            borderRadius: 0,
          }}
        >
          <Grid item xs={props.edit ? 9 : 12}>
            <Typography sx={{ textAlign: "center" }}>
              {userFullname(props.jourDate.worker)}
            </Typography>
          </Grid>
          {props.edit && (
            <Grid item xs={3}>
              <IconButton
                onClick={() => props.showDeleteDialog(props.jourDate)}
                size="small"
                sx={{ m: 0, p: 0 }}
              >
                <PersonRemoveIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          sx={{
            borderTop: "1px dashed rgba(58, 58, 60, 0.3);",
            borderBottom: "1px dashed rgba(58, 58, 60, 0.3);",
            borderRadius: 0,
            p: "8px",
          }}
        >
          <Button
            sx={{
              border: "1px solid rgba(58, 58, 60, 0.1)",
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: "bold",
            }}
            startIcon={<AddBoxOutlinedIcon />}
            onClick={props.openModal}
          >
            Lägg till jourare
          </Button>
        </Grid>
      )}
    </TableCell>
  );
}
