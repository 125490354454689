import { useContext } from "react";
import { MobileStateContext } from "../../contexts/MobileContext";
import {
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@mui/material";
import { Company, Address } from "../../utils/types";
import CloseIcon from "@mui/icons-material/Close";
import AddressForm from "./AddressForm";

interface Props {
  open: boolean;
  company: Company;
  editAddresses?: Address[];
  handleClose: () => void;
  setIsLoading: (boolean) => void;
  getAddresses: () => void;
  allAddresses: Address[];
}

/** STYLE */
const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
};

/**
 * Modal holding address form
 * @returns
 */
export default function AddressFormModal(props: Props) {
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);
  console.log(props.allAddresses);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    p: 4,
    // maxWidth: "600px",
    width: isMobile ? "100vw" : "70%",
    height: isMobile ? "100%" : {},
    maxHeight: isMobile ? "100%" : "80%",
    boxShadow: isMobile ? "0px !important" : 24,
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader
          title={
            <Typography variant="h3" align="center">
              {props.editAddresses
                ? props.editAddresses.length === 1
                  ? `Redigera address ${props.editAddresses[0].address_street}`
                  : `Redigera flera adresser`
                : "Lägg till ny address"}
            </Typography>
          }
        />
        <IconButton
          aria-label="Example"
          onClick={props.handleClose}
          sx={closeButtonStyle}
        >
          <CloseIcon color="primary" sx={{ fontSize: 40 }} />
        </IconButton>
        <CardContent sx={{ p: 0 }}>
          <AddressForm
            handleClose={props.handleClose}
            company={props.company}
            getAddresses={props.getAddresses}
            editAddresses={props.editAddresses}
            allAddresses={props.allAddresses}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
