import React, { useState } from "react";
import { Typography, Divider, Grid, Button } from "@mui/material";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { userFullname } from "../../utils/helpFunctions";
import { JourReport } from "../../utils/types";
import { Link } from "react-router-dom";
import GenerateOrderPdfButton from "./GenerateOrderPdfButton";
/**
 *
 * Component for showing an order
 * @param {JourReport} - active report
 * @returns
 */
export default function OrderCard({ report }) {
  /**
   * Open adress in google maps
   * */
  const openMaps = (address: string) => {
    const query = encodeURI(address);
    // const query = encodeURI(customer.adress) + "+" + encodeURI(adress.city);
    const link = "https://www.google.com/maps/search/?api=1&query=" + query;
    window.open(link); // Open maps
  };
  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "15px" }}
      >
        <Typography>
          <i>{report.custom_id}</i>
        </Typography>
        {/* <Button
          sx={{
            color: "black",
            border: 1,
            borderColor: "#e0e0e0",
            margin: "10px",
          }}
          startIcon={<GetAppOutlinedIcon />}
        >
          <Typography>Ladda ner rapport</Typography>
        </Button> */}
        <GenerateOrderPdfButton id={report.id} custom_id={report.custom_id} />
      </Grid>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "15px" }}
      >
        <Typography>
          {report.address ? (
            <b>{report.address.company.name}</b>
          ) : (
            <b>Bolaget borttaget</b>
          )}
        </Typography>
        {report.worker ? (
          <Typography>{userFullname(report.worker.worker)}</Typography>
        ) : (
          <Typography>Error: Ingen användare</Typography>
        )}
      </Grid>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "15px" }}
      >
        {report.address ? (
          <Link
            to=""
            onClick={() =>
              openMaps(
                `${report.address.address_street} ${report.address.address_number}`
              )
            }
          >
            <Typography>
              {report.address.address_street} {report.address.address_number}
            </Typography>
          </Link>
        ) : (
          <Typography>Adressen borttagen</Typography>
        )}
        <Typography>
          {report.worker ? report.worker.date.slice(0, 10) : "Inget datum"}
        </Typography>
      </Grid>

      <Divider />
    </>
  );
}
