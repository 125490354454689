import { useState, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Card,
  InputAdornment,
  IconButton,
  CircularProgress,
  CardContent,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { SnackbarContext } from "../contexts/SnackbarProvider";
import { User } from "../utils/types";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../auth/hooks/useAuth";
import { isNullOrEmpty, isValidEmail } from "../utils/helpFunctions";
import { ConfirmResetPasswordRequest } from "../utils/types";

interface PasswordChange {
  password: string;
  password_confirm: string;
}

/**
 * Generates the reset password view
 * @returns ResetPassword component
 */
export default function ResetPassword() {
  const params = useParams();
  console.log(params.token);
  const auth = useAuth();
  const snackBar = useContext(SnackbarContext);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [pswError, setPswError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [passwords, setPasswords] = useState<PasswordChange>({
    password: "",
    password_confirm: "",
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handle input changes and update states of the email
   * @param {object} event - the event from the textfield
   */
  function handleChange(event) {
    setEmail(event.target.value);
  }

  /**
   * Show error message in textfield if the password is less
   * than 6 characters or passwords not matching
   * @param event - from textfield
   */
  function handlePasswordChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    console.log(event.target.id);
    setPasswords({ ...passwords, [event.target.id]: event.target.value });

    let pswCompare =
      event.target.id === "password"
        ? passwords.password_confirm
        : passwords.password;

    if (!isNullOrEmpty(event.target.value)) {
      if (event.target.value.length < 6)
        setPswError("Lösenordet måste innehålla minst 6 tecken.");
      else if (pswCompare !== event.target.value)
        setPswError("Lösenorden matchar inte");
      else setPswError("");
    } else {
      setPswError("");
    }
  }

  /**
   * Sends the form and generates a reset password email sent to the users email adress
   * @param {object} event - the event from the form
   */
  const sendForm = async (e) => {
    setLoading(true);
    e.preventDefault();

    const res = await auth.sendPasswordToken(email);
    // const res = await auth.sendPasswordToken("amanda.hult@weknowit.nu");

    if (res) {
      snackBar.show(
        `Ett mejl har skickats till ${email}. Följ länken för att återställa lösenordet.`,
        "success"
      );
    } else {
      snackBar.show("Kunde inte återställa.", "error");
    }
    setLoading(false);
  };

  /**
   * Sends the form and generates a reset password email sent to the users email adress
   * @param {object} event - the event from the form
   */
  const updatePassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (params.token) {
      const payload: ConfirmResetPasswordRequest = {
        token: params.token,
        password: passwords.password,
      };
      const res = await auth.resetPasswordRequest(payload);
      // const res = await auth.sendPasswordToken("amanda.hult@weknowit.nu");

      if (res) {
        snackBar.show(`Ditt lösenord har uppdaterats.`, "success");
      } else {
        snackBar.show("Kunde inte återställa lösenord.", "error");
      }
    } else {
      snackBar.show("Kunde inte återställa lösenord.", "error");
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={6}>
        <Card>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Återställ lösenord
          </Typography>
          <CardContent>
            {params.token ? (
              <form onSubmit={updatePassword}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={11}>
                    <TextField
                      id="password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              //   onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Lösenord"
                      value={passwords.password}
                      onChange={(e) => handlePasswordChange(e)}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      id="password_confirm"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              //   onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={pswError}
                      error={pswError !== ""}
                      placeholder="Bekräfta lösenord"
                      value={passwords.password_confirm}
                      onChange={(e) => handlePasswordChange(e)}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="secondary"
                      size="large"
                      type="submit"
                    >
                      Återställ lösenord
                    </Button>
                  </Grid>
                  <Grid item xs={11}>
                    <Link to="/">
                      <Typography>Tillbaka till logga in</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <form onSubmit={sendForm}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={11}>
                    <TextField
                      value={email}
                      label="Email"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      //   error={
                      //     error &&
                      //     (error.code === "auth/invalid-email" ||
                      //       error.code === "auth/user-not-found")
                      //       ? true
                      //       : false
                      //   }
                      //   helperText={
                      //     error &&
                      //     (error.code === "auth/invalid-email" ||
                      //       error.code === "auth/user-not-found")
                      //       ? error.message
                      //       : ""
                      //   }
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="secondary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Typography color="white">
                          Återställ lösenord
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={11}>
                    <Link to="/">
                      <Typography>Tillbaka till logga in</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            )}

            {/* </Grid> */}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
