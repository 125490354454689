import { ReactElement, useEffect, useState } from "react";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridComparatorFn,
  GridFilterModel,
  GridSelectionModel,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Typography, Stack, Box, CircularProgress } from "@mui/material";
import { userFullname, datesAreOnSameDay } from "../../../utils/helpFunctions";
import { JourReports } from "../../../utils/types";
import ReportActionMenu from "../ReportActionMenu";
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth/index.js";

interface Props {
  reportCount: number;
  page: number;
  setPage: (p: number) => void;
  pageSize: number;
  setPageSize: (s: number) => void;
  orders: JourReports[];
  customToolbar: () => ReactElement;
  onSelectionChange: (ids: number[]) => void;
  signalFetchJourReports: () => void;
  setColumnToSort: (s: string) => void;
  setSortDirection: (d: "asc" | "desc") => void;
  setFilterText: (s: string | null) => void;
  fetchedArchived: boolean;
  loading: boolean;
}
interface OrderInfo {
  id: number;
  company: string;
  date: string;
  name: string;
  custom_id: string;
  address: string;
}

export default function OrderTablePaginated(props: Props) {
  /**
   * Open adress in google maps
   * */
  const openMaps = (address: string) => {
    const query = encodeURI(address);
    // const query = encodeURI(customer.adress) + "+" + encodeURI(adress.city);
    const link = "https://www.google.com/maps/search/?api=1&query=" + query;
    window.open(link); // Open maps
  };

  function filterOrders() {
    let filtered: OrderInfo[] = props.orders.map((order) => ({
      custom_id: order.custom_id,
      id: order.id,
      name: order.worker
        ? userFullname(order.worker.worker)
        : "Användare borttagen",
      date: order.worker ? order.worker.date : "Datum borttaget",
      company: order.address ? order.address.company.name : "Bolaget borttaget",
      address: order.address
        ? `${order.address.address_street} ${order.address.address_number}`
        : "Adressen borttagen",
    }));

    return filtered;
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "custom_id",
      headerName: "ID",
      flex: 0.5,
    },
    { field: "date", headerName: "Datum", flex: 0.5 },
    {
      field: "company",
      headerName: "Bolag",
      flex: 1,
    },
    { field: "name", headerName: "Namn", flex: 1 },
    {
      field: "address",
      headerName: "Adress",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Link to="" onClick={() => openMaps(cellValues.row.address)}>
            <Typography>{cellValues.row.address}</Typography>
          </Link>
        );
      },
    },
    {
      field: "reports",
      headerName: "Rapporter",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <ReportActionMenu
            id={cellValues.row.id}
            custom_id={cellValues.row.custom_id}
            signalFetchJourReports={props.signalFetchJourReports}
            fetchedArchive={props.fetchedArchived}
          />
        );
        // return <Typography>{cellValues.row.id}</Typography>;
      },
    },
  ];

  const rowSelectionChanged = (selectionModel: GridSelectionModel) => {
    console.log("selection changed with ids: ", selectionModel);
    let ids: number[] = [];
    try {
      ids = selectionModel.map((id: number | string) =>
        typeof id === "number" ? id : parseInt(id)
      );
    } catch {
      return;
    }
    props.onSelectionChange(ids);
  };

  function onSortModelChange(model: GridSortModel) {
    // the sort model is a list since the pro api has sorting on several fields at once.
    const { field, sort }: GridSortItem = model[0];
    props.setColumnToSort(field);
    if (sort && ["asc", "desc"].includes(sort)) {
      props.setSortDirection(sort);
    }
  }

  function onFilterModelChange(model: GridFilterModel) {
    console.log("filter model change!: ", model);
    // if the search string contains any words, take out the first one only and search based on that
    if (!model.quickFilterLogicOperator) return;
    const values = model.quickFilterValues as string[];
    const value: string | undefined = values[0];
    props.setFilterText(value ? value : null);
  }

  return (
    <DataGrid
      sx={{ border: "none" }}
      rows={filterOrders()}
      loading={props.loading}
      columns={columns}
      disableColumnMenu={true}
      autoHeight
      // pagination
      page={props.page}
      pageSize={props.pageSize}
      rowCount={props.reportCount}
      paginationMode="server"
      onPageChange={props.setPage}
      onPageSizeChange={props.setPageSize}
      rowsPerPageOptions={[5, 10, 15, 20]}
      // bulk selection for deletion
      checkboxSelection={true}
      disableSelectionOnClick={true}
      onSelectionModelChange={rowSelectionChanged}
      // serverside sorting
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      sortingOrder={["asc", "desc"]}
      // filtering. The toolbar has a quick filter that we need to tie server logic for
      filterMode="server"
      onFilterModelChange={onFilterModelChange}
      // toolbar and placeholder
      components={{
        Toolbar: props.customToolbar,
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            Inga uppdrag
          </Stack>
        ),
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            Inga uppdrag
          </Stack>
        ),
        LoadingOverlay: () => (
          <Box width={"100%"} display="flex" justifyContent={"center"} py={10}>
            <CircularProgress />
          </Box>
        ),
      }}
      componentsProps={{
        panel: {
          sx: {
            "& .MuiInput-root": {
              color: "dodgerblue",
              backgroundColor: "pink",
              fontSize: 20,
            },
            "& .MuiDataGrid-filterForm": {
              bgcolor: "lightblue",
            },
          },
        },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "custom_id", sort: "asc" }],
        },
      }}
    />
  );
}
