import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Divider,
  Grid,
  CircularProgress,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Address, AddressUpdate, Company } from "../../utils/types";
import { isEven } from "../../utils/time";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useAPI } from "../../API/useAPI";

interface AddressFormProps {
  editAddresses?: Address[];
  company: Company;
  getAddresses: () => void;
  handleClose: () => void;
  allAddresses: Address[];
}

interface inputProps {
  id: string;
  label: string;
  size: number;
  placeholder?: string;
  required?: boolean;
  rows?: number;
}

/**
 * Form for adding or editing an address
 * @returns
 */
export default function AddressForm(props: AddressFormProps) {
  const emptyAddress: AddressUpdate = {
    company: props.company.id,
    address_street: "",
    address_number: [],
    cost_center: "",
  };
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const [addressData, setAddressData] = useState<AddressUpdate>(emptyAddress);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMultiple, setEditMultiple] = useState<boolean>(false);
  console.log(addressData);
  console.log(props.allAddresses);
  /**
   * Check for single address to edit or
   * set multiple addresses to true
   */
  useEffect(() => {
    if (props.editAddresses) {
      console.log(`Address number: ${props.editAddresses[0].address_number}`);
      if (props.editAddresses.length === 1)
        setAddressData({
          ...props.editAddresses[0],
          company: props.editAddresses[0].company.id,
          address_number: [props.editAddresses[0].address_number],
        });
      else setEditMultiple(true);
    }
  }, []);

  const [intervallInfo, setIntervallInfo] = useState({
    multiple_numbers: false,
    start_number: NaN,
    end_number: NaN,
    type: "even",
  });

  const [errorInfo, setErrorInfo] = useState({
    start: false,
    end: false,
    helper_text: "",
  });

  /**
   * Update intervall info
   * @param id - field to update
   * @param value - new Value
   */
  const update = (id: string, value: any) => {
    setIntervallInfo({ ...intervallInfo, [id]: value });
  };

  useEffect(() => {
    if (intervallInfo.multiple_numbers) {
      setErrorInfo({
        start: false,
        end: false,
        helper_text: "",
      });
      checkInvalidInput();
      updateNumbersArray();
    }
  }, [intervallInfo]);

  /**
   * Function to check after invalid input numbers for intervall
   */
  const checkInvalidInput = () => {
    let start = false;
    let end = false;

    if (intervallInfo.multiple_numbers) {
      if (intervallInfo.type === "even") {
        // Check that both numbers are even when even intervall chosen
        if (!isEven(intervallInfo.start_number)) start = true;
        if (!isEven(intervallInfo.end_number)) end = true;
        setErrorInfo({
          ...errorInfo,
          start: intervallInfo.start_number ? start : false,
          end: intervallInfo.end_number ? end : false,
          helper_text: "Numret måste vara jämnt.",
        });
      }
      // Check that both numbers are odd when even intervall chosen
      else if (intervallInfo.type === "odd") {
        if (isEven(intervallInfo.start_number)) start = true;
        if (isEven(intervallInfo.end_number)) end = true;
        setErrorInfo({
          ...errorInfo,
          start: intervallInfo.start_number ? start : false,
          end: intervallInfo.end_number ? end : false,
          helper_text: "Numret måste vara udda.",
        });
      }
      // Check that end number is larger than start number
      if (intervallInfo.start_number >= intervallInfo.end_number) {
        setErrorInfo({
          ...errorInfo,
          end: intervallInfo.end_number ? true : false,
          helper_text: "Sista numret måste vara större.",
        });
      }
    }
  };

  /**
   * Create an array with all numbers inside intervall
   */
  const updateNumbersArray = () => {
    var numbers: number[] = [];
    const step = intervallInfo.type === "all" ? 1 : 2;
    console.log("updateNumbersArray");

    for (
      var i = intervallInfo.start_number;
      i <= intervallInfo.end_number;
      i = i + step
    ) {
      numbers.push(i);
    }
    console.log(`Numbers: ${numbers}`);
    // Update props variable
    setAddressData((prev) => {
      return {
        ...prev,
        address_number: numbers,
      };
    });
  };

  /**
   * Component textfield in form
   */
  function TextInput({
    id,
    label,
    size,
    placeholder,
    required,
    rows,
  }: inputProps) {
    return (
      <Grid item xs={size}>
        <InputLabel htmlFor={id} sx={{ m: "10px 0" }}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={id}
          value={addressData[id]}
          onChange={(event) =>
            setAddressData((prev) => {
              return {
                ...prev,
                [event.target.id]: event.target.value,
              };
            })
          }
          placeholder={placeholder}
          required={required}
          fullWidth
          multiline={rows ? true : false}
          rows={rows}
          notched={false}
        />
      </Grid>
    );
  }

  /**
   * Sends form: and an address to database or edit existing
   */
  const sendForm = async (e) => {
    e.preventDefault();
    console.log(addressData);

    setLoading(true);
    if (props.editAddresses) {
      // Edit single address
      if (props.editAddresses.length === 1) {
        const res = await api.addresses.update(props.editAddresses[0].id, {
          ...addressData,
          address_number: addressData.address_number[0],
        });
        if (!res.data) {
          snackBar.show(
            "Kunde inte uppdatera addressen. Försök igen.",
            "error"
          );
        } else {
          snackBar.show("Addressen uppdaterad!", "success");
          props.getAddresses();
          props.handleClose();
        }
      } else {
        // EDIT MULTIPLE ADDRESSES
        console.log("MULTIPLE EDIT");
        // let ids = props.editAddresses.map((x) => x.address_number);
        let ids = props.editAddresses.map((x) => x.id);

        console.log(ids);
        const res = await api.addresses.bulkUpdate(
          ids,
          addressData["cost_center"]
        );
        if (!res.data) {
          snackBar.show(
            "Kunde inte uppdatera addresser. Försök igen.",
            "error"
          );
        } else {
          snackBar.show("Addresserna uppdaterade!", "success");
          props.getAddresses();
          props.handleClose();
        }
      }
    } else {
      // Create new address
      const res = await api.addresses.create({
        company: props.company.id,
        address_street: addressData.address_street,
        address_number: addressData.address_number,
        cost_center: addressData.cost_center,
      });
      if (!res.data) {
        snackBar.show("Kunde inte skapa addressen. Försök igen.", "error");
      } else {
        snackBar.show("Addressen har skapats!", "success");
        props.getAddresses();
      }
    }
    setLoading(false);
    props.handleClose();
  };

  function isExisting(address_street: string, address_number: number) {
    // console.log(address);
    console.log(props.allAddresses);
    console.log(address_street);

    const existingAddress = props.allAddresses.find(
      (ad) =>
        ad.address_street.replace(/ /g, "") ===
          address_street.replace(/ /g, "") &&
        ad.address_number === address_number
    );
    console.log(`Existing: ${existingAddress}`);
    return existingAddress;
  }
  console.log(editMultiple);
  console.log(props.editAddresses);

  return (
    <form onSubmit={sendForm}>
      <Grid
        sx={{
          m: 1,
          borderRadius: 1,
        }}
        container
        display="flex"
        justifyContent="center"
      >
        {editMultiple ? (
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: "bold", m: 0, p: 0 }}>
              Adresser:
            </Typography>
            <ul>
              {props.editAddresses!.map((address, index) => (
                <li key={index}>
                  <Typography>
                    {address.address_street} {address.address_number}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>
        ) : (
          <>
            {TextInput({
              id: "address_street",
              label: "Gatunamn",
              size: 10,
              required: true,
              // value: props.state.address_street,
              // handleChange: handleChange,
            })}

            {!props.editAddresses && (
              <Grid item xs={intervallInfo.multiple_numbers ? 10 : 5}>
                <InputLabel htmlFor="MultipleNumbers" sx={{ m: "10px 0" }}>
                  Antal nummer
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="MultipleNumbers"
                    value={intervallInfo.multiple_numbers}
                    onChange={() =>
                      setIntervallInfo({
                        ...intervallInfo,
                        multiple_numbers: !intervallInfo.multiple_numbers,
                      })
                    }
                    row
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Ett nummer"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Flera nummer"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            {intervallInfo["multiple_numbers"] ? (
              <Grid item xs={10}>
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    <InputLabel htmlFor="interval" sx={{ m: "10px 0" }}>
                      Välj typ av intervall
                    </InputLabel>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="interval"
                        defaultValue="even"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          setIntervallInfo({
                            ...intervallInfo,
                            type: event.target.value,
                          })
                        }
                        row
                      >
                        <FormControlLabel
                          value="even"
                          control={<Radio />}
                          label="Jämna nummer"
                        />
                        <FormControlLabel
                          value="odd"
                          control={<Radio />}
                          label="Udda nummer"
                        />
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="Alla nummer"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="start_number" sx={{ m: "10px 0" }}>
                      Start på intervall
                    </InputLabel>
                    <TextField
                      id="start_number"
                      value={intervallInfo.start_number || ""}
                      onChange={(event) =>
                        update(event.target.id, parseInt(event.target.value))
                      }
                      required
                      error={errorInfo.start}
                      helperText={errorInfo.start ? errorInfo.helper_text : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="end_number" sx={{ m: "10px 0" }}>
                      Slut på intervall
                    </InputLabel>
                    <TextField
                      id="end_number"
                      value={intervallInfo.end_number || ""}
                      onChange={(event) =>
                        update(event.target.id, parseInt(event.target.value))
                      }
                      required
                      error={errorInfo.end}
                      helperText={errorInfo.end ? errorInfo.helper_text : ""}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={4}>
                  <InputLabel htmlFor="number" sx={{ m: "10px 0" }}>
                    Nummer
                  </InputLabel>
                  <TextField
                    sx={{ width: "100%" }}
                    id="number"
                    value={addressData.address_number[0] || ""}
                    onChange={(event) =>
                      setAddressData((prev) => {
                        return {
                          ...prev,
                          address_number: [parseInt(event.target.value)],
                        };
                      })
                    }
                    required
                    error={errorInfo.start}
                    helperText={errorInfo.start ? errorInfo.helper_text : ""}
                  />
                </Grid>
                <Grid item xs={1} />
              </>
            )}
          </>
        )}
        {TextInput({
          id: "cost_center",
          label: "Kostnadsställe",
          size: props.editAddresses ? 5 : 10,
          required: true,
        })}
        <Divider sx={{ m: "20px 0" }} />
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            sx={{ p: "10px 30px", m: "30px 10px" }}
            type="submit"
            disabled={errorInfo.start || errorInfo.end}
          >
            <Typography sx={{ color: "white" }}>
              {loading ? (
                <CircularProgress />
              ) : props.editAddresses ? (
                "Spara"
              ) : (
                "Lägg till"
              )}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
