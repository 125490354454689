import {
  TextField,
  Autocomplete,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { userFullname } from "../../utils/helpFunctions";
import { createFilterOptions } from "@mui/material/Autocomplete";

interface Props {
  data: Array<any>;
  state: any;
  id: string;
  label: string;
  setState: Function;
  displayProp: string;
  error?: boolean;
  // visible: string;
}
/**
 * Search after existing customers for company
 *
 * @param {string} label - label on textfield
 * @param {any} default - default value for textfield
 * @param {Array<any>} data - all data to search on
 * @returns SearchCustomers component
 */
export default function AutoSearch(props: Props) {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: any) => option[props.displayProp],
  });
  return (
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      sx={{ height: "100%" }}
    >
      <InputLabel htmlFor={props.id}>
        <Typography>{props.label}</Typography>
      </InputLabel>
      <Autocomplete
        id={props.id}
        options={props.data}
        renderInput={(params) => (
          <TextField
            error={props.error ? true : false}
            {...params}
            placeholder="Sök"
            variant="outlined"
          />
        )}
        renderOption={(p, option) => {
          const dp = props.displayProp
          return (
            <li {...p} key={option.id}>
              {dp === "last_name"
                ? userFullname(option)
                : (dp === "address_street") ? `${option.address_street} - ${option.company.name}`
                :option[props.displayProp]}
            </li>
          );
        }}
        noOptionsText="Inga alternativ hittades"
        getOptionLabel={(option) => {
          const dp = props.displayProp
          return ((dp === "last_name") ? userFullname(option) : option[props.displayProp])          
        }
        }
        fullWidth
        value={props.state[props.id] || null}
        onChange={(event, newValue) => {
          props.setState({
            ...props.state,
            [props.id]: newValue,
          });
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={filterOptions}
      />
    </Grid>
  );
}
