import React, {
  SyntheticEvent,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Typography,
  Divider,
  Grid,
  IconButton,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Input,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import { MobileStateContext } from "../../contexts/MobileContext";
import AutoSearch from "../shared/AutoSearch";
import {
  Company,
  Address,
  JourReport,
  JourReportCreate,
  Companies,
  Addresses,
  User,
  JourReportWorker,
  JourReportWorkerCreate,
} from "../../utils/types";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import DateInput from "../shared/DateInput";
import TextInput from "../shared/TextInput";
import {
  isNullOrEmpty,
  removeDuplicates,
  userFullname,
} from "../../utils/helpFunctions";
import { useAuth } from "../../auth/hooks/useAuth";
import { useAPI } from "../../API/useAPI";
import { Box, minWidth } from "@mui/system";
import { AttachFile, Clear } from "@mui/icons-material";
import { ERRORS } from "../../utils/constants";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import TimeInput from "../shared/TimeInput";
import { create } from "domain";

interface FormDataJourReport {
  apartment_number: string;
  address?: Addresses;
  port_id?: string;
  tenant_name: string;
  tenant_phone: string;
  debit_tenant: boolean;
  worker?: User;
  created_at: string;
  created_time: string;
  total_hours: number;

  defect_description: string;
  actions_made: string;
  actions_to_be_made: string;
  attachments: File[];
}
interface FormDataJourReportError {
  apartment_number?: string;
  address?: string;
  port_id?: string;
  tenant_name?: string;
  tenant_phone?: string;
  debit_tenant?: string;

  worker?: JourReportWorkerCreate;
  additional_workers?: JourReportWorkerCreate[];

  defect_description?: string;
  actions_made?: string;
  actions_to_be_made?: string;
  attachments?: string;
}

interface AdditionalWorker {
  user?: User;
  date?: string;
  end_time?: string;
  total_hours?: string;
}

const defaultState: FormDataJourReport = {
  apartment_number: "",

  tenant_name: "",
  tenant_phone: "",
  debit_tenant: false,

  total_hours: 1,
  created_at: new Date().toISOString().slice(0, 10),
  created_time: new Date().toISOString(),

  defect_description: "",
  actions_made: "",
  actions_to_be_made: "",
  attachments: [],
};

const gridStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  m: "10px 0 0 0",
};
/**
 * Form for adding a new jour report
 * @returns
 */
export default function JourForm() {
  const auth = useAuth();
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);
  const [addresses, setAddresses] = useState<Addresses[]>([]);
  const [showSupportJour, setShowSupportJour] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [errors, setErrors] = useState<FormDataJourReportError>({});
  const [formData, setFormData] = useState<FormDataJourReport>(defaultState);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedStreetNumber, setStreetNumber] = useState<number | null>(null);
  const [additional_worker, setAdditionalWorker] = useState<AdditionalWorker>({
    user: undefined,
    date: new Date().toISOString().slice(0, 10),
    total_hours: "",
  });
  const inputFile = useRef<HTMLInputElement>(null);

  /**
   * This function is a frontend solution for nightshifts. If a worker
   * Reports during the night between wednesday and thursday, the report shall
   * be included in the same weekly report as those on wednesday, NOT thursday (even
   * though the actual date when the report is submitted is thursday.)
   * @param time The time string from the form
   * @param date the sliced string for the date
   * @returns The date to use, same as param date if time is after 7AM,
   * adjusted to the date before if the time is between 00 and 07 (i.e nightshift)
   */
  function adjustDateForNightShift(time: string, date: string): string {
    const timeAsDate = new Date(time);
    const isNightShift = timeAsDate.getHours() < 7;
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() - (isNightShift ? 1 : 0));
    return adjustedDate.toISOString().slice(0, 10);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formerrors: FormDataJourReportError = {};

    if (!auth.user) {
      setIsSubmitting(false);
      return;
    }

    if (isNullOrEmpty(formData.apartment_number)) {
      formerrors.apartment_number = ERRORS.NULL_OR_EMPTY;
    }

    if (!formData.address) {
      formerrors.address = ERRORS.NULL_OR_EMPTY;
      setErrors(formerrors);
      setIsSubmitting(false);
      snackBar.show("Fyll i address och försök igen.", "error");
      return;
    }

    if (Object.keys(formerrors).length > 0) {
      setErrors(formerrors);
      snackBar.show("Alla fält är inte korrekt ifyllda.", "error");
      setIsSubmitting(false);
      return;
    }
    /*
     * The function adjustDateForNightShift is a front end quickfix to address the bug
     * of nightshifts.
     * TODO: Move the logic to the backend.
     */
    const payload: JourReportCreate = {
      apartment_number: formData.apartment_number,

      tenant_name: formData.tenant_name,
      tenant_phone: formData.tenant_phone,
      // tenant_phone: 123456789,
      debit_tenant: formData.debit_tenant,
      address: formData.address.id,
      port_id: formData.port_id,

      worker: {
        id: auth.user?.id,
        date: adjustDateForNightShift(
          formData.created_time,
          formData.created_at
        ),
        total_hours: formData.total_hours,
      },

      defect_description: formData.defect_description,
      actions_made: formData.actions_made,
      actions_to_be_made: formData.actions_to_be_made,
    };

    if (showSupportJour) {
      if (
        additional_worker.user &&
        additional_worker.date &&
        additional_worker.total_hours &&
        additional_worker.end_time
      ) {
        payload.additional_workers = [
          {
            id: additional_worker.user.id,
            total_hours: parseFloat(additional_worker.total_hours),
            date: adjustDateForNightShift(
              additional_worker.end_time,
              additional_worker.date
            ),
          },
        ];
      } else {
        snackBar.show("Alla fält är inte ifyllda på stödjour", "error");
        setIsSubmitting(false);
        return;
      }
    }

    if (formData.attachments.length > 0) {
      const res = await api.jourReports.createWithAttachments(
        formData.attachments,
        payload
      );

      if (res.data) {
        snackBar.show(res.data.message, "success");
        setFormData(defaultState);
      } else {
        snackBar.show("Något gick fel, rapporten skickades inte!", "error");
      }
      setIsSubmitting(false);
      setShowSupportJour(false);
    } else {
      const res = await api.jourReports.create(payload);
      if (res.data) {
        snackBar.show(res.data.message, "success");
        // snackBar.show("Rapport inskickad!", "success");
        setFormData(defaultState);
        setAdditionalWorker({
          user: undefined,
          date: new Date().toISOString().slice(0, 10),
          total_hours: "",
        });
        setShowSupportJour(false);
      } else if (res.error) {
        snackBar.show(res.error.message, "error");
      } else {
        snackBar.show("Något gick fel, rapporten skickades inte!", "error");
      }
      setIsSubmitting(false);
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    let newFiles: File[] = [];
    if (event.target.files !== null) {
      // Only upload jpg and png images
      Array.from(event.target.files || []).map((file) => {
        if (file.type !== "image/jpeg" && file.type !== "image/png") {
          snackBar.show(`Laddar endast upp filer av typen JPG & PNG.`, "error");
        } else {
          newFiles.push(file);
        }
      });
      setFormData((prev) => {
        return {
          ...prev,
          attachments: formData.attachments.concat(newFiles),
        };
      });
    }
  };
  const setFormAddress = (address: Addresses) => {
    setFormData((prev) => {
      return {
        ...prev,
        address: address,
      };
    });
  };

  const getAddressFromStreetNumber = (n: number): Addresses | undefined => {
    if (!formData.address) return undefined;
    return addresses.find(
      (ad) =>
        ad.address_street === formData.address?.address_street &&
        ad.address_number === selectedStreetNumber
    );
  };

  useEffect(() => {
    if (selectedStreetNumber) {
      const new_address = getAddressFromStreetNumber(selectedStreetNumber);
      if (new_address) {
        setFormAddress(new_address);
      }
    }
  }, [selectedStreetNumber]);

  const getAddressNumbers = () => {
    if (!formData.address) return [];
    const selectedAddress = addresses.find(
      (ad) => ad.id === formData.address?.id
    );

    return addresses.filter(
      (ad) => ad.address_street === selectedAddress?.address_street
    );
  };

  const fetchData = async () => {
    // const res_com = await api.companies.all();
    const res_add = await api.addresses.all();
    const res_user = await api.users.all();

    // if (res_com.data) {
    //   setCompanies(res_com.data.data);
    // }
    if (res_add.data) {
      // we add a filtering such that we only include addressess whose company are not archived
      const addresses: Addresses[] = res_add.data.data;
      const non_archived = addresses.filter((a) => {
        return !a.company.archived;
      });
      setAddresses(non_archived);
    } else {
      snackBar.show("Kunde inte hämta addresser.", "error");
    }
    if (res_user.data) {
      setUsers(res_user.data.data);
    } else {
      snackBar.show("Kunde inte hämta användare.", "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getUniqueStreetNames = () => {
    const unique: Addresses[] = [];

    addresses.forEach((ad) => {
      let found = false;
      unique.forEach((ad2) => {
        if (
          ad.address_street === ad2.address_street &&
          ad.company.id === ad2.company.id
        ) {
          found = true;
        }
      });
      if (!found) {
        unique.push(ad);
      }
    });
    return unique;
  };

  function handleChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    // Different assignment for string and number
    setErrors({});
    console.log(isNaN(formData[event.target.id]));

    setFormData({
      ...formData,
      [(event.target as HTMLInputElement).id]: (
        event.target as HTMLInputElement
      ).value,
    });
    // if (isNaN(formData[event.currentTarget.id])) {
    //   setFormData({
    //     ...formData,
    //     [(event.target as HTMLInputElement).id]: (
    //       event.target as HTMLInputElement
    //     ).value.replace(/\D/g, ""),
    //   });
    // } else {

    // }
  }

  function handleFloatChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    // Only allow 0-9 and .
    setFormData({
      ...formData,
      [(event.target as HTMLInputElement).id]: event.target.value.replace(
        /[^0-9.]/gi,
        ""
      ),
    });
  }

  function updateDate(newDate: Date) {
    setFormData({
      ...formData,
      created_at: newDate.toISOString().slice(0, 10),
    });
  }
  function updateTime(newDate: Date) {
    setFormData({
      ...formData,
      created_time: newDate.toISOString(),
    });
  }
  console.log(additional_worker);

  return (
    <form onSubmit={(e) => submitForm(e)}>
      <Grid
        sx={{
          m: 1,
          p: 1,
          borderRadius: 1,
          boxSizing: "border-box",
        }}
        flexDirection={isDesktop ? "row" : "column"}
        container={isDesktop}
        // spacing={3}
      >
        {/* COLUMN 1 */}
        <Grid id="column1" item xs={isMobile ? 12 : 6}>
          {/* START SECTION 1 */}
          <Typography variant="h3">Objekt</Typography>
          <br></br>
          <Grid item xs={12}>
            <AutoSearch
              error={errors.address ? true : false}
              label="Välj adress"
              data={getUniqueStreetNames()}
              id="address"
              state={formData}
              setState={setFormData}
              displayProp={"address_street"}
            />
          </Grid>
          <Grid id="section2" container spacing={2}>
            {/* {TextInput({ id: "adress", label: "Adress", size: 8 })} */}

            <Grid item xs={6}>
              <InputLabel sx={{ m: "10px 0" }}>
                <Typography>Gatunummer</Typography>
              </InputLabel>
              <Select
                fullWidth
                disabled={getAddressNumbers().length === 0}
                id="demo-simple-select"
                value={selectedStreetNumber?.toString() || ""}
                onChange={(e: SelectChangeEvent) => {
                  const new_street_number = parseInt(e.target.value);
                  setStreetNumber(new_street_number);
                }}
              >
                {getAddressNumbers().map((ad, index) => {
                  return (
                    <MenuItem value={ad.address_number} key={index}>
                      {ad.address_number}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            {TextInput({
              id: "port_id",
              label: "Port (bokstav)",
              size: 6,
              value: formData["port_id"] || "",
              handleChange: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    port_id: event.target.value,
                  };
                }),
            })}
            {TextInput({
              error: errors.apartment_number ? true : false,
              id: "apartment_number",
              label: "Lägenhetsnummer/utrymme*",
              size: 12,
              required: true,
              value: formData["apartment_number"],
              handleChange: handleChange,
            })}
            {TextInput({
              id: "tenant_name",
              label: "Namn*",
              size: 8,
              required: true,
              value: formData["tenant_name"],
              handleChange: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    tenant_name: event.target.value,
                  };
                }),
            })}
            {TextInput({
              id: "tenant_phone",
              label: "Telefon*",
              size: 4,
              required: true,
              value: formData["tenant_phone"],
              handleChange: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    tenant_phone: event.target.value,
                  };
                }),
            })}
          </Grid>
          {/* END SECTION 1 */}
          <Divider sx={{ m: "20px 0" }} />
          {/* START SECTION 2 */}
          <Typography variant="h3">Bolag</Typography>
          <Grid id="section1" container>
            <Grid item xs={isMobile ? 12 : 8}>
              <InputLabel sx={{ m: "10px 0" }}>
                <Typography>Företag</Typography>
              </InputLabel>
              <TextField
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                disabled
                placeholder="Välj adress för att bolaget ska fyllas i."
                value={formData.address ? formData.address.company.name : ""}
              />
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 4}
              sx={isMobile ? { m: "15px 0 0 0" } : gridStyle}
            >
              <InputLabel htmlFor="chargeTenant">
                <Typography>Debitera hyresgäst?</Typography>
              </InputLabel>
              <FormControl>
                <RadioGroup
                  aria-labelledby="chargeTenant"
                  value={formData.debit_tenant.toString()}
                  defaultValue={false}
                  onChange={(e) => {
                    const value = e.target.value === "true";
                    setFormData((prev) => {
                      return {
                        ...prev,
                        debit_tenant: value,
                      };
                    });
                  }}
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Nej"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Ja"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ m: "20px 0" }} />
          {/* END SECTION 2 */}
          {/* START SECTION 3 */}
          <Typography variant="h3">Jour</Typography>{" "}
          <Grid id="section3" container spacing={2}>
            {/* {TextInput({ id: "performer", label: "Utfört av", size: 6 })} */}
            <Grid item xs={isMobile ? 12 : 6}>
              {/* <AutoSearch
                                data={content.jourare}
                                id="worker"
                                label="Utfört av"
                                state={formData}
                                setState={setFormData}
                            /> */}
              <Grid
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                sx={{ height: "100%" }}
              >
                <InputLabel
                  htmlFor={"worker"}
                  sx={{ m: "10px 0", color: "white" }}
                >
                  <Typography>Utfört av</Typography>
                </InputLabel>
                <TextField
                  id="worker"
                  value={
                    auth.user
                      ? userFullname(auth.user) + " (du)"
                      : "Kunde inte hitta användare"
                  }
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <InputLabel
                htmlFor="date-picker"
                sx={{ m: "10px 0", color: "white" }}
              >
                <Typography>Välj datum</Typography>
              </InputLabel>
              <DateInput
                newDate={new Date(formData.created_at) || null}
                setNewDate={(d) => {
                  if (d) {
                    updateDate(d);
                  }
                }}
                showClearField={false}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <InputLabel
                htmlFor="time-picker"
                sx={{ m: "10px 0", color: "white" }}
              >
                <Typography>Tid avslutat ärende</Typography>
              </InputLabel>
              <TimeInput
                time={new Date(formData.created_time) || null}
                setTime={(d) => {
                  if (d) {
                    updateTime(d);
                  }
                }}
              />
            </Grid>
            {TextInput({
              id: "total_hours",
              label: "Summa timmar",
              size: 3,
              value: formData["total_hours"],
              handleChange: handleFloatChange,
            })}
          </Grid>
          <Grid>
            {showSupportJour && (
              <Grid id="section3" container spacing={2}>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  sx={isMobile ? { marginTop: "15px" } : {}}
                >
                  <AutoSearch
                    id="user"
                    data={users.filter((user) => user.role === 2)}
                    label="Stödjour"
                    state={additional_worker}
                    setState={setAdditionalWorker}
                    displayProp={"last_name"}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <InputLabel
                    htmlFor="date-picker"
                    sx={{ m: "10px 0", color: "white" }}
                  >
                    <Typography>Datum</Typography>
                  </InputLabel>
                  <DateInput
                    newDate={
                      new Date(
                        additional_worker.date ||
                          new Date().toISOString().slice(0, 10)
                      )
                    }
                    // setNewDate={updateDate}
                    setNewDate={(value) => {
                      if (!value) return;
                      setAdditionalWorker((prev) => {
                        return {
                          ...prev,
                          date: value.toISOString().slice(0, 10),
                        };
                      });
                    }}
                    showClearField={false}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <InputLabel
                    htmlFor="time-picker"
                    sx={{ m: "10px 0", color: "white" }}
                  >
                    <Typography>Tid avslutat ärende</Typography>
                  </InputLabel>
                  <TimeInput
                    time={
                      new Date(
                        additional_worker.end_time || new Date().toISOString()
                      )
                    }
                    setTime={(value) => {
                      if (!value) return;
                      setAdditionalWorker((prev) => {
                        return {
                          ...prev,
                          end_time: value.toISOString(),
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{ boxSizing: "border-box" }}
                  xs={isMobile ? 12 : 3}
                >
                  <InputLabel
                    htmlFor="additional_total_hours"
                    sx={{ m: "10px 0" }}
                  >
                    <Typography>Summa timmar</Typography>
                  </InputLabel>
                  <TextField
                    type="text"
                    id="additional_total_hours"
                    value={additional_worker["total_hours"] || ""}
                    onChange={(event) =>
                      setAdditionalWorker({
                        ...additional_worker,
                        total_hours: event.target.value.replace(
                          /[^0-9.]/gi,
                          ""
                        ),
                      })
                    }
                    fullWidth
                    // required
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <IconButton onClick={() => setShowSupportJour(!showSupportJour)}>
            {showSupportJour ? <PersonRemoveIcon /> : <PersonAddIcon />}
          </IconButton>
          {/* <Divider sx={{ m: 3 }} /> */}
        </Grid>
        {/* END SECTION 3 */}
        {/* Vertical or horizontal divider */}
        {!isMobile ? (
          <Grid item xs={1}>
            <Divider orientation="vertical" sx={{ m: "20px" }} />
          </Grid>
        ) : (
          <Divider sx={{ m: "20px 0" }} />
        )}
        {/* COLUMN 2 */}
        <Grid id="column2" item xs={isMobile ? 12 : 5}>
          {/* START SECTION 4 */}
          <Grid id="section4" container spacing={2} display="flex">
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection={"row"}
                alignItems="center"
                gap={2}
              >
                <Typography variant="h3">Bilagor</Typography>
                <Input
                  onChange={onChangeFile}
                  value={""}
                  type={"file"}
                  sx={{ display: "none" }}
                  inputProps={{
                    multiple: true,
                    ref: inputFile,
                    accept: "image/png, image/jpeg",
                  }}
                />
                <Button
                  variant="outlined"
                  sx={{ border: "1px solid rgba(58, 58, 60, 0.1)" }}
                  startIcon={<AttachFile />}
                  onClick={() => {
                    inputFile.current?.click();
                  }}
                >
                  Ladda upp filer
                </Button>
              </Box>
              <Box my={2}>
                {formData.attachments.map((file: File, idx: number) => {
                  return (
                    <Box width={"32ch"} key={idx}>
                      <Box
                        display={"flex"}
                        gap={2}
                        flexDirection="row"
                        justifyContent={"space-between"}
                        alignItems="flex-start"
                        key={file.name}
                        my={1}
                      >
                        <Typography>
                          {file.name.length > 30
                            ? file.name.slice(0, 27) + "..."
                            : file.name}
                        </Typography>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setFormData((prev) => {
                              return {
                                ...prev,
                                attachments: prev.attachments.filter(
                                  (at, i) => idx !== i
                                ),
                              };
                            })
                          }
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}

                {formData.attachments.length === 0 && (
                  <Typography>Inga filer valda</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ m: "20px 0" }} />
          {/* SECTION 5 */}
          <Typography variant="h3">Arbete</Typography>
          <Grid id="section5" container spacing={2} display="flex">
            {TextInput({
              id: " defect_description",
              label: "Felets art",
              size: 12,
              rows: 4,
              placeholder: "Beskriv vad felet är",
              value: formData["defect_description"],
              handleChange: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    defect_description: event.target.value,
                  };
                }),
            })}
            {TextInput({
              id: "actions_made",
              label: "Utfört arbete",
              size: 12,
              rows: 4,
              placeholder: "Beskriv det arbete som utförts på objektet",
              value: formData["actions_made"],
              handleChange: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    actions_made: event.target.value,
                  };
                }),
            })}
            {TextInput({
              id: "actions_to_be_made",
              label: "Arbete som bör utföras snarast",
              size: 12,
              rows: 4,
              placeholder: "Beskriv vilken typ av arbete som bör utföras",
              value: formData["actions_to_be_made"],
              handleChange: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    actions_to_be_made: event.target.value,
                  };
                }),
            })}
          </Grid>
          <Button
            disabled={isSubmitting}
            variant="contained"
            color="secondary"
            sx={
              isMobile
                ? {
                    width: "100%",
                    p: "10px 30px",
                    m: "30px 0px",
                  }
                : {
                    p: "10px 30px",
                    m: "30px 10px",
                    minWidth: "150px",
                  }
            }
            type="submit"
          >
            <Typography
              sx={{
                color: "white",
                textTransform: "none",
                fontWeight: 600,
              }}
            >
              {isSubmitting ? (
                <CircularProgress size={"1rem"} />
              ) : (
                "Skicka rapport"
              )}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
