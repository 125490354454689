import { useContext, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Address } from "../../utils/types";
import { Button, IconButton, Grid, Typography } from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../shared/SearchBar";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useAPI } from "../../API/useAPI";
import ConfirmDelete from "../shared/ConfirmDelete";

const columns: GridColDef[] = [
  { field: "address_street", headerName: "Gatunamn", width: 130 },
  { field: "address_number", headerName: "Nummer", width: 90 },
  { field: "cost_center", headerName: "Kostnadsställe", width: 130 },
];

interface Props {
  addresses: Array<Address>;
  setEditAddress: (any) => void;
  setShowAddressModal: (boolean) => void;
  getAddresses: () => void;
}
/**
 *
 * @param {Array<Address>} addresses - all addresses for specific company
 * @param {function} setEditAddress -
 * @param {function} setShowAddressModal - open or close address form modal
 * @param {function} getAddresses - get addresses from database
 * @param {function} editAddress - open modal to edit single address
 */
export default function AddressTable(props: Props) {
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const [selectedAddresses, setSelectedAddresses] = useState<Array<Address>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  /**
   * Empties edit address and opens modal
   */
  function handleNewAddressButton() {
    props.setEditAddress(undefined);
    props.setShowAddressModal(true);
  }

  const showDeleteDialog = () => {
    // setDeleteAddress(props.address.id);
    // setDialogTitle(
    //   `Är du säker på att du vill radera address: ${props.address.address_street} ${props.address.address_number}? `
    // );
    setDialogTitle(`Är du säker på att du vill radera addresser? `);
    setConfirmDialog(true);
  };

  function filterAddresses(ids) {
    console.log(ids);
    const selectedIDs = new Set(ids);
    console.log(selectedIDs);
    setSelectedAddresses(
      props.addresses.filter((row) => selectedIDs.has(row.id))
    );
  }

  /**
   * Delete checked addresses i table
   */
  const permDeleteAddresses = async () => {
    console.log("deleteWholeWeek");
    setLoading(true);

    let ids = selectedAddresses.map((a) => a.id);
    console.log(ids);
    if (ids.length > 0) {
      const res = await api.addresses.delete(ids);
      console.log(res);
      if (!res.data) {
        snackBar.show("Kunde inte radera. Försök igen.", "error");
      } else {
        snackBar.show(
          `${ids.length} ${
            ids.length > 1 ? " adresser " : " adress"
          } har tagits bort.`,
          "success"
        );
      }
      props.getAddresses();
      setConfirmDialog(false);
    }
    setLoading(false);
  };

  function handleEditButton() {
    if (selectedAddresses.length < 1)
      snackBar.show("Välj minst 1 adress att redigera.", "error");
    else {
      props.setEditAddress(selectedAddresses);
      props.setShowAddressModal(true);
    }
  }

  function handleDeleteButton() {
    if (selectedAddresses.length < 1)
      snackBar.show("Välj minst 1 adress att ta bort.", "error");
    else showDeleteDialog();
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <GridToolbarQuickFilter />
          <Grid>
            <IconButton onClick={() => handleEditButton()}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteButton()}>
              <DeleteIcon />
            </IconButton>
            <IconButton
              color="success"
              onClick={() => handleNewAddressButton()}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{ width: "100%" }}>
      {props.addresses.length > 0 ? (
        <DataGrid
          sx={{ border: "none" }}
          rows={props.addresses}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableColumnMenu={true}
          checkboxSelection
          autoHeight
          components={{ Toolbar: CustomToolbar }}
          onSelectionModelChange={(ids) => filterAddresses(ids)}
        />
      ) : (
        <>
          <Typography>Inga addresser hittades</Typography>
          <Button
            sx={{
              color: "black",
              border: 1,
              m: 1,
              width: "95%",
              borderColor: "#e0e0e0",
              borderStyle: "dashed",
            }}
            startIcon={<AddLocationAltIcon />}
            onClick={() => handleNewAddressButton()}
          >
            <Typography>Lägg till adress</Typography>
          </Button>
        </>
      )}
      {confirmDialog && (
        <ConfirmDelete
          onConfirm={() => permDeleteAddresses()}
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            props.getAddresses();
          }}
          title={dialogTitle}
        />
      )}
    </div>
  );
}
