import { useContext } from "react";
import { Grid } from "@mui/material";
import { MobileStateContext } from "../contexts/MobileContext";
import JourForm from "../components/jourreport/JourForm";

/**
 * Main component for JourReport
 * @returns
 */
export default function JourReport() {
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);

  return (
    <Grid sx={isMobile ? { p: "10px" } : { p: "30px" }}>
      <JourForm />
    </Grid>
  );
}
