import React, { useState, useContext } from "react";
import {
  ListItemText,
  Box,
  Typography,
  List,
  Grid,
  ListItem,
  Drawer,
  IconButton,
} from "@mui/material";
import logo from "../../logo.png";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { MobileStateContext } from "../../contexts/MobileContext";
import { useAuth } from "../../auth/hooks/useAuth";

/**
 * Component for menu
 * @returns
 */
export default function Menu() {
  const { isMobile, isIpad } = useContext(MobileStateContext);
  const auth = useAuth();

  const [showMenu, setShowMenu] = useState<boolean>();

  function openMenu() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  const list = () => (
    <List sx={!isIpad ? { display: "flex", flexDirection: "row" } : {}}>
      {[
        {
          name: "UPPDRAG",
          to: "/orders",
        },
        {
          name: "ARKIV",
          to: "/archive",
        },
        { name: "BOLAG", to: "/companies" },
        { name: "JOUR", to: "/jour" },
        { name: "INSTÄLLNINGAR", to: "/settings" },
        { name: "JOURRAPPORT", to: "/report" },
        {
          name: "MINA UPPDRAG",
          to: "/my-orders",
        },
      ].map((link, index) => (
        <NavLink
          key={index}
          to={link.to}
          onClick={closeMenu}
          style={({ isActive }) =>
            isActive
              ? {
                  textDecoration: "underline",
                  textDecorationColor: isIpad ? "white" : "#3A3A3C",
                }
              : { textDecoration: "none" }
          }
        >
          <ListItem button>
            <ListItemText>
              <Typography color={isIpad ? "white" : "primary"} variant="h4">
                {link.name}
              </Typography>
            </ListItemText>
          </ListItem>
        </NavLink>
      ))}
    </List>
  );

  if (auth.user && auth.isAdmin())
    return (
      <Grid
        sx={
          !isIpad
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }
            : {}
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 1,
            m: 1,
            borderRadius: 1,
          }}
        >
          <img src={logo} alt="Logo" width="100px" height="50px" />
          {isIpad && (
            <IconButton onClick={openMenu}>
              <MenuIcon fontSize="large"></MenuIcon>
            </IconButton>
          )}
        </Box>
        {isIpad ? (
          <Drawer
            anchor="right"
            open={showMenu}
            onClose={closeMenu}
            PaperProps={{
              sx: { width: "100%", backgroundColor: "#44A247" },
            }}
          >
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <IconButton
                onClick={closeMenu}
                sx={{ marginRight: "18px", marginTop: "18px" }}
              >
                <CloseIcon fontSize="large" sx={{ color: "white" }}></CloseIcon>
              </IconButton>
            </Grid>
            {list()}
          </Drawer>
        ) : (
          list()
        )}
      </Grid>
    );
  else return null;
}
