import { useContext, useEffect, useState } from "react";
import { MobileStateContext } from "../../contexts/MobileContext";
import {
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Grid,
  Radio,
  InputLabel,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DateInput from "../shared/DateInput";
import AutoSearch from "../shared/AutoSearch";
import { User } from "../../utils/types";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useAPI } from "../../API/useAPI";
import { daysOfWeek, YYMMDD } from "../../utils/time";

/** STYLE */
const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  overflowY: "scroll",
  maxHeight: "80vh",
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  handleClose: () => void;
  users: User[];
  getJourDates: () => void;
  date: Date;
}
interface FormData {
  worker: User;
  date: Date;
  bookWeek: boolean;
}

/**
 * Modal for booking an employee in jour table
 * @returns
 */
export default function BookEmployee(props: Props) {
  const snackBar = useContext(SnackbarContext);
  const api = useAPI();
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);
  const [formData, setFormData] = useState<FormData>({
    worker: props.users[0],
    date: props.date,
    bookWeek: false,
  });

  useEffect(() => {
    setFormData({ ...formData, date: props.date });
  }, [props]);

  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Sends form:
   */
  const sendForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await api.jours.create({
      worker: formData.worker.id,
      //   date: daysOfWeek(formData.date),
      date: formData.bookWeek
        ? daysOfWeek(formData.date)
        : [YYMMDD(formData.date)],
    });
    if (!res.data) {
      snackBar.show("Kunde inte spara. Försök igen.", "error");
    } else {
      snackBar.show("Sparat", "success");
      props.getJourDates();
      props.handleClose();
    }

    setLoading(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={[
          style,
          isIpad && { width: "80vw" },
          isMobile && { width: "100vw" },
        ]}
      >
        <CardHeader
          title={
            <Typography variant="h3" align="center">
              Schemalägg anställd
            </Typography>
          }
        />
        <IconButton
          aria-label="Example"
          onClick={props.handleClose}
          sx={closeButtonStyle}
        >
          <CloseIcon color="primary" sx={{ fontSize: 40 }} />
        </IconButton>
        <CardContent>
          <form onSubmit={sendForm}>
            <Grid
              container
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="stretch"
            >
              <Grid sx={{ width: "50%" }}>
                <AutoSearch
                  id="worker"
                  data={props.users}
                  label="Anställd"
                  state={formData}
                  setState={setFormData}
                  displayProp={"last_name"}
                />
              </Grid>
              <Grid sx={{ p: 1 }} />
              <InputLabel>
                <Typography>Startdatum</Typography>
              </InputLabel>
              <DateInput
                newDate={formData.date}
                setNewDate={(value) => {
                  if (value) {
                    setFormData({ ...formData, date: value });
                  }
                }}
                showClearField={false}
              />
              <Grid sx={{ p: 1 }} />
              <InputLabel htmlFor="chargeTenant">
                <Typography>Intervall</Typography>
              </InputLabel>
              <FormControl>
                <RadioGroup
                  aria-labelledby="chargeTenant"
                  value={formData.bookWeek}
                  name="radio-buttons-group"
                  onChange={() =>
                    setFormData({ ...formData, bookWeek: !formData.bookWeek })
                  }
                  row
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Ett datum"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Hel vecka"
                  />
                </RadioGroup>
              </FormControl>
              <Grid sx={{ m: 2 }} />

              <Button variant="contained" color="secondary" type="submit">
                <Typography
                  sx={{
                    color: "white",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Spara
                </Typography>
              </Button>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
