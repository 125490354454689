import React, { useState, useContext } from "react";
import {
  Typography,
  Grid,
  Button,
  Modal,
  Fade,
  Box,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import logo from "../../logo.png";
import { MobileStateContext } from "../../contexts/MobileContext";
import { useAuth } from "../../auth/hooks/useAuth";
import { Link, NavLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useAPI } from "../../API/useAPI";

interface PasswordChange {
  password: string;
  password_confirm: string;
}
const PASSWORD_STATES = {
  SHORT: "short",
  NOT_MATCHING: "not-matching",
  OK: "ok",
};
/** STYLE */
const closeButtonStyle = {
  position: "absolute",
  top: "20px",
  right: "20px",
};
/**
 * Menu for regular users (jourare)
 * @returns
 */
export default function JourareMenu() {
  const { isMobile, isDesktop } = useContext(MobileStateContext);
  const auth = useAuth();
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwords, setPasswords] = useState<PasswordChange>({
    password: "",
    password_confirm: "",
  });

  const activeMenuitemStyle = {
    textDecoration: "underline",
  };

  const validatePassword = (pws: PasswordChange) => {
    if (passwords.password !== passwords.password_confirm) {
      return PASSWORD_STATES.NOT_MATCHING;
    } else if (passwords.password.trim() === "") {
      return PASSWORD_STATES.SHORT;
    }

    return PASSWORD_STATES.OK;
  };

  const updatePassword = async () => {
    if (!auth.user) return;

    if (validatePassword(passwords) === PASSWORD_STATES.NOT_MATCHING) {
      snackBar.show("Lösenorden matchar inte", "error");
      return;
    } else if (validatePassword(passwords) === PASSWORD_STATES.SHORT) {
      snackBar.show("För kort lösenord", "error");
      return;
    }

    const res = await api.users.changePassword(
      auth.user.id,
      passwords.password,
      passwords.password_confirm
    );

    if (res.error) {
      snackBar.show("Det gick inte att byta lösenord", "error");
      return;
    }
    setPasswords({ password: "", password_confirm: "" });
    snackBar.show("Lösenordet har uppdaterats!", "success");
    setOpenChangePassword(false);
  };

  if (auth.user)
    return (
      <Grid
        display="flex"
        flexDirection={isDesktop ? "row" : "column"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ m: 1, p: 1 }}
      >
        <Grid display="flex" alignItems="center">
          <img src={logo} alt="Logo" width="100px" height="50px" />
          <NavLink
            to="/report"
            style={({ isActive }) =>
              isActive
                ? {
                    textDecoration: "underline",
                    textDecorationColor: "#3A3A3C",
                  }
                : { textDecoration: "none" }
            }
          >
            <Typography variant="h4" sx={{ m: 1, p: 1 }}>
              JOURRAPPORT
            </Typography>
          </NavLink>

          <NavLink
            // to={`/orders/${auth.user!.id}`}
            to="/my-orders"
            style={({ isActive }) =>
              isActive
                ? {
                    textDecoration: "underline",
                    textDecorationColor: "#3A3A3C",
                  }
                : { textDecoration: "none" }
            }
          >
            <Typography variant="h4" sx={{ m: 1, p: 1 }}>
              RAPPORTER
            </Typography>
          </NavLink>
        </Grid>
        <Grid
          display={isDesktop ? "block" : "flex"}
          flexDirection={isDesktop ? "column" : "row"}
        >
          <Button
            sx={{
              border: "1px solid rgba(58, 58, 60, 0.1)",
              m: "10px ",
              marginLeft: "5px",
              p: 2,
              borderRadius: 2,
            }}
            startIcon={<Lock />}
            onClick={() => setOpenChangePassword(true)}
          >
            Byt lösenord
          </Button>
          <Button
            id="demo-customized-button"
            variant="outlined"
            sx={{
              border: "1px solid rgba(58, 58, 60, 0.1)",
              m: "10px",
              p: 2,
              borderRadius: 2,
            }}
            startIcon={<LogoutIcon />}
            onClick={() => auth.logout()}
          >
            Logga ut
          </Button>
        </Grid>
        {/* CHANGE PASSWORD MODAL */}
        <Modal
          open={openChangePassword}
          onClose={() => setOpenChangePassword(false)}
          closeAfterTransition
        >
          <Fade in={openChangePassword}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",

                boxShadow: 24,
                p: 4,
              }}
            >
              <IconButton
                aria-label="Example"
                onClick={() => setOpenChangePassword(false)}
                sx={closeButtonStyle}
              >
                <CloseIcon color="primary" sx={{ fontSize: 40 }} />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Byt lösenord
                </Typography>
                <TextField
                  id="newPassword"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev) => !prev)}
                          //   onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Lösenord"
                  value={passwords.password}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setPasswords((prev) => {
                      return {
                        password: event.target.value,
                        password_confirm: prev.password_confirm,
                      };
                    });
                  }}
                />
                <TextField
                  id="password-confirm"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev) => !prev)}
                          //   onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Bekräfta lösenord"
                  value={passwords.password_confirm}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setPasswords((prev) => {
                      return {
                        password_confirm: event.target.value,
                        password: prev.password,
                      };
                    });
                  }}
                />
                <Button
                  onClick={updatePassword}
                  variant="contained"
                  color="secondary"
                >
                  Byt lösenord
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Grid>
    );
  else return null;
}
