import { useContext } from "react";
import { Typography, Grid, TextField, InputLabel } from "@mui/material";
import { MobileStateContext } from "../../contexts/MobileContext";

interface InputProps {
  id: string;
  label: string;
  size: number;
  value: string | number;
  placeholder?: string;
  type?: string;
  required?: boolean;
  rows?: number;
  error?: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

export default function TextInput(props: InputProps) {
  const { isMobile } = useContext(MobileStateContext);
  return (
    <Grid item sx={{ boxSizing: "border-box" }} xs={isMobile ? 12 : props.size}>
      <InputLabel htmlFor={props.id} sx={{ m: "10px 0" }}>
        <Typography>{props.label}</Typography>
      </InputLabel>
      <TextField
        error={props.error ? props.error : false}
        // type={props.type || "text"}
        id={props.id}
        key={props.id}
        value={props.value || ""}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        required={props.required}
        fullWidth
        multiline={props.rows ? true : false}
        rows={props.rows}
      />
    </Grid>
  );
}
