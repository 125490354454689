import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ConfirmResetPasswordRequest,
  CreateUser,
  Token,
  User,
} from "../../utils/types";
import axios, { AxiosError } from "axios";
import { API_URL, AuthErrors, USER_ROLES } from "../../utils/constants";
import Cookies from "js-cookie";

interface AuthContext {
  user: User | null;
  tokens: Token;
  loading: boolean;
  error: string | null;
  validateToken: Function;
  login: Function;
  logout: Function;
  isAdmin: Function;
  validatePasswordToken: Function;
  sendPasswordToken: Function;
  resetPasswordRequest: Function;
}

type APIUserResponse = {
  success: boolean;
  statusCode: number;
  message: string;
};

const defaultSettings: AuthContext = {
  user: null,
  tokens: {
    access: null,
    refresh: null,
  },
  error: null,
  loading: true,
  validateToken: () => {},
  validatePasswordToken: () => {},
  sendPasswordToken: () => {},
  resetPasswordRequest: () => {},
  login: () => {},
  logout: () => {},
  isAdmin: () => {},
};

const authContext = createContext(defaultSettings);

interface Props {
  children?: ReactNode;
}

export function AuthProvider({ children }: Props) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [tokens, setTokens] = useState<Token>({
    access: null,
    refresh: null,
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const cookie = Cookies.get("django_jwt");
    if (cookie) {
      const token: Token = JSON.parse(cookie);
      if (token.access && token.refresh) {
        validateToken(token.access)
          .then((res) => {
            updateUser(token);
            setTokens(token);
          })
          .catch((er) => {
            if (!token.refresh) {
              setUser(null);
              return;
            }
            refreshToken(token.refresh)
              .then((res) => {
                const new_token: Token = {
                  access: res.data.access,
                  refresh: res.data.refresh,
                };
                setTokens(new_token);
                setJWTCookie(new_token);
                updateUser(new_token);
              })
              .catch((er) => {
                setUser(null);
                setLoading(false);
                setError(AuthErrors.TOKEN_EXPIRED);
              });
          });
      }
    }

    // ELSE DENIED
    setTokens({
      access: "",
      refresh: "",
    });
    setUser(null);
    setLoading(false);
    setError(AuthErrors.TOKEN_NOT_FOUND);
  }, []);

  const updateUser = (token: Token) => {
    axios
      .get(`${API_URL}/auth/users/me`, {
        headers: {
          Authorization: `JWT ${token.access}`,
        },
      })
      .then((res) => {
        setUser(res.data.data);
        setLoading(false);
      });
  };

  const validateToken = async (access_token: string) => {
    return axios.post(
      `${API_URL}/auth/token/verify/`,

      {
        token: access_token,
      }
    );
  };

  const refreshToken = async (refresh_token: string) => {
    return axios.post(`${API_URL}/auth/token/refresh/`, {
      refresh: refresh_token,
    });
  };

  const isAdmin = () => {
    if (!user) {
      throw new Error("User is not defined");
    }
    return user.role === USER_ROLES.ADMIN;
  };

  const login = async (username: string, password: string) => {
    setLoading(true);
    return axios
      .post(
        `${API_URL}/auth/login`,

        {
          email: username,
          password: password,
        }
      )
      .then((response) => {
        if (response.data.access) {
          const jwt: Token = {
            access: response.data.access,
            refresh: response.data.refresh,
          };
          setError(null);
          setJWTCookie(jwt);
          setUser(response.data.authenticatedUser);
          setLoading(false);
        }
        return { success: true, error: null };
      })
      .catch((er) => {
        console.log(er);
        setLoading(false);
        setUser(null);
        setError(AuthErrors.INVALID_CREDENTIALS);
        return {
          success: false,
          error: AuthErrors.INVALID_CREDENTIALS,
          errorMessage: er.response.data
            ? er.response.data.non_field_errors
            : "Nätverksproblem",
        };
      });
  };

  const setJWTCookie = (token: Token) => {
    const jwt = {
      access: token.access,
      refresh: token.refresh,
    };
    Cookies.set("django_jwt", JSON.stringify(jwt));
  };

  const logout = () => {
    Cookies.remove("django_jwt");
    setUser(null);
  };

  /** RESET PASSWORD FUNCTIONS */
  const sendPasswordToken = async (email: string) => {
    console.log(email);
    try {
      const response = await axios.post(`${API_URL}/auth/password_reset/`, {
        email: email,
      });

      console.log(response);

      return true;
    } catch (error: any | AxiosError) {
      console.log(error);
      return false;
    }
  };

  const resetPasswordRequest = async ({
    token,
    password,
  }: ConfirmResetPasswordRequest) => {
    return axios.post(`${API_URL}/auth/password_reset/confirm/`, {
      token: token,
      password: password,
    });
  };

  const validatePasswordToken = async (access_token: string) => {
    return axios.post(
      `${API_URL}/auth/password_reset/validate_token/`,

      {
        token: access_token,
      }
    );
  };

  const AuthService = {
    user,
    loading,
    error,
    tokens,
    validateToken,
    login,
    logout,
    isAdmin,
    validatePasswordToken,
    sendPasswordToken,
    resetPasswordRequest,
  };
  return AuthService;
};
