import { useContext, useEffect, useState } from "react";
import { PaginatedUrlParams, useAPI } from "../../../API/useAPI";
import { useAuth } from "../../../auth/hooks/useAuth";
import { SnackbarContext } from "../../../contexts/SnackbarProvider";
import OrdersPaginated from "../../../components/orders/paginated/OrdersPaginated";
import { YYMMDD } from "../../../utils/time";
import { JourReports, SortColumn } from "../../../utils/types";

interface Props {
  ordersTitle: string;
  fetchArchived: boolean;
  fetchMyOrders: boolean;
}

/**
 * Component for Orders
 * @returns
 */
export default function Orders({
  ordersTitle,
  fetchArchived,
  fetchMyOrders,
}: Props) {
  const api = useAPI();
  const auth = useAuth();
  const snackBar = useContext(SnackbarContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [companyNames, setCompanyNames] = useState<string[]>([]);
  useEffect(() => {
    async function getCompanies() {
      const res = await api.companies.all();

      if (res.data) {
        const names = res.data.data.map((c) => c.name);
        setCompanyNames(names);
      } else {
        snackBar.show("Kunde inte hämta företag. ", "error");
      }
    }
    getCompanies();
  }, []);
  const [reports, setAllReports] = useState<JourReports[]>([]);
  const [reportCount, setReportCount] = useState<number>(0);
  // we make sure that reportcount can't ever be undefined as mentioned
  // here https://v5.mui.com/x/react-data-grid/pagination/#server-side-pagination
  useEffect(() => {
    setReportCount((prevCount) =>
      reportCount !== undefined ? reportCount : prevCount
    );
  }, [reportCount, setReportCount]);

  // this is a state that only exists such that we can force the below useEffect to rerun
  const [fetchJourReportsCounter, setFetchJourReportsCounter] = useState(1);
  const signalFetchJourReports = () => {
    setFetchJourReportsCounter(fetchJourReportsCounter + 1);
  };

  // filtering
  const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [filterCompany, setFilterCompany] = useState<string | null>(null);
  // freeText filtering, should have quite a performance cost
  const [filterText, _setFilterText] = useState<string | null>(null);
  function setFilterText(s: string | null) {
    _setFilterText(s);
  }

  const [page, _setPage] = useState<number>(0);
  function setPage(p: number) {
    if (p < 0 || reportCount / pageSize <= p) return;
    _setPage(p);
  }

  const [pageSize, setPageSize] = useState(10);

  // sorting related states
  const [columnToSort, _setColumnToSort] = useState<SortColumn>(
    SortColumn.CustomId
  );
  function setColumnToSort(col: string) {
    if (["custom_id", "date", "name", "company"].includes(col)) {
      _setColumnToSort(col as SortColumn);
    }
  }

  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    const fetchJourReports = async () => {
      setLoading(true);

      const date_str = filterDate ? YYMMDD(filterDate) : undefined;
      const params: PaginatedUrlParams = {
        date: date_str, // optional filtering
        company: filterCompany ? filterCompany : undefined, // optional filtering
        searchText: filterText ? filterText : undefined,
        sortBy: columnToSort, // required
        sortDir: sortDirection, // required
        page: page + 1, // required, the paging on the packend starts with index 1 while the paging in the DataGrid modal starts at 0
        pageSize: pageSize, // required
        archived: fetchArchived,
        myOrders: fetchMyOrders,
      };
      console.log("params: ", params);

      const res = await api.jourReports.paginated(params);
      console.log("response: ", res);

      if (res.data) {
        setReportCount(res.data.rowCount);
        setAllReports(res.data.data);
      } else {
        snackBar.show("Kunde inte hämta rapporter. ", "error");
      }
      setLoading(false);
    };
    fetchJourReports();
  }, [
    auth,
    fetchJourReportsCounter,
    sortDirection,
    columnToSort,
    pageSize,
    page,
    filterCompany,
    filterDate,
    filterText,
  ]);

  return (
    <OrdersPaginated
      setColumnToSort={setColumnToSort}
      setSortDirection={setSortDirection}
      filterDate={filterDate}
      setFilterDate={setFilterDate}
      filterCompany={filterCompany}
      setFilterCompany={setFilterCompany}
      filterText={filterText}
      setFilterText={setFilterText}
      reportCount={reportCount}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      companyNames={companyNames}
      setPageSize={setPageSize}
      reports={reports}
      signalFetchJourReports={signalFetchJourReports}
      loading={loading}
      ordersTitle={ordersTitle}
      fetchedArchive={fetchArchived}
    />
  );
}
