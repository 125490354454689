import { useContext, useState, useEffect } from "react";
import {
    Typography,
    Grid,
    Divider,
    Button,
    Card,
    CircularProgress,
} from "@mui/material";
import { MobileStateContext } from "../../contexts/MobileContext";
import SettingsIcon from "@mui/icons-material/Settings";
import { Address, Companies, Company } from "../../utils/types";
import CenteredGrid from "../shared/CenteredGrid";
import InfoIcons from "./InfoIcons";
import { useAPI } from "../../API/useAPI";
import { useAuth } from "../../auth/hooks/useAuth";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import AddressFormModal from "../addresses/AddressFormModal";
import DeleteIcon from "@mui/icons-material/Delete";
import GetReportButton from "./GetReportButton";
import ConfirmDelete from "../shared/ConfirmDelete";
import AddressTable from "../addresses/AddressTable";

interface Props {
    company: Company;
    showCompanyForm: () => void;
    getCompanies: () => void;
    setEditCompany: (Company) => void;
}

const buttonStyle = {
    color: "black",
    border: 1,
    m: 1,
    p: 1,
    borderRadius: "10px",
    borderColor: "#e0e0e0",
};
/**
 * Component for companyInfo
 * @returns
 */
export default function CompanyInfo(props: Props) {
    const auth = useAuth();
    const api = useAPI();

    const { isMobile, isDesktop } = useContext(MobileStateContext);
    const snackBar = useContext(SnackbarContext);

    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [editAddress, setEditAddress] = useState<Address[] | undefined>(
        undefined
    );
    const [deleteCompany, setDeleteCompany] = useState<number>();
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
    const [allAddresses, setAllAddresses] = useState<Address[]>([]);
    const [companyAddresses, setCompanyAddresses] = useState<Address[]>([]);

    // Get addresses
    useEffect(() => {
        getAddresses();
    }, [props.company]);

    // Call function to filter addresses
    // useEffect(() => {
    //   filterAddresses();
    // }, [addresses.all]);

    /**
     * Update addresses when active company changes
     */
    // function filterAddresses() {
    //   // setIsLoading(true);
    //   if (addresses.all.length > 0) {
    //     const companyAddresses = addresses.all.filter(
    //       (address) => address.company.id === props.company.id
    //     );

    //     updateAddress("filtered", companyAddresses);
    //   }
    //   // setIsLoading(false);
    // }

    /**
     * Function to get all addresses from api
     */
    const getAddresses = async () => {
        setIsLoading(true);

        const res = await api.addresses.all();

        if (res.error) {
            snackBar.show("Kunde inte hämta addresser.", "error");
        } else {
            setAllAddresses(res.data!.data);

            setCompanyAddresses(
                res.data!.data.filter((address) =>
                    address.company
                        ? address.company.id === props.company.id
                        : false
                )
            );
        }

        setIsLoading(false);
    };

    /**
     * When user click on button to edit company
     */
    function handleEditButton() {
        props.setEditCompany(props.company);
        props.showCompanyForm();
    }

    /**
     * Show modal to confirm delete
     */
    const showDeleteDialog = (company: Company) => {
        setDeleteCompany(company.id);
        setDialogTitle(
            `Är du säker på att du vill radera bolag ${company.name}? `
        );
        setConfirmDialog(true);
    };

    /**
     * Archive company in database.
     */
    const archiveCompany = async () => {
        if (deleteCompany) {
            setIsLoading(true);

            // const res = await api.companies.delete(deleteCompany);
            // instead of deleting the company we enable it's archived field
            const companyId = deleteCompany;
            // When "deleting" a company, we set a flag such that this company will not be shown.
            // we also replace the contact information with dummy values to satisfy the validation done by backend.
            const res = await api.companies.update(companyId, {
                archived: true,
                contact_email: "deleted@oldcompany.com",
                contact_name: "deleted",
                contact_phone: "123",
            });
            console.log(res);

            if (!res.data) {
                snackBar.show("Kunde inte radera bolag.", "error");
            } else {
                snackBar.show("Bolaget har tagits bort permanent", "success");
            }
            setIsLoading(false);
            props.getCompanies();
            setConfirmDialog(false);
        }
    };
    console.log(allAddresses);

    return (
        <Card
            sx={
                !isDesktop
                    ? { p: 1, width: "100%", boxShadow: "none" }
                    : {
                          p: 2,
                          m: 2,
                          top: "70px",
                          height: "80vh",
                          overflowY: "scroll",
                          boxSizing: "border-box",
                          position: "sticky",
                      }
            }
        >
            <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Grid
                    item
                    xs={12}
                    sx={{ m: 1, width: "100%" }}
                    display="flex"
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        sx={
                            isDesktop
                                ? { textAlign: "left" }
                                : { textAlign: "center" }
                        }
                    >
                        {props.company.name}
                    </Typography>
                </Grid>

                <Grid
                    container
                    sx={{ width: "100%", justifyContent: "center" }}
                >
                    <GetReportButton company={props.company} />
                    <Button
                        sx={buttonStyle}
                        startIcon={<SettingsIcon />}
                        onClick={() => handleEditButton()}
                    >
                        <Typography>Redigera</Typography>
                    </Button>
                    <Button
                        sx={buttonStyle}
                        startIcon={<DeleteIcon />}
                        onClick={() => showDeleteDialog(props.company)}
                    >
                        <Typography>Ta bort</Typography>
                    </Button>
                </Grid>
                {/* <InfoIcons
          company={props.company}
          showText={true}
          // align={isDesktop ? "space-between" : "center"}
          align="center"
          // setIsLoading={setIsLoading}
        /> */}
            </Grid>
            <br></br>
            <Divider />
            <Grid sx={{ m: 2 }} />
            <Typography variant="h3" sx={{ m: 1 }}>
                Uppgifter
            </Typography>
            <Grid container>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Typography
                        sx={{
                            textAlign: "center",
                            // isDesktop
                            //   ? { textAlign: "left", width: "100%" }
                            //   : { textAlign: "center", width: "100%" }
                        }}
                    >
                        <b>Kontaktperson: </b>
                        {props.company.contact_name && (
                            <>
                                <br></br>
                                {props.company.contact_name}
                            </>
                        )}
                        {props.company.contact_phone && (
                            <>
                                <br></br>
                                {props.company.contact_phone}
                            </>
                        )}
                        {props.company.contact_email && (
                            <>
                                <br></br>
                                <a
                                    href={`mailto:${props.company.contact_email}`}
                                >
                                    {props.company.contact_email}
                                </a>
                            </>
                        )}
                    </Typography>
                </Grid>
                {isMobile && <Grid sx={{ m: "10px" }} />}
                <Grid item xs={isMobile ? 12 : 6}>
                    <Typography sx={{ textAlign: "center" }}>
                        <b>Faktureringsadress: </b>
                        <br></br>
                        {props.company.billing_address ? (
                            <>{props.company.billing_address}</>
                        ) : (
                            "Ingen faktureringsadress"
                        )}
                        {props.company.billing_city && (
                            <>
                                <br></br>
                                {props.company.billing_city}
                            </>
                        )}
                        {props.company.billing_postal && (
                            <>
                                <br></br>
                                {props.company.billing_postal}
                            </>
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid sx={{ m: 2 }} />
            <Divider />
            <Grid sx={{ m: 2 }} />
            <Typography variant="h3" sx={{ m: 1 }}>
                Adresser
            </Typography>

            <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CenteredGrid>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <AddressTable
                            addresses={companyAddresses}
                            setEditAddress={setEditAddress}
                            getAddresses={getAddresses}
                            setShowAddressModal={setShowAddressModal}
                        />
                    )}
                </CenteredGrid>
            </Grid>

            {showAddressModal && (
                <AddressFormModal
                    company={props.company}
                    open={showAddressModal}
                    setIsLoading={setIsLoading}
                    editAddresses={editAddress}
                    getAddresses={getAddresses}
                    allAddresses={allAddresses}
                    handleClose={() => setShowAddressModal(false)}
                />
            )}
            {confirmDialog && (
                <ConfirmDelete
                    onConfirm={() => archiveCompany()}
                    open={confirmDialog}
                    handleClose={() => {
                        setConfirmDialog(false);
                    }}
                    title={dialogTitle}
                />
            )}
        </Card>
    );
}
