import React, { useState } from "react";
import { Alert, Snackbar, AlertColor } from "@mui/material";

interface SnackbarState {
  show: (message: string, severity: "success" | "error") => void;
  close: () => void;
}

const initialState: SnackbarState = {
  show: () => {},
  close: () => {},
};

export const SnackbarContext = React.createContext(initialState);

interface SnackbarProviderProps {
  children: React.ReactElement;
}

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  function show(message: string, severity: "success" | "error") {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  }

  function close() {
    setOpen(false);
  }

  return (
    <>
      <SnackbarContext.Provider
        value={{
          show,
          close,
        }}
      >
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={close}
        autoHideDuration={4000}
      >
        <Alert elevation={6} variant="filled" severity={severity}>
          {" "}
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
