import { Api } from "@mui/icons-material";
import axios, { AxiosError } from "axios";
import { id } from "date-fns/locale";
import Cookies from "js-cookie";
import { stringify } from "querystring";
import { useEffect, useState } from "react";
import { API_ERROR, API_URL } from "../utils/constants";
import {
  AddressCreate,
  Addresses,
  AddressUpdate,
  AddressUpdateSingle,
  ChangePasswordRequest,
  Companies,
  Company,
  CompanyCreate,
  CompanyUpdate,
  JourDate,
  JourDateCreate,
  JourDateUpdate,
  JourReportCreate,
  JourReports,
  JourReportUpdate,
  SortColumn,
  SuperUserCreate,
  Token,
  User,
  UserCreate,
  UserUpdate,
} from "../utils/types";

interface GetAllRequest {
  endpoint: string;
}

export interface PaginatedUrlParams {
  // fields we filter by
  date?: string; // we need the string object to be in the yyyy-mm-dd format
  company?: string;
  archived?: boolean;
  myOrders?: boolean;
  searchText?: string; // free text search
  // fields we sort by
  sortBy: SortColumn;
  sortDir: "asc" | "desc";
  // page data
  page: number;
  pageSize: number;
}

interface GetRequestPaginated {
  endpoint: string;
  urlParams: PaginatedUrlParams;
}

export interface PaginatedResponse<T> {
  data: {
    rowCount: number;
    previousPage: string | null;
    nextPage: string | null;
    data: T[];
  } | null;
  error: {
    code: number;
    message: string;
  } | null;
}

interface GetSingleRequest {
  endpoint: string;
  id: number;
}

interface DjangoResponse<T> {
  success: boolean;
  statusCode: string;
  message: string;
  data: T[];
}

interface APIGetSingleResponse<T> {
  data: T | null;
  error: {
    code: number;
    message: string;
  } | null;
}

interface APIGetAllResponse<T> {
  data: DjangoResponse<T> | null;
  error: {
    code: number;
    message: string;
  } | null;
}

interface APICreateRequest<T> {
  body: T;
  endpoint: string;
}

interface APICreateWithFilesRequest<T> {
  body: T;
  endpoint: string;
  files: File[];
}

interface APIUpdateWithFilesRequest<T> {
  body: T;
  id: number;
  endpoint: string;
  files: File[];
}

interface APICreateResponse {
  data: {
    message: string;
    status: number;
  } | null;

  error: {
    code: number;
    message: string;
  } | null;
}
interface APIUpdateResponse {
  data: {
    message: string;
    status: number;
  } | null;

  error: {
    code: number;
    message: string;
  } | null;
}
interface APIUpdateRequest<T> {
  body: T;
  endpoint: string;
  id: number;
}

interface APIBulkUpdateRequest<T> {
  body: {
    new_cost_center: string;
    ids: number[];
  };
  endpoint: string;
}

interface APIDeleteRequest {
  id: number;
  endpoint: string;
}

interface BULKDeleteAddresses {
  ids: number[];
  // endpoint: string;
}

interface APIDeleteResponse {
  data: {
    message: string;
    status: number;
  } | null;

  error: {
    code: number;
    message: string;
  } | null;
}

const getMessageOfCode = (code: number) => {
  switch (code) {
    case 404:
      return API_ERROR.NOT_FOUND;
    case 403:
      return API_ERROR.NOT_AUTHORIZED;
    case 401:
      return API_ERROR.NOT_AUTHORIZED;
    default:
      return API_ERROR.UNKNOWN_ERROR;
  }
};

const getJWTFromCookie = (): string => {
  const cookie = Cookies.get("django_jwt");
  if (cookie) {
    const jwt: Token = JSON.parse(cookie);
    if (jwt.access) {
      return jwt.access;
    }
  }

  return "asd";
};

export const useAPI = () => {
  const [access_token, setToken] = useState<string>(getJWTFromCookie());

  // *** GET ALL FROM DATABASE ***
  const getAll = async <T,>(
    req: GetAllRequest
  ): Promise<APIGetAllResponse<T>> => {
    try {
      const response = await axios.get(`${API_URL}/${req.endpoint}`, {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });

      const APIres: APIGetAllResponse<T> = {
        data: await response.data,
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIGetAllResponse<T> = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIGetAllResponse<T> = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  // *** GET INSTANCE FROM DATABASE ***
  const getSingle = async <T,>(
    req: GetSingleRequest
  ): Promise<APIGetSingleResponse<T>> => {
    try {
      const response = await axios.get(`${API_URL}/${req.endpoint}/${req.id}`, {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });

      const APIres: APIGetSingleResponse<T> = {
        data: await response.data,
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIGetSingleResponse<T> = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIGetSingleResponse<T> = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  // *** GET INSTANCES FROM DATABASE using paginated approach***
  const getPaginated = async <T,>(
    req: GetRequestPaginated
  ): Promise<PaginatedResponse<T>> => {
    try {
      const response = await axios.get(`${API_URL}/${req.endpoint}`, {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
        params: req.urlParams,
      });

      const djangoData = await response.data;

      const data: {
        rowCount: number;
        previousPage: string;
        nextPage: string;
        data: T[];
      } = {
        rowCount: djangoData["count"] ?? 0,
        previousPage: djangoData["previous"],
        nextPage: djangoData["next"],
        data: djangoData["results"] ?? [],
      };

      const APIres: PaginatedResponse<T> = {
        data: data,
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: PaginatedResponse<T> = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: PaginatedResponse<T> = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  // *** CREATE NEW INSTANCE IN DATABASE ***
  const create = async <T,>(
    req: APICreateRequest<T>
  ): Promise<APICreateResponse> => {
    try {
      const response = await axios.post(
        `${API_URL}/${req.endpoint}`,
        req.body,
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );
      const APIres: APICreateResponse = {
        data: {
          message: "Created",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };
  // *** UPDATE INSTANCE IN DATABASE ***
  const update = async <T,>(
    req: APIUpdateRequest<T>
  ): Promise<APIUpdateResponse> => {
    try {
      const response = await axios.patch(
        `${API_URL}/${req.endpoint}/${req.id}`,
        req.body,
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );
      const APIres: APIUpdateResponse = {
        data: {
          message: "Updated",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  const deleteMany = async <T,>(req: APICreateRequest<T>) => {
    try {
      const response = await axios.delete(`${API_URL}/${req.endpoint}`, {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
        data: {
          ...req.body,
        },
      });
      const APIres: APIDeleteResponse = {
        data: {
          message: response.data.message,
          status: 204,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIDeleteResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIDeleteResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  // *** DELETE INSTANCE IN DATABASE ***
  const deleteOne = async (
    req: APIDeleteRequest
  ): Promise<APIDeleteResponse> => {
    try {
      const response = await axios.delete(
        `${API_URL}/${req.endpoint}/${req.id}`,
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );
      const APIres: APIDeleteResponse = {
        data: {
          message: "Deleted",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIDeleteResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIDeleteResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  /** Bulk delete addresses */
  const deleteAddresses = async <T,>(req: APICreateRequest<T>) => {
    try {
      const response = await axios.post(
        `${API_URL}/addresses/bulk_delete`,
        {
          ...req.body,
        },
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );

      const APIres: APIDeleteResponse = {
        data: {
          message: response.data.message,
          status: 204,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIDeleteResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIDeleteResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  // *** Change cost center of multiple addresses ***
  const bulkUpdateAddresses = async <T,>(
    req: APIBulkUpdateRequest<T>
  ): Promise<APIUpdateResponse> => {
    try {
      const response = await axios.post(
        `${API_URL}/addresses/bulk_alter`,
        req.body,
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );
      const APIres: APIUpdateResponse = {
        data: {
          message: "Updated",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  // *** Change cost center of multiple addresses ***
  const bulkToggleArchived = async (req: {
    ids: number[];
  }): Promise<APIUpdateResponse> => {
    try {
      const response = await axios.post(
        `${API_URL}/jour-reports/toggle-archived`,
        {
          ids: req.ids,
        },
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );
      const APIres: APIUpdateResponse = {
        data: {
          message: "Updated",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  const createWithAttachments = async <T,>(
    req: APICreateWithFilesRequest<T>
  ): Promise<APICreateResponse> => {
    try {
      const formdata = prepareDataWithFiles(req);
      const response = await axios.post(
        `${API_URL}/${req.endpoint}`,
        formdata,
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );

      if (response.data) {
        const res: APICreateResponse = {
          data: {
            message: response.data.message,
            status: response.data.statuscode,
          },
          error: null,
        };
        return res;
      } else {
        const res: APICreateResponse = {
          data: null,
          error: {
            code: response.status,
            message: response.statusText,
          },
        };
        return res;
      }
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  const createWithoutAttachments = async <T,>(
    req: APICreateRequest<T>
  ): Promise<APICreateResponse> => {
    try {
      const response = await axios.post(
        `${API_URL}/${req.endpoint}`,
        JSON.stringify(req.body),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access_token}`,
          },
        }
      );

      if (response.data) {
        const res: APICreateResponse = {
          data: {
            message: response.data.message,
            status: response.data.statuscode,
          },
          error: null,
        };
        return res;
      } else {
        const res: APICreateResponse = {
          data: null,
          error: {
            code: response.status,
            message: response.statusText,
          },
        };
        return res;
      }
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        let res: any = error.response?.data as any;
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: res.statusCode ?? 500,
            message: res.message ?? getMessageOfCode(res.statusCode ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };
  const updateWithAttachments = async <T,>(
    req: APIUpdateWithFilesRequest<T>
  ): Promise<APICreateResponse> => {
    try {
      const formdata = prepareDataWithFiles(req);
      const response = await axios.patch(
        `${API_URL}/${req.endpoint}`,
        formdata,
        {
          headers: {
            Authorization: `JWT ${access_token}/${req.id}`,
          },
        }
      );
      const APIres: APICreateResponse = {
        data: {
          message: "Created",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APICreateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };
  const prepareDataWithFiles = <T,>(req: APICreateWithFilesRequest<T>) => {
    const formData = new FormData();
    req.files.forEach((file) => {
      formData.append("attachment", file);
    });

    formData.append("data", JSON.stringify(req.body));

    return formData;
  };

  const changePassword = async (
    req: ChangePasswordRequest
  ): Promise<APIUpdateResponse> => {
    try {
      const response = await axios.patch(
        `${API_URL}/auth/change-password/${req.id}`,
        {
          password: req.password,
          password_confirm: req.password_confirm,
        },
        {
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        }
      );
      const APIres: APIUpdateResponse = {
        data: {
          message: "Updated",
          status: 201,
        },
        error: null,
      };
      return APIres;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: error.response?.status ?? 500,
            message: getMessageOfCode(error.response?.status ?? 500),
          },
        };
        return APIres;
      } else {
        const APIres: APIUpdateResponse = {
          data: null,
          error: {
            code: 500,
            message: getMessageOfCode(500),
          },
        };
        return APIres;
      }
    }
  };

  const downloadJourReportAsPDF = async (id: number, file_name: string) => {
    try {
      const response = await axios.get(`${API_URL}/download/jourreport/${id}`, {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file_name}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      //link.parentNode?.removeChild(link);

      return true;
    } catch (error: any | AxiosError) {
      console.log(error);
      return false;
    }
  };

  const getCSVFromCompany = async (
    company: Company,
    start_date: string,
    end_date: string
  ) => {
    const response = await axios.post(
      `${API_URL}/download/company-summary/${company.id}`,
      {
        start: start_date,
        end: end_date,
      },

      {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
        responseType: "blob",
      }
    );

    const href = URL.createObjectURL(response.data);

    // create "a" HTLM element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${company.name}__${start_date}-${end_date}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
  };

  useEffect(() => {
    const jwt = getJWTFromCookie();
    if (jwt) {
      setToken(jwt);
      // console.log(jwt);
    }
  }, []);

  const api = {
    companies: {
      all: () => getAll<Companies>({ endpoint: "companies" }),
      one: (id: number) =>
        getSingle<Companies>({ endpoint: "companies", id: id }),
      create: (company: CompanyCreate) =>
        create({ body: company, endpoint: "companies" }),
      update: (id: number, company: CompanyUpdate) =>
        update({ body: company, endpoint: "companies", id: id }),
      delete: (id: number) => deleteOne({ endpoint: "companies", id: id }),
    },
    addresses: {
      all: () => getAll<Addresses>({ endpoint: "addresses" }),
      one: (id: number) =>
        getSingle<Addresses>({ endpoint: "addresses", id: id }),
      create: (address: AddressCreate) =>
        create({ body: address, endpoint: "addresses" }),
      update: (id: number, address: AddressUpdate | AddressUpdateSingle) =>
        update({ body: address, endpoint: "addresses", id: id }),
      bulkUpdate: (ids: number[], new_cost_center: string) =>
        bulkUpdateAddresses({
          endpoint: "addresses",
          body: { ids: ids, new_cost_center: new_cost_center },
        }),
      delete: (ids: number[]) =>
        deleteAddresses({ endpoint: "addresses", body: { ids } }),
    },
    jourReports: {
      all: () => getAll<JourReports>({ endpoint: "jour-reports" }),
      paginated: (urlParams: PaginatedUrlParams) =>
        getPaginated<JourReports>({
          endpoint: "jour-reports/paginated",
          urlParams: urlParams,
        }),
      toggleArchived: (id: number) => bulkToggleArchived({ ids: [id] }),
      toggleArchivedMany: (ids: number[]) =>
        bulkToggleArchived({
          ids: ids,
        }),
      one: (id: number) =>
        getSingle<JourReports>({ endpoint: "jour-reports", id: id }),
      create: (report: JourReportCreate) =>
        createWithoutAttachments({
          body: report,
          endpoint: "jour-reports",
        }),
      update: (id: number, report: JourReportUpdate) =>
        update({ body: report, endpoint: "jour-reports", id: id }),
      delete: (id: number) => deleteOne({ endpoint: "jour-reports", id: id }),
      // since the bulk delete operation for the reports is backed by a post endpoint we utilize the create function to delete the reports
      // the reasoning behind using post for bulk delete operations is that a REST delete request does not support request bodies (e.g list of ids)
      // in the standard. As such the design of the deleteMany operation here is non-standard and might not work when using any middleware.
      deleteMany: (ids: number[]) =>
        create({ endpoint: "jour-reports/bulk_delete", body: { ids: ids } }),
      createWithAttachments: (files: File[], body: JourReportCreate) =>
        createWithAttachments({
          files: files,
          body: body,
          endpoint: "jour-reports",
        }),
      updateeWithAttachments: (
        id: number,
        files: File[],
        body: JourReportUpdate
      ) =>
        updateWithAttachments({
          id: id,
          files: files,
          body: body,
          endpoint: "jour-reports",
        }),
    },
    jours: {
      all: (start_date?: string, end_date?: string) =>
        getAll<JourDate[]>({
          endpoint:
            start_date && end_date
              ? `jours?start_date=${start_date}&end_date=${end_date}`
              : "jours",
        }),
      one: (id: number) => getSingle<JourDate>({ endpoint: "jours", id: id }),
      create: (address: JourDateCreate) =>
        create({ body: address, endpoint: "jours" }),
      update: (id: number, address: JourDateUpdate) =>
        update({ body: address, endpoint: "jours", id: id }),
      deleteOne: (id: number) => deleteOne({ endpoint: "jours", id: id }),
      deleteMany: (date: string[]) =>
        deleteMany({ endpoint: "jours", body: { date } }),
    },
    users: {
      all: () => getAll<User>({ endpoint: "auth/users" }),
      one: (id: number) => getSingle<User>({ endpoint: "auth/users", id: id }),
      update: (id: number, user: UserUpdate) =>
        update({ body: user, endpoint: "auth/users", id: id }),
      create_standard_user: (user: UserCreate) =>
        create({ body: user, endpoint: "auth/register" }),
      create_super_user: (user: SuperUserCreate) =>
        create({ body: user, endpoint: "auth/superuser" }),
      delete: (id: number) => deleteOne({ id: id, endpoint: "auth/users" }),
      changePassword: (
        id: number,
        password: string,
        password_confirm: string
      ) => changePassword({ id, password, password_confirm }),
    },
    /** Sets new JWT access token */
    setToken,
    downloadJourReport: (id: number, filename: string) =>
      downloadJourReportAsPDF(id, filename),
    costCenters: {
      all: () => getAll<string>({ endpoint: "cost-centers" }),
    },
    getCSVFromCompany: (
      company: Company,
      start_date: string,
      end_date: string
    ) => getCSVFromCompany(company, start_date, end_date),
    // sendPasswordToken: (email: string) => sendPasswordToken(email),
  };

  return api;
};
