import { useContext, useEffect, useState, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  styled,
  MenuProps,
  alpha,
  Box,
} from "@mui/material";
import { MobileStateContext } from "../contexts/MobileContext";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { months } from "../utils/time";
import StyledIconButton from "../components/shared/StyledIconButton";
import JourTableDesktop from "../components/jour/JourTableDesktop";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import JourTableMobile from "../components/jour/JourTableMobile";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";
import { useAPI } from "../API/useAPI";
import { CSVLink } from "react-csv";
import {
  addDays,
  format,
  getDay,
  getMonth,
  getWeek,
  getYear,
  startOfYear,
  subDays,
} from "date-fns";
import { JourReports, JourReportWorker, SortColumn } from "../utils/types";
import { weeksOfMonth } from "../utils/time";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import startOfWeek from "date-fns/fp/startOfWeek/index";
import axios from "axios";
import { mergeColumnsState } from "@mui/x-data-grid/hooks/features/columns/gridColumnsUtils";
import { DataArray } from "@mui/icons-material";

/** STYLE */
const buttonStyle = {
  border: 1,
  p: 1,
  m: 1,
  borderColor: "#e0e0e0",
  borderRadius: "4px",
};

const prettyLink = {
  color: "#3A3A3C",
  textDecoration: "none",
};
/**
 * Main component for Jour
 * @returns
 */
export default function Jour() {
  const { isMobile, isDesktop } = useContext(MobileStateContext);

  const today = new Date();

  const [activeMonth, setActiveMonth] = useState<number>(today.getMonth());
  const [activeDate, setActiveDate] = useState<Date>(today);
  const [edit, setEdit] = useState<boolean>(false);
  const api = useAPI();

  function nextMonth() {
    const newDate = new Date(
      activeDate.getFullYear(),
      activeDate.getMonth() + 1
    );
    setActiveMonth(newDate.getMonth());
    setActiveDate(newDate);
  }

  function prevMonth() {
    const newDate = new Date(
      activeDate.getFullYear(),
      activeDate.getMonth() - 1
    );
    setActiveMonth(newDate.getMonth());

    setActiveDate(newDate);
  }

  function currentMonth() {
    setActiveMonth(today.getMonth());
    setActiveDate(today);
  }

  const getJourDates = async (): Promise<any[]> => {
    const res = await api.jours.all();
    if (!res.data) return [];
    return res.data.data;
  };

  const getJourReports = async (
    year: number,
    week: number
  ): Promise<JourReports[]> => {
    try {
      // Calculate the start date of the specified week
      const startDateOfYear = startOfYear(new Date(year, 0, 1));
      let startDate = addDays(startDateOfYear, (week - 1) * 7);

      // Adjust the start date to the next Thursday
      const dayOfWeek = startDate.getDay();
      const daysToThursday = dayOfWeek <= 4 ? 4 - dayOfWeek : 11 - dayOfWeek;
      startDate = addDays(startDate, daysToThursday);

      // Print the exact dates from Thursday to Thursday
      const weekDates: string[] = [];
      for (let i = 0; i < 7; i++) {
        weekDates.push(format(addDays(startDate, i), "yyyy-MM-dd"));
      }

      let allReports: JourReports[] = [];

      console.log(weekDates)

      for (const date of weekDates) {
        const res = await api.jourReports.paginated({
          date: date,
          sortBy: "date" as SortColumn,
          sortDir: "asc",
          page: 1,
          pageSize: 100,
        });

        if (res.data && res.data.data.length > 0) {
          allReports = allReports.concat(res.data.data);
        }
      }

      // Sort the combined data by date
      const sortedData = allReports.sort(
        (a, b) =>
          new Date(a.worker.date).getTime() - new Date(b.worker.date).getTime()
      );

      return sortedData;
    } catch (error) {
      console.error("Error fetching filtered jour reports:", error);
      return [];
    }
  };

  const getWeekShift = (date): number => {
    // HACK. shifting of weeks from weeknumber to jour specific week number are implemented differently elsewere. This might decouple week number displayed in gui from the week number displayed in summary
    let shiftWeek =
      getWeek(date, {
        weekStartsOn: 4, // week starts on thursday
      }) - 1;

    // if the week is 52 it return 0, this is not what we want.
    if (shiftWeek === 0) shiftWeek = 52;
    return shiftWeek;
  };

  const scheduleHeaders = [
    { label: "Vecka", key: "week" },
    { label: "Namn", key: "names" },
  ];
  // const scheduleFileName = `Schema-${months[activeMonth]}.csv`;
  const [csvScheduleData, setScheduleData] = useState<any[]>([]);
  const [csvScheduleLoading, setCsvScheduleloading] = useState(false);
  // we introduce useRef to solve the issues refernced here https://github.com/react-csv/react-csv/issues/221
  // and here https://stackoverflow.com/questions/64817556/loading-data-asynchronously-and-downloading-csv-data-with-a-click-is-one-step-be
  // this allows us to essentially have two clicks for every button click. One that fetches the data, and one that activates the csvlink component.
  const scheduleLink = useRef<CSVLink>();
  const [scheduleActive, setScheduleActive] = useState(false);
  const [years, setYears] = useState<number[]>([]);
  const [scheduleFileName, setScheduleFileName] = useState<string>();
  useEffect(() => {
    if (scheduleActive) {
      setScheduleActive(false);
      scheduleLink.current.link.click();
    }
  }, [csvScheduleData]);

  useEffect(() => {
    let holder = weeksOfMonth(activeDate, activeMonth).map((id) => {
      return id.weekNumber;
    });

    setWeeks(holder);
    const currentYear = getYear(activeDate);
    const yearHolder = [currentYear - 1, currentYear, currentYear + 1];
    setYears(yearHolder);
  }, [activeMonth]);

  const computeSchedule = async (chosenYear: number) => {
    setCsvScheduleloading(true);
    const allJourDates = await getJourDates();

    // We are only interested in the dates for the chosen year
    let jourDates: any[] = [];
    allJourDates.forEach((element) => {
      if (getYear(new Date(element.date)) == chosenYear) {
        jourDates.push(element);
      }
    });

    // we retrive the scheduled dates
    const worker = jourDates.map((a) => a.worker);
    const workerDates = jourDates.map((a) => a.date);

    // for every week, build up map of Jour workers
    let csvDataMap = new Map<number, Set<String>>();
    for (var i = 0; i < worker.length; i++) {
      let week: number = getWeekShift(new Date(workerDates[i]));
      if (week == 0) week = 52; // if the week is 52 it returns 0, this is not what we want.

      if (!csvDataMap.has(week)) {
        csvDataMap.set(week, new Set<String>());
      }
      const name: String = `${worker[i].first_name} ${worker[i].last_name}`;
      // TODO this might not work if the add method does not mutate the set inside the map but rather a copy?
      csvDataMap.get(week)?.add(name);
    }

    let csvData = new Array();
    csvDataMap.forEach((names, week) => {
      const names_str: string = Array.from(names).join(", ");
      csvData.push({ week: week, names: names_str });
    });

    csvData.sort((a, b) => (a.week < b.week ? -1 : a.week > b.week ? 1 : 0));
    const lastDay = new Date(
      activeDate.getFullYear(),
      activeDate.getMonth() + 1,
      0
    );

    setScheduleData(csvData);
    setScheduleFileName(`Schema-${chosenYear}.csv`);
    setScheduleActive(true);
    setCsvScheduleloading(false);
  };

  const summaryHeaders = [
    { label: "Vecka", key: "week" },
    { label: "Namn", key: "name" },
    { label: "Dagar", key: "days" },
    { label: "Röda dagar", key: "red_days" },
    { label: "Timmar", key: "hours" },
    { label: "Röda timmar", key: "red_hours" },
    { label: "Antal jobb", key: "jobs" },
  ];

  // we want to map from week name pairs to the days/hours
  type SummaryData = {
    week: number;
    first_name: string;
    last_name: string;
    name: string;
    days: number;
    red_days: number;
    hours: number;
    red_hours: number;
    jobs: number;
  };
  type SummaryKey = string;

  const [csvSummaryData, setCsvSummaryData] = useState<SummaryData[]>([]);
  const [csvSummaryLoading, setCsvSummaryLoading] = useState(false);
  const summaryLink = useRef<CSVLink>();
  const [summaryActive, setSummaryActive] = useState(false);
  const [weeks, setWeeks] = useState<number[]>([]);
  const [summaryFileName, setSummaryFileName] = useState<string>();
  useEffect(() => {
    if (summaryActive) {
      setSummaryActive(false);
      summaryLink.current.link.click();
    }
  }, [csvSummaryData]);

  function returnElement(
    week: number,
    chosenWeek: number,
    chosenYear: number,
    year: number,
    date: Date
  ) {
    // Handles ocassion if the chosen week is 52 and the month is December
    if (
      week == 52 &&
      chosenWeek == week &&
      getMonth(activeDate) == 11 &&
      ((chosenYear == year && getMonth(date) == 11) ||
        (chosenYear + 1 == year && getMonth(date) == 0))
    ) {
      return true;

      // Handles ocassion if the chosen week is 52 and the month is January
    } else if (
      week == 52 &&
      chosenWeek == week &&
      getMonth(activeDate) == 0 &&
      ((chosenYear == year && getMonth(date) == 0) ||
        (chosenYear - 1 == year && getMonth(date) == 11))
    ) {
      return true;

      // Handles all other occasions
    } else if (week !== 52 && chosenWeek == week && chosenYear == year) {
      return true;
    }
    return false;
  }

  const computeSummary = async (chosenWeek: number, chosenYear: number) => {
    setCsvSummaryLoading(true);
  
    const jourDates = await getJourDates();
    const jourReports = await getJourReports(chosenYear, chosenWeek);
  
    let csvSummaryMap = new Map<SummaryKey, SummaryData>();
  
    // Process jourDates
    const jourHolder: any[] = jourDates.filter((element) => {
      const date = new Date(element.date);
      const year = getYear(date);
      let week = getWeekShift(date);
      if (week === 0) week = 52;
  
      return returnElement(week, chosenWeek, chosenYear, year, date);
    });
  
    jourHolder.forEach((element) => {
      const worker = element.worker;
      const first_name = worker.first_name;
      const last_name = worker.last_name;
      const full_name = first_name + " " + last_name;
      const is_holiday: boolean = element.date_is_holiday;
  
      const key: SummaryKey = `${chosenWeek}${first_name}${last_name}`;
      if (!csvSummaryMap.has(key)) {
        const defaultData: SummaryData = {
          week: chosenWeek,
          first_name: first_name,
          last_name: last_name,
          name: full_name,
          days: 0,
          red_days: 0,
          hours: 0,
          red_hours: 0,
          jobs: 0,
        };
        csvSummaryMap.set(key, defaultData);
      }
      const data = csvSummaryMap.get(key)!;
  
      if (is_holiday) {
        data.red_days += 1;
      } else {
        data.days += 1;
      }
  
      csvSummaryMap.set(key, data);
    });
  
    // Process jourReports and merge data
    jourReports.forEach((report) => {
      const worker = report.worker;
      const worker_first_name = worker.worker.first_name || "";
      const worker_last_name = worker.worker.last_name || "";
      const worker_full_name = `${worker_first_name} ${worker_last_name}`;
      const date_is_holiday = worker.date_is_holiday;
      const key: SummaryKey = `${chosenWeek}${worker_first_name}${worker_last_name}`;
      const hours_worked = worker.total_hours;
  
      if (!csvSummaryMap.has(key)) {
        const defaultData: SummaryData = {
          week: chosenWeek,
          first_name: worker_first_name,
          last_name: worker_last_name,
          name: worker_full_name,
          days: 0,
          red_days: 0,
          hours: 0,
          red_hours: 0,
          jobs: 0,
        };
        csvSummaryMap.set(key, defaultData);
      }
  
      const data = csvSummaryMap.get(key)!;
  
      if (date_is_holiday) {
        data.red_hours += hours_worked;
      } else {
        data.hours += hours_worked;
      }
      data.jobs += 1;
  
      csvSummaryMap.set(key, data);
  
      const additional_workers: JourReportWorker[] = report.additional_workers
        ? report.additional_workers
        : [];
      additional_workers.forEach((aw) => {
        const worker_worker = aw!.worker;
        const date = aw.date;
        const worker_week: number = getWeekShift(new Date(date));
        const worker_first_name = aw.worker.first_name;
        const worker_last_name = aw.worker.last_name;
        const worker_full_name = `${worker_first_name} ${worker_last_name}`;
        const date_is_holiday = aw.date_is_holiday;
        const additional_worker_key = `${chosenWeek}${worker_first_name}${worker_last_name}`;
        const hours_worked = aw.total_hours;
  
        const defaultData: SummaryData = {
          week: chosenWeek,
          first_name: worker_first_name ? worker_first_name : "",
          last_name: worker_last_name ? worker_last_name : "",
          name: worker_full_name,
          days: 0,
          red_days: 0,
          hours: 0,
          red_hours: 0,
          jobs: 0,
        };
  
        const additionalWorkerData = csvSummaryMap.get(additional_worker_key) || defaultData;
  
        if (date_is_holiday) {
          additionalWorkerData.red_hours += hours_worked;
        } else {
          additionalWorkerData.hours += hours_worked;
        }
        additionalWorkerData.jobs += 1;
  
        csvSummaryMap.set(additional_worker_key, additionalWorkerData);
      });
    });
  
    console.log("csvSummaryMap after processing jour reports:", csvSummaryMap);
  
    const csvSummaryArray = Array.from(csvSummaryMap.values());
    setCsvSummaryData(csvSummaryArray);
    setSummaryFileName(
      `Sammanställning-${chosenYear}-${months[activeMonth]}-${chosenWeek}.csv`
    );
    setCsvSummaryLoading(false);
    setSummaryActive(true);
  };
  

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElSchedule, setAnchorElSchedule] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorEl);
  const openSchedule = Boolean(anchorElSchedule);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (chosenWeek: number) => {
    setAnchorEl(null);
    if (Number.isFinite(chosenWeek)) {
      // Checks if the inparamater is a number or not
      await computeSummary(chosenWeek, years[1]);
    }
  };

  const handleClickSchedule = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSchedule(event.currentTarget);
  };

  const handleCloseSchedule = async (chosenYear: number) => {
    setAnchorElSchedule(null);
    if (Number.isFinite(chosenYear)) {
      // Checks if the inparamater is a number or not
      await computeSchedule(chosenYear);
    }
  };

  return (
    <Grid
      container
      display="flex"
      sx={isMobile ? { p: "10px" } : { p: "30px 70px" }}
      alignItems="flex-end"
      justifyContent="space-between"
    >
      {/* <Grid sx={isMobile ? { width: "100%" } : { width: "60%" }}> */}
      <Grid item xs={isDesktop ? 2 : 3}>
        <Typography variant="h2">Jour</Typography>
      </Grid>
      {/* </Grid> */}
      {/* CALENDAR NAVIGATION */}
      <Grid item xs={isMobile ? 8 : 4}>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={2}>
            <StyledIconButton
              onClick={() => prevMonth()}
              disabled={false}
              icon={<ArrowLeftIcon />}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton onClick={() => currentMonth()}>
                <HomeIcon />
              </IconButton>
              <Typography
                sx={{ fontWeight: "bold", textAlign: "center" }}
                variant="h3"
              >
                {months[activeMonth]} {activeDate.getFullYear()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <StyledIconButton
              onClick={() => nextMonth()}
              disabled={false}
              icon={<ArrowRightIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* BUTTONS */}
      <Grid item xs={isDesktop ? 6 : 12}>
        <Grid display="flex" justifyContent={isDesktop ? "flex-end" : "center"}>
          <Button
            id="demo-customized-button"
            sx={buttonStyle}
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            startIcon={<GetAppOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {csvSummaryLoading ? "Laddar..." : "Hämta sammanställning"}
          </Button>

          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Box sx={{ width: "100px" }}>
              {weeks.map((element) => {
                return (
                  <MenuItem onClick={async () => await handleClose(element)}>
                    {element}
                  </MenuItem>
                );
              })}
            </Box>
          </Menu>

          <CSVLink
            style={{ display: "hidden" }}
            ref={summaryLink}
            headers={summaryHeaders}
            data={csvSummaryData}
            filename={summaryFileName}
            separator={";"}
          />

          <Button
            id="demo-customized-button-schedule"
            sx={buttonStyle}
            aria-controls={
              openSchedule ? "demo-customized-menu-schedule" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openSchedule ? "true" : undefined}
            disableElevation
            onClick={handleClickSchedule}
            startIcon={<CalendarMonthIcon />}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {csvSummaryLoading ? "Laddar..." : "Hämta schema"}
          </Button>

          <Menu
            id="demo-customized-menu-schedule"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button-schedule",
            }}
            anchorEl={anchorElSchedule}
            open={openSchedule}
            onClose={handleCloseSchedule}
          >
            <Box sx={{ width: "100px" }}>
              {years.map((element) => {
                return (
                  <MenuItem
                    onClick={async () => await handleCloseSchedule(element)}
                  >
                    {element}
                  </MenuItem>
                );
              })}
            </Box>
          </Menu>

          <CSVLink
            style={{ display: "hidden" }}
            ref={scheduleLink}
            headers={scheduleHeaders}
            data={csvScheduleData}
            filename={scheduleFileName}
            separator={";"}
          />

          {isDesktop && (
            <IconButton sx={buttonStyle} onClick={() => setEdit(!edit)}>
              {edit ? <EditOffIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Grid>
      </Grid>
      {/* CALENDAR */}
      <Grid item xs={12} sx={{ m: 2 }}>
        {isDesktop ? (
          <JourTableDesktop
            activeDate={activeDate}
            activeMonth={activeMonth}
            edit={edit}
          />
        ) : (
          <JourTableMobile
            activeDate={activeDate}
            activeMonth={activeMonth}
            edit={edit}
          />
        )}
      </Grid>
    </Grid>
  );
}
