import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { useAPI } from "../../API/useAPI";
import { useContext } from "react";
import { SnackbarContext } from "../../contexts/SnackbarProvider";

interface Props {
  id: number;
  custom_id: string;
}
/**
 * Button to generate a pdf from order information
 *
 *
 * @param {JourReports}
 * @returns
 */
export default function GenerateOrderPdfButton({ id, custom_id }: Props) {
  const snackBar = useContext(SnackbarContext);
  const api = useAPI();
  const handleDownload = async (id: number, name: string) => {
    const res = await api.downloadJourReport(id, name);
    if (!res) {
      snackBar.show("Kunde inte hämta rapport.", "error");
    }
  };

  return (
    <Button
      component={Link}
      to=""
      sx={{
        border: 1,
        borderColor: "info.light",
        borderRadius: "10px",
      }}
      startIcon={<GetAppOutlinedIcon />}
      variant="outlined"
      onClick={() => handleDownload(id, `jour-rapport_${custom_id}`)}
      //   target="_blank"
      //   download
    >
      <Typography>Ladda ner rapport</Typography>
    </Button>
  );
}
