import { useState, useContext } from "react";
import { Grid, Modal, Card, CardHeader, IconButton } from "@mui/material";
import { Company } from "../../utils/types";
import CloseIcon from "@mui/icons-material/Close";
import { MobileStateContext } from "../../contexts/MobileContext";

interface Props {
  open: boolean;
  companyName: string;
  handleClose: () => void;
  children?: JSX.Element | JSX.Element[];
}

/** STYLE */
const closeButtonStyle = {
  position: "absolute",
  top: "20px",
  right: "20px",
};
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   width: "90%",
//   boxShadow: 24,
//   height: "90%",
//   overflowY: "scroll",
//   p: "40px 20px 20px 20px",
//   borderRadius: 0,
//   transform: " translate(-50.1%, -50.1%)",
// };

/**
 * Modal to show component CompanyInfo in mobile version
 * @returns
 */
export default function CompanyInfoModal(props: Props) {
  console.log("companyInfoModal");
  console.log(props.open);
  const { isMobile, isDesktop } = useContext(MobileStateContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    p: 4,
    // maxWidth: "600px",
    width: isMobile ? "100vw" : "70%",
    height: isMobile ? "100vh" : "80vh",
    boxShadow: isMobile ? "0px !important" : 24,
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <IconButton
          aria-label="Example"
          onClick={props.handleClose}
          sx={closeButtonStyle}
        >
          <CloseIcon color="primary" sx={{ fontSize: 40 }} />
        </IconButton>
        {/* <CardHeader
          title={
            <Typography variant="h3" align="center">
              {props.companyName}
            </Typography>
          }
        /> */}

        {props.children}
      </Card>
    </Modal>
  );
}
