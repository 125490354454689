import { useState, useEffect, useContext } from "react";
import {
  Table,
  IconButton,
  TableContainer,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableHead,
} from "@mui/material";
import { useAPI } from "../../API/useAPI";
import content from "../../tempContent/content.json";
import {
  dayOfWeek,
  months,
  addDays,
  weeksOfMonth,
  YYMMDD,
  getWeek,
} from "../../utils/time";
import { JourDate, User } from "../../utils/types";
import { userFullname } from "../../utils/helpFunctions";
import CellInfo from "./CellInfo";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import BookEmployee from "./BookEmploye";
import ConfirmDelete from "../shared/ConfirmDelete";
import { SnackbarContext } from "../../contexts/SnackbarProvider";

interface Props {
  activeDate: Date;
  activeMonth: number;
  edit: boolean;
}
export default function JourTableDesktop(props: Props) {
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const [jourare, setJourare] = useState<User[]>([]);
  const [bookEmploye, setBookEmployee] = useState<boolean>(false);
  const [jourDates, setJourDates] = useState<any[]>([]);
  const [deleteJourDate, setDeleteJourDate] = useState<number>();
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [addDate, setAddDate] = useState<Date>(new Date());
  const [deleteWeek, setDeleteWeek] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteWeekStart, setDeleteWeekStart] = useState<Date>();

  useEffect(() => {
    getUsers();
    getJourDates();
  }, []);

  const getUsers = async () => {
    const res = await api.users.all();
    if (!res.data) return;

    setJourare(res.data.data);
  };

  const getJourDates = async () => {
    const res = await api.jours.all();
    if (!res.data) return;

    setJourDates(res.data.data);
  };

  /**
   * For each row in table
   * @param startDate start of intervall
   * @returns array of 7 dates from startDate
   */
  function getRow(startDate: Date) {
    let iterator = startDate;

    const res: Date[] = [];
    for (let i = 0; i < 7; i++) {
      // console.log(iterator);
      res.push(iterator);
      iterator = addDays(iterator, 1);
    }

    return res;
  }

  /**
   * Check if a jourdate exists on date
   * @param date
   * @returns worker
   */
  function findJourDate(date: Date) {
    const jourDate = jourDates.find(
      (jourDate) => jourDate.date === YYMMDD(date)
    );

    return jourDate ? jourDate : null;
  }

  /**
   * Open dialog to confirm delete
   * @param jourDate - jourdate to delete
   */
  const showDeleteDialog = (jourDate: JourDate, wholeWeek = false) => {
   
    setDeleteWeek(wholeWeek);
    if (jourDate.id) setDeleteJourDate(jourDate.id);
    if (wholeWeek) {
      setDialogTitle(
        `Är du säker på att du vill avboka användare hela veckan? `
      );
    } else {
      setDialogTitle(
        `Är du säker på att du vill avboka ${userFullname(
          jourDate.worker
        )} på datum ${jourDate.date}? `
      );
    }
    setConfirmDialog(true);
  };

  /**
   * Delete jour date from database
   */
  const permDeleteJourDate = async () => {
    console.log("permdelete");
    if (deleteJourDate) {
      const res = await api.jours.deleteOne(deleteJourDate);
      if (!res.data) {
        snackBar.show("Kunde inte avboka. Försök igen.", "error");
      } else {
        snackBar.show("Användaren har avbokats", "success");
      }

      getJourDates();
      setConfirmDialog(false);
    }
  };

  /**
   * Delete jour date and coming 7 jour dates if exists from database
   */
  const deleteWholeWeek = async () => {
    console.log("deleteWholeWeek");
    setLoading(true);

    let weekDays: Date[] = [];
    if (deleteWeekStart) weekDays = getRow(deleteWeekStart);

    let deleteJourDates: string[] = [];
    for (const weekDay of weekDays) {
      console.log(`Weekday ${weekDay}`);
      let jourDate = findJourDate(weekDay);
      console.log(jourDate);
      if (jourDate) deleteJourDates.push(YYMMDD(new Date(jourDate.date)));
    }
    console.log(deleteJourDates);
    if (deleteJourDates.length > 0) {
      const res = await api.jours.deleteMany(deleteJourDates);
      if (!res.data) {
        snackBar.show("Kunde inte avboka. Försök igen.", "error");
      } else {
        snackBar.show("Användaren har avbokats", "success");
      }

      getJourDates();
      setConfirmDialog(false);
    } else {
      snackBar.show(
        `Inga användare är bokade vecka ${getWeek(deleteWeekStart!)}`,
        "error"
      );
      setConfirmDialog(false);
    }
    setLoading(false);
  };

  function handleDeleteWeek(startDate) {
    setDeleteWeekStart(startDate);
    showDeleteDialog(startDate, true);
  }

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ color: "#3A3A3C80", borderBottom: 0 }}
            >
              Datum
            </TableCell>
            {props.edit && (
              <TableCell align="center" sx={{ borderBottom: 0 }} />
            )}
            {dayOfWeek.map((day, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{ color: "#3A3A3C80", borderBottom: 0 }}
              >
                {day}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {weeksOfMonth(props.activeDate, props.activeMonth).map((week) => (
            <TableRow
              key={week.id}
              sx={{
                height: "100px",
                // "&:last-child td, &:last-child th": {
                //   borderCollapse: "collapse",
                // },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight: "bold !important",
                  borderBottom: 0,
                  // borderRight: "1px solid rgba(58, 58, 60, 0.15)",
                }}
                align="center"
              >
                Vecka {getWeek(week.start)} {"\n"}
                {/* {week.start.getDate()} {months[week.start.getMonth()]} -{" "}
                {week.end.getDate()} {months[week.end.getMonth()]} */}
              </TableCell>
              {props.edit && (
                <TableCell sx={{ borderBottom: 0 }}>
                  <IconButton onClick={() => handleDeleteWeek(week.start)}>
                    <PersonRemoveIcon />
                  </IconButton>
                </TableCell>
              )}
              {getRow(week.start).map((date, index) => (
                <CellInfo
                  key={index}
                  showDeleteDialog={showDeleteDialog}
                  edit={props.edit}
                  jourDate={findJourDate(date)}
                  openModal={() => {
                    setAddDate(date);
                    setBookEmployee(true);
                  }}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <BookEmployee
        open={bookEmploye}
        handleClose={() => setBookEmployee(false)}
        users={jourare}
        getJourDates={() => getJourDates()}
        date={addDate}
      />
      {confirmDialog && (
        <ConfirmDelete
          onConfirm={
            deleteWeek ? () => deleteWholeWeek() : () => permDeleteJourDate()
          }
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            getUsers();
          }}
          title={dialogTitle}
        />
      )}
    </TableContainer>
  );
}
