import { CircularProgress } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth/hooks/useAuth";

/**
 * ProtectedRoute can be used instead of the Route within a BrowserRouter to make sure the user trying to reach a path is authenticated
 * @returns
 */
export const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  console.log(auth.loading);
  if (auth.loading) {
    return <CircularProgress />;
  } else {
    return auth.user ? (
      children
    ) : (
      <Navigate to="/login" state={{ from: location.pathname }} />
    );
  }
};
