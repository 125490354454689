import { useContext, useEffect, useState } from "react";
import { Typography, Grid, CircularProgress } from "@mui/material";
import { MobileStateContext } from "../../../contexts/MobileContext";
import GenericSelect from "../../shared/GenericSelect";
import SearchBar from "../../shared/SearchBar";
import { HeadCell, PaginationInfo, JourReports } from "../../../utils/types";
import DateInput from "../../shared/DateInput";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import StyledIconButton from "../../shared/StyledIconButton";

import {
  datesAreOnSameDay,
  removeDuplicates,
  userFullname,
} from "../../../utils/helpFunctions";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { SnackbarContext } from "../../../contexts/SnackbarProvider";
import OrderCard from "../OrderCard";

interface InputProps {
  reportCount: number;
  page: number;
  setPage: (p: number) => void;
  pageSize: number;
  setPageSize: (s: number) => void;
  orders: JourReports[];
  signalFetchJourReports: () => void;
  loading: boolean;
  filterDate: Date | null;
  setFilterDate: (d: Date | null) => void;
  filterCompany: string | null;
  setFilterCompany: (c: string | null) => void;
  filterText: string | null;
  setFilterText: (t: string | null) => void;
  companyNames: string[];
}

export default function OrderTableMobile(props: InputProps) {
  const { isMobile, isDesktop } = useContext(MobileStateContext);

  const start = props.page * props.pageSize + 1;
  const endb = (props.page + 1) * props.pageSize;
  const end = endb < props.reportCount ? endb : props.reportCount;

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Grid>
        <Grid
          item
          xs={isDesktop ? 6 : 12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={isMobile ? { width: "100%" } : { width: "80%" }}
        >
          <Grid item xs={6}>
            <GenericSelect
              selectData={props.filterCompany}
              setSelectData={props.setFilterCompany}
              label="Välj bolag"
              data={props.companyNames}
            />
          </Grid>
          {/* Empty space */}
          <Grid sx={{ p: 1 }} />
          <Grid item xs={6}>
            <DateInput
              newDate={props.filterDate}
              setNewDate={props.setFilterDate}
              showClearField={true}
            />
          </Grid>
        </Grid>
        {/* Empty space */}
        {!isDesktop && <Grid sx={{ p: 1 }} item xs={12} />}
        <Grid
          item
          xs={isDesktop ? 8 : 12}
          sx={isMobile ? { width: "100%" } : { width: "80%", m: "0 15px 0 0" }}
        >
          <SearchBar
            searchValue={props.filterText}
            setSearchValue={props.setFilterText}
            placeholder="Sök uppdrag"
          />
        </Grid>
      </Grid>
      {props.loading ? (
        <Box width={"100%"} display="flex" justifyContent={"center"} py={10}>
          <CircularProgress />
        </Box>
      ) : props.orders.length > 0 ? (
        props.orders.map((order, index) => (
          <Box component="span" sx={{ p: 2 }} key={index}>
            <OrderCard report={order} />
          </Box>
        ))
      ) : (
        <Typography sx={{ m: "20px" }}>Inga uppdrag hittades</Typography>
      )}
      <Grid
        sx={{
          position: "fixed",
          width: "100vw",
          bottom: 0,
          left: 0,
          backgroundColor: "#EFEFEF",
        }}
      >
        {/* PAGINATION */}
        {props.orders.length > 0 && (
          // <Grid item xs={isDesktop ? 3 : 12}>
          <Grid container display="flex" alignItems="center">
            <Grid item xs={isDesktop ? 8 : 6}>
              {/* <Grid
                container
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
              > */}
              <Typography sx={{ color: "gray", textAlign: "center" }}>
                Visar {start} - {end} av {props.reportCount} uppdrag
              </Typography>
              {/* </Grid> */}
            </Grid>
            {/* BUTTONS */}
            <Grid item xs={isDesktop ? 4 : 6}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "gray",
                  }}
                >
                  Du är på sida <b>{props.page + 1}</b>
                </Typography>
                <Grid>
                  <StyledIconButton
                    icon={<ArrowLeftIcon />}
                    disabled={props.page === 0}
                    onClick={() => props.setPage(props.page - 1)}
                  />

                  <StyledIconButton
                    icon={<ArrowRightIcon />}
                    disabled={
                      props.page ===
                      Math.floor(props.reportCount / props.pageSize)
                    }
                    onClick={() => props.setPage(props.page + 1)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          // </Grid>
        )}
      </Grid>
      <Grid sx={{ m: "30px" }} />
    </Grid>
  );
}
