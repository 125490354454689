import { useState } from "react";
import svLocaleTime from "date-fns/locale/sv";
import { DatePicker } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IconButton, TextField, Grid, Tooltip } from "@mui/material";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import DateRangeIcon from "@mui/icons-material/DateRange";

interface Props {
  newDate: Date | null;
  setNewDate: (d: Date | null) => void;
  showClearField: boolean;
}

export default function DateInput({
  newDate,
  setNewDate,
  showClearField,
}: Props) {
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false);

  function handleChange(date: Date | null) {
    setTimePickerOpen(false);
    setNewDate(date);
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={svLocaleTime}
    >
      <Grid display="flex">
        <DatePicker
          open={timePickerOpen}
          componentsProps={
            showClearField
              ? {
                  actionBar: {
                    actions: ["clear"],
                  },
                }
              : undefined
          }
          className="date-picker"
          onOpen={() => setTimePickerOpen(true)}
          onClose={() => setTimePickerOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={() => setTimePickerOpen(true)}
              // InputProps={{
              //   endAdornment: showClearField && (
              //   <IconButton onClick={() => test()}>
              //   <AutoDeleteIcon />
              // </IconButton>
              //   ),
              // }}
            />
          )}
          label="Välj datum"
          value={newDate || null}
          onChange={(event) => handleChange(event!)}
          disableOpenPicker={true}
        />

        {showClearField && (
          <Tooltip title="Rensa filter (datum)">
            <IconButton onClick={() => handleChange(null)}>
              <AutoDeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </LocalizationProvider>
  );
}
