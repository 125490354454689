import { getWeek as getWeek2 } from "date-fns";
export const months = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const dayOfWeek = [
  "Torsdag",
  "Fredag",
  "Lördag",
  "Söndag",
  "Måndag",
  "Tisdag",
  "Onsdag",
];

export function fiveYears(today: Date) {
  const thisYear = today.getFullYear();
  const res: number[] = [];
  for (let i = 0; i < 5; i++) {
    res.push(thisYear - i);
  }
  return res;
}

export function subtractDays(date: Date, numOfDays: number) {
  var newDate: Date = new Date(date);

  newDate.setDate(date.getDate() - numOfDays);

  return newDate;
}

export function addDays(date: Date, numOfDays: number): Date {
  var newDate: Date = new Date(date);

  newDate.setDate(date.getDate() + numOfDays);

  return newDate;
}

export function isEven(num: number) {
  return num % 2 === 0;
}

interface WeekInfo {
  start: Date;
  end: Date;
  id: number;
  weekNumber: number;
}

export function YYMMDD(arg: Date) {
  const year = arg.getFullYear().toString();
  let month = (arg.getMonth() + 1).toString();
  let date = arg.getDate().toString();

  if (month.length === 1) {
    month = "0" + month;
  }
  if (date.length === 1) {
    date = "0" + date;
  }

  return year + "-" + month + "-" + date;
}

/**
 * Return ISO 8601 week of active date
 */
export function getWeek(date: Date) {
  // Create a copy of the date to avoid modifying the original
  let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  // Set the day to Thursday in the current week (ISO 8601 considers week starting from Monday and week 1 contains first Thursday)
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

  // Calculate the first day of the year
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  // Calculate the number of full weeks since the start of the year
  let weekNo = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

  return weekNo;
}

/**
 * Return week of active date (Old version)
 */
/* export function getWeek(date: Date) {
  let startDate = new Date(date.getFullYear(), 0, 1);
  var days = Math.floor(
    (Number(date) - Number(startDate)) / (24 * 60 * 60 * 1000)
  );

  var weekNumber = Math.ceil(days / 7);

  // Return the calculated result
  return weekNumber;
} */


// Return array with 7 days from startDate and
// forward in YY-MM-DD format
export function daysOfWeek(startDate: Date) {
  const week: string[] = [];
  let date = startDate;

  for (let i = 0; i < 7; i++) {
    week.push(YYMMDD(date));
    date = addDays(date, 1);
  }
  return week;
}

export function weeksOfMonth(date: Date, activeMonth: number): Array<WeekInfo> {
  const weeks: WeekInfo[] = [];
  let firstDayOfMonth = new Date(date.getFullYear(), activeMonth, 1);
  
  // Start from the last Thursday before or on the first day of the month
  let firstDay = firstDayOfMonth;
  while (firstDay.getDay() !== 4) {
    firstDay = subtractDays(firstDay, 1);
  }

  // Loop through weeks until the end of the month
  let lastDay: Date = addDays(firstDay, 6);
  let index = 1;

  while (
    firstDay.getMonth() === activeMonth || lastDay.getMonth() === activeMonth
  ) {
    var weekNumber = getWeek2(firstDay, { weekStartsOn: 4 });
    weekNumber = weekNumber - 1;
    console.log(weekNumber);
    if (weekNumber === 0) {
      weekNumber = 52;
    }
    
    const info: WeekInfo = {
      start: firstDay,
      end: lastDay,
      id: index,
      weekNumber: weekNumber,
    };

    weeks.push(info);

    // Move to the next week
    firstDay = addDays(lastDay, 1);
    lastDay = addDays(firstDay, 6);
    index++;
  }

  return weeks;
}

