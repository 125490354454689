import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Grid,
  CircularProgress,
  SortDirection,
} from "@mui/material";
import { MobileStateContext } from "../../../contexts/MobileContext";
import GenericSelect from "../../shared/GenericSelect";
import { PaginationInfo, JourReports } from "../../../utils/types";
import DateInput from "../../shared/DateInput";
import { useAPI } from "../../../API/useAPI";
import { useAuth } from "../../../auth/hooks/useAuth";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridSortDirection,
} from "@mui/x-data-grid";
import {
  datesAreOnSameDay,
  removeDuplicates,
  userFullname,
} from "../../../utils/helpFunctions";
import { Box } from "@mui/system";
import BulkDeleteButton from "../BulkDeleteButton";
import OrderTablePaginated from "./OrderTablePaginated";
import OrderTableMobilePaginated from "./OrderTableMobilePaginated";
import BulkToggleArchivedButton from "../BulkToggleArchivedButton";

interface Props {
  // we make all the filteroptions props to propagate them uppwards to the parent component
  // I chose to put all the filtering options there, since the filtering, sorting and restriction based
  // on user type happens in the backend. see JourReportListViewPaginated in views.py
  filterDate: Date | null;
  setFilterDate: (d: Date | null) => void;
  filterCompany: string | null;
  setFilterCompany: (c: string | null) => void;
  reportCount: number;
  page: number;
  setPage: (p: number) => void;
  pageSize: number;
  setPageSize: (s: number) => void;
  setColumnToSort: (s: string) => void;
  setSortDirection: (d: "asc" | "desc") => void;
  reports: JourReports[];
  companyNames: string[];
  signalFetchJourReports: () => void;
  filterText: string | null;
  setFilterText: (s: string | null) => void;
  loading: boolean;
  ordersTitle: string;
  fetchedArchive: boolean;
}

/**
 * Component for Orders
 * @returns
 */
export default function Orders({
  filterDate,
  companyNames,
  setFilterDate,
  filterCompany,
  setFilterCompany,
  filterText,
  setFilterText,
  reportCount,
  page,
  setPage,
  pageSize,
  setPageSize,
  reports,
  signalFetchJourReports,
  loading,
  ordersTitle,
  setColumnToSort,
  setSortDirection,
  fetchedArchive,
}: Props) {
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);
  const { isAdmin } = useAuth();

  // the report ids that have been selected for deletion
  // we keep this state here instead of in the parent component since it is not relevant
  // for the fetch
  const [selectedReports, setSelectedReports] = useState<number[]>([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={isDesktop ? 5 : 12}>
            <GridToolbarQuickFilter
              sx={{
                width: "100%",
                div: {
                  width: "100%",
                  backgroundColor: "info.light",
                  borderRadius: "5px",
                  padding: "10px",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={isDesktop ? 6 : 12}
            gap={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "80%" }}
          >
            <Grid item xs={5}>
              <GenericSelect
                selectData={filterCompany}
                setSelectData={setFilterCompany}
                label="Välj bolag"
                data={companyNames}
              />
            </Grid>
            <Grid item xs={5}>
              <DateInput
                newDate={filterDate}
                setNewDate={setFilterDate}
                showClearField={true}
              />
            </Grid>
            <Grid item xs={1}>
              <BulkDeleteButton
                ids={selectedReports}
                signalFetchJourReports={signalFetchJourReports}
              />
            </Grid>
            {isAdmin && (
              <Grid item xs={1}>
                <BulkToggleArchivedButton
                  ids={selectedReports}
                  signalFetchJourReports={signalFetchJourReports}
                  fetchedArchive={fetchedArchive}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <Grid sx={isMobile ? { p: "10px" } : { p: "30px 70px" }}>
      <Typography variant="h2">{ordersTitle}</Typography>
      <Grid container>
        {/* MOBILE TABLE */}
        {isDesktop ? (
          <Grid item xs={12}>
            <OrderTablePaginated
              setColumnToSort={setColumnToSort}
              setSortDirection={setSortDirection}
              reportCount={reportCount}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              orders={reports}
              customToolbar={CustomToolbar}
              signalFetchJourReports={signalFetchJourReports}
              onSelectionChange={(ids) => setSelectedReports(ids)}
              setFilterText={setFilterText}
              fetchedArchived={fetchedArchive}
              loading={loading}
            />
          </Grid>
        ) : (
          <OrderTableMobilePaginated
            reportCount={reportCount}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            orders={reports}
            signalFetchJourReports={signalFetchJourReports}
            loading={loading}
            filterCompany={filterCompany}
            setFilterCompany={setFilterCompany}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            companyNames={companyNames}
            filterText={filterText}
            setFilterText={setFilterText}
          />
        )}
      </Grid>
    </Grid>
  );
}
