import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { months, fiveYears, YYMMDD } from "../../utils/time";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useAPI } from "../../API/useAPI";
import { Companies, Company } from "../../utils/types";

const buttonStyle = {
  color: "black",
  border: 1,
  m: 1,
  p: 1,
  borderRadius: "10px",
  borderColor: "#e0e0e0",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

/**
 * Button to get report for different months in company view
 * @returns
 */
interface Props {
  company: Company;
}
export default function GetReportButton({ company }: Props) {
  const snackbar = useContext(SnackbarContext);
  const today = new Date();

  const api = useAPI();

  const [open, setOpen] = useState(false);
  const [monthIndex, setMonthIndex] = useState(today.getMonth());
  const [yearIndex, setYearIndex] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMonthChange = (event) => {
    setMonthIndex(event.target.value);
  };
  const handleYearChange = (event) => {
    setYearIndex(event.target.value);
  };

  console.log(yearIndex, monthIndex);

  function getReport() {
    const firstDayOfMonth = YYMMDD(
      new Date(fiveYears(today)[yearIndex], monthIndex, 2)
    );
    const lastDayOfMonth = YYMMDD(
      new Date(fiveYears(today)[yearIndex], monthIndex + 1, 1)
    );

    api.getCSVFromCompany(company, firstDayOfMonth, lastDayOfMonth);
    snackbar.show(
      `Hämtar rapport för ${months[monthIndex]},  ${
        fiveYears(today)[yearIndex]
      }`,
      "success"
    );
  }

  return (
    <div>
      <Button
        sx={[buttonStyle, { flexShrink: 4 }]}
        onClick={handleClickOpen}
        startIcon={<GetAppOutlinedIcon />}
      >
        <Typography>Hämta rapport</Typography>
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Välj månad och år</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="select">Månad</InputLabel>
              <Select
                id="select"
                value={monthIndex}
                onChange={handleMonthChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {months.map((month, index) => (
                  <MenuItem value={index} key={index}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="select">År</InputLabel>
              <Select
                id="select"
                value={yearIndex}
                onChange={handleYearChange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
              >
                {fiveYears(today).map((year, index) => (
                  <MenuItem value={index} key={index}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Typography color="error">Avbryt</Typography>
          </Button>
          <Button onClick={() => getReport()}>Hämta</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
