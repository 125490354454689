import UsersTable from "../components/admin/UsersTable";
import { Grid, Card, Typography } from "@mui/material";
import content from "../tempContent/content.json";

/**
 * Page for managing users and professions
 *
 * @returns AdminPage component
 */
export default function AdminPage() {
  const jourare = content.jourare;
  return (
    <Grid display="flex" sx={{ p: "30px 70px" }} flexDirection="column">
      <Typography variant="h2">Admininställningar</Typography>
      {jourare && (
        <Card sx={{ width: "70%" }}>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12}>
              <UsersTable />
            </Grid>
          </Grid>
        </Card>
      )}
    </Grid>
  );
}
