import React, { useEffect, useState } from "react";
import { Select, MenuItem, Grid } from "@mui/material";

interface Props {
  time: Date | null;
  setTime: (time: Date | null) => void;
}

export default function TimeInput({ time, setTime }: Props) {
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);

  const hours = Array.from({ length: 24 }, (_, i) => i); // 00-23
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  useEffect(() => {
    if (time) {
      setSelectedHour(time.getHours());
      setSelectedMinute(time.getMinutes());
    } else {
      setSelectedHour(0);
      setSelectedMinute(0);
    }
  }, [time]);

  const handleHourChange = (e) => {
    const hourValue = parseInt(e.target.value);
    setSelectedHour(hourValue);
    updateTime(hourValue, selectedMinute);
  };

  const handleMinuteChange = (e) => {
    const minuteValue = parseInt(e.target.value);
    setSelectedMinute(minuteValue);
    updateTime(selectedHour, minuteValue);
  };

  const updateTime = (hours, minutes) => {
    const newTime = new Date();
    newTime.setHours(hours);
    newTime.setMinutes(minutes);
    setTime(newTime);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          fullWidth
          value={selectedHour}
          onChange={handleHourChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            Välj timme
          </MenuItem>
          {hours.map((hour) => (
            <MenuItem key={hour} value={hour}>
              {hour < 10 ? `0${hour}` : hour}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Select
          fullWidth
          value={selectedMinute}
          onChange={handleMinuteChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            Välj minut
          </MenuItem>
          {minutes.map((minute) => (
            <MenuItem key={minute} value={minute}>
              {minute < 10 ? `0${minute}` : minute}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
