import { useLocation } from "react-router-dom";
import { useAuth } from "../../auth/hooks/useAuth";
import Menu from "./Menu";
import JourareMenu from "./JourareMenu";

// Hide menu on login page
export default function Navigation() {
  const auth = useAuth();
  const location = useLocation();
  if (location.pathname === "/login" || location.pathname === "/") return null;

  if (auth.user && auth.isAdmin()) return <Menu />;
  else return <JourareMenu />;
}
