import OrdersGeneric from "../components/orders/paginated/OrdersGeneric";

/**
 * Component for Orders
 * @returns
 */
export default function Orders() {
  return (
    <OrdersGeneric
      ordersTitle="Mina Uppdrag"
      fetchArchived={false}
      fetchMyOrders={true}
    />
  );
}
