import { IconButton } from "@mui/material";

interface Props {
  disabled: boolean;
  onClick: () => void;
  icon: JSX.Element;
}

export default function StyledIconButton(props: Props) {
  return (
    <IconButton
      sx={{
        color: "black",
        border: "1px solid rgba(58, 58, 60, 0.1)",
        borderRadius: "5px",
        m: 1,
      }}
      size="small"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon}
    </IconButton>
  );
}
