import React, { useEffect, useContext, useState } from "react";
import {
  Typography,
  Divider,
  Grid,
  Button,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Company, CompanyUpdate, CompanyCreate } from "../../utils/types";
import { useAPI } from "../../API/useAPI";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { isValidEmail } from "../../utils/helpFunctions";

interface CompanyFormProps {
  company?: Company;
  handleClose: () => void;
  getCompanies: () => void;
}

const emptyCompany: CompanyUpdate = {
  name: "",
  billing_address: "",
  billing_city: "",
  billing_postal: NaN,
  contact_name: "",
  contact_email: "",
  contact_phone: "",
};

interface inputProps {
  id: string;
  label: string;
  size: number;

  placeholder?: string;
  required?: boolean;
  rows?: number;
}
/**
 * Form for adding or editing a company
 * @returns
 */
export default function CompanyForm(props: CompanyFormProps) {
  const api = useAPI();

  const snackBar = useContext(SnackbarContext);
  const [companyData, setCompanyData] = useState<CompanyUpdate>(emptyCompany);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    if (props.company) {
      setCompanyData(props.company);
    }
  }, []);
  console.log(companyData);

  /**
   * Component textfield in form
   */
  function TextInput({
    id,
    label,
    size,
    placeholder,
    required,
    rows,
  }: inputProps) {
    return (
      <Grid item xs={size}>
        <InputLabel htmlFor={id} sx={{ m: "10px 0" }}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={id}
          value={companyData[id] || ""}
          onChange={(event) =>
            setCompanyData((prev) => {
              return {
                ...prev,
                [id]: event.target.value,
              };
            })
          }
          placeholder={placeholder}
          required={required}
          fullWidth
          multiline={rows ? true : false}
          rows={rows}
          notched={false}
        />
      </Grid>
    );
  }

  /**
   * Sends form: add a company to database or edit existing
   */
  const sendForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.company) {
      console.log(companyData);
      const res = await api.companies.update(props.company.id, companyData);
      if (!res.data) {
        console.log(res.error);
        snackBar.show("Kunde inte uppdatera bolaget. Försök igen.", "error");
      } else {
        snackBar.show("Bolag uppdaterad!", "success");
        props.getCompanies();
        props.handleClose();
      }
    } else {
      const res = await api.companies.create(companyData);
      if (!res.data) {
        snackBar.show("Kunde inte skapa bolaget. Försök igen.", "error");
      } else {
        snackBar.show("Bolaget har skapats!", "success");
        props.getCompanies();
        props.handleClose();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (companyData.contact_email) {
      if (isValidEmail(companyData.contact_email)) {
        setEmailError("");
      } else {
        setEmailError("Skriv in en giltig email.");
      }
    }
  }, [companyData.contact_email]);

  return (
    <form onSubmit={sendForm}>
      <Grid
        sx={{
          m: 1,
          borderRadius: 1,
        }}
        container
        display="flex"
        justifyContent="center"
      >
        {TextInput({
          id: "name",
          label: "Namn*",
          size: 10,
          required: true,
        })}
        {TextInput({
          id: "billing_address",
          label: "Faktureringsadress",
          size: 10,
          required: true,
        })}
        {TextInput({
          id: "billing_city",
          label: "Stad",
          size: 5,
          required: true,
        })}
        <Grid item xs={1} />
        {TextInput({
          id: "billing_postal",
          label: "Postnummer",
          size: 4,
          required: true,
        })}

        <Grid item xs={10}>
          <Grid sx={{ p: 1 }} />
          <Typography variant="h4">Kontaktperson</Typography>
        </Grid>
        {TextInput({
          id: "contact_name",
          label: "För- & efternamn",
          size: 10,
          required: true,
        })}
        <Grid item xs={10}>
          <InputLabel htmlFor="contact_email" sx={{ m: "10px 0" }}>
            Email
          </InputLabel>
          <TextField
            id="contact_email"
            value={companyData["contact_email"] || ""}
            onChange={(event) =>
              setCompanyData((prev) => {
                return {
                  ...prev,
                  contact_email: event.target.value,
                };
              })
            }
            required
            fullWidth
            helperText={emailError}
            error={emailError !== ""}
          />
        </Grid>
        {TextInput({
          id: "contact_phone",
          label: "Telefonnummer",
          size: 10,
        })}
        <Divider sx={{ m: "20px 0" }} />
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            sx={{ p: "10px 30px", m: "30px 10px" }}
          >
            <Typography sx={{ color: "white" }}>
              {loading ? (
                <CircularProgress />
              ) : props.company ? (
                "Spara"
              ) : (
                "Lägg till"
              )}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
