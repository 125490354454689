import { useContext, useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddUserModal from "../jourare/AddUserModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDelete from "../shared/ConfirmDelete";
import { User } from "../../utils/types";
import { useAuth } from "../../auth/hooks/useAuth";
import { useAPI } from "../../API/useAPI";
import { USER_ROLES } from "../../utils/constants";
import { userFullname } from "../../utils/helpFunctions";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { Box } from "@mui/system";
import {
  CalendarMonth,
  Email,
  Person,
  Phone,
  Search,
} from "@mui/icons-material";
import { MobileStateContext } from "../../contexts/MobileContext";

interface InfoProps {
  text: string;
}

interface UserTableProps {
  showAdmin: boolean;
}

const headCells = [
  {
    id: "name",
    label: "Namn",
    icon: <Person sx={{ color: "#3A3A3C80" }} />,
  },
  {
    id: "email",
    label: "Email",
    icon: <Email sx={{ color: "#3A3A3C80" }} />,
  },
  {
    id: "phone",
    label: "Telefon",
    icon: <Phone sx={{ color: "#3A3A3C80" }} />,
  },
  {
    id: "created",
    label: "Skapad",
    icon: <CalendarMonth sx={{ color: "#3A3A3C80" }} />,
  },
];

/**
 * Shows all users, can edit, remove and add new
 *
 * @returns UsersTable component
 */
export default function UsersTable({ showAdmin = false }) {
  const [newJourareModal, setNewJourareModal] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<number>();
  const [jourare, setJourare] = useState<User[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const auth = useAuth();
  const api = useAPI();
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [selectedUserID, setSelectedUserID] = useState<number>();
  const snackBar = useContext(SnackbarContext);
  const [searchText, setSearchText] = useState<string>("");
  const { isMobile } = useContext(MobileStateContext);

  const getUsers = async () => {
    const res = await api.users.all();

    if (res.error) {
      snackBar.show("Kunde inte hämta användare", "error");
    }
    if (!res.data) return;

    console.log(res.data);
    setAllUsers(res.data.data);

    const filtered_jourare: User[] = res.data?.data.filter((user) => {
      if (showAdmin) {
        return user.role === USER_ROLES.ADMIN;
      }
      return user.role === USER_ROLES.WORKER;
    });
    setJourare(filtered_jourare);
  };

  useEffect(() => {
    if (!auth.user) return;
    getUsers();
  }, [auth]);

  const showDeleteDialog = (user: User) => {
    setDeleteUser(user.id);
    setDialogTitle(
      `Är du säker på att du vill radera användare ${userFullname(user)}? `
    );
    setConfirmDialog(true);
  };

  const permdeleteUser = async () => {
    if (deleteUser) {
      const res = await api.users.delete(deleteUser);
      console.log(res);
      if (!res.data) {
        snackBar.show("Kunde inte radera användare.", "error");
      } else {
        snackBar.show("Användare har tagits bort permanent", "success");
      }

      getUsers();
      setConfirmDialog(false);
    }
  };

  const openUserModal = (user?: User) => {
    setNewJourareModal(true);
    if (user) {
      setSelectedUserID(user.id);
    } else {
      setSelectedUserID(-1);
    }
  };
  /**
   * Every cell holding information about user
   */
  function InfoCell({ text }: InfoProps) {
    return (
      <TableCell component="th" scope="row">
        <Typography>{text}</Typography>
      </TableCell>
    );
  }

  const filterArrayByNames = (jourareArray: User[]) => {
    if (searchText.trim() === "") return jourareArray;

    return jourareArray.filter(
      (item) =>
        userFullname(item).toLowerCase().indexOf(searchText.toLowerCase()) !==
        -1
    );
  };
  console.log(jourare);

  return (
    <>
      <Grid
        sx={{ width: "100%" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          placeholder="Filtrera på namn"
          sx={{ m: 1, minWidth: "30ch" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setSearchText(event.target.value)}
        />
        {isMobile && (
          //   <Button
          //     onClick={() => openUserModal()}
          //     color="secondary"
          //     sx={{ height: "50%" }}
          //     icon={<AddIcon />}
          //     variant="contained"
          //   ></Button>
          <IconButton
            color="secondary"
            size="large"
            onClick={() => openUserModal()}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        )}
      </Grid>

      {/* <Grid sx={{ width: "100%", minHeight: "500px" }}> */}
      <Grid sx={{ width: "100%" }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => {
                  if (isMobile) {
                    return null;
                  } else {
                    return (
                      <TableCell key={index}>
                        <Box gap={1} display="flex" flexDirection={"row"}>
                          {headCell.icon}
                          <Typography
                            // variant="h4"
                            // fontWeight="bold"
                            sx={{ color: "#3A3A3C80" }}
                          >
                            {headCell.label}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  }
                })}
                {!isMobile && (
                  <TableCell align="right">
                    {/* <IconButton onClick={() => setEdit(!edit)}>
                      {edit ? <EditOffIcon /> : <EditIcon />}{" "}
                    </IconButton> */}

                    <Button
                      onClick={() => openUserModal()}
                      color="secondary"
                      startIcon={<AddIcon />}
                      variant="contained"
                    >
                      Lägg till
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {filterArrayByNames(jourare).length < 1 ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>Inga användare hittades</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {filterArrayByNames(jourare).map((user, index) => {
                  if (isMobile) {
                    return (
                      <div key={index}>
                        <Box
                          display={"flex"}
                          justifyContent="space-between"
                          padding="0 1rem"
                        >
                          <Box padding="1rem 0">
                            <Typography fontWeight="600">
                              {userFullname(user)}
                            </Typography>
                            <Typography>{user.email}</Typography>
                            <Typography>{user.phone}</Typography>
                            <Typography>
                              {user.created_date.slice(0, 10)}
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection="column"
                            justifyContent={"center"}
                          >
                            <IconButton onClick={() => openUserModal(user)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => showDeleteDialog(user)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Box>
                        </Box>
                        <Divider />
                      </div>
                    );
                  }
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <InfoCell text={userFullname(user)} />
                      <InfoCell text={user.email} />
                      <InfoCell text={user.phone} />
                      <InfoCell text={user.created_date.slice(0, 10)} />
                      <TableCell align="right">
                        {/* {edit && ( */}
                        <Grid container justifyContent="end" spacing={1}>
                          <Grid item xs={5}>
                            <IconButton onClick={() => openUserModal(user)}>
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          {/* Not delete current user */}
                          {/* {!(user.id === currentUser.id) && ( */}
                          <Grid item>
                            <IconButton onClick={() => showDeleteDialog(user)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Grid>
                          {/* )} */}
                        </Grid>
                        {/* )} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {newJourareModal && (
          <AddUserModal
            open={newJourareModal}
            handleClose={() => {
              setNewJourareModal(false);
              getUsers();
            }}
            users={allUsers}
            adminUser={showAdmin}
            user={
              selectedUserID !== -1
                ? jourare.find((u) => u.id === selectedUserID)
                : undefined
            }
          />
        )}
        {confirmDialog && (
          <ConfirmDelete
            onConfirm={() => permdeleteUser()}
            open={confirmDialog}
            handleClose={() => {
              setConfirmDialog(false);
              getUsers();
            }}
            title={dialogTitle}
          />
        )}
      </Grid>
    </>
  );
}
