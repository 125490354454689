import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MobileStateProvider } from "./contexts/MobileContext";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Admin from "./pages/Admin";
import "./App.css";
import Orders from "./pages/Orders";
import Companies from "./pages/Companies";
import Settings from "./pages/Settings";
import Menu from "./components/shared/Menu";
import JourReport from "./pages/JourReport";
import Jour from "./pages/Jour";
import Login from "./pages/Login";
import { AuthProvider } from "./auth/hooks/useAuth";
import { SnackbarProvider } from "./contexts/SnackbarProvider";
import { AdminRoute } from "./components/AdminRoute";
import { ProtectedRoute } from "./components/ProtectedRoute";
import ResetPassword from "./pages/ResetPassword";
import Navigation from "./components/shared/Navigation";
import { svSE } from "@mui/x-data-grid";
import NotFound from "./components/NotFound";
import { ConfirmProvider } from "material-ui-confirm";
import MyOrders from "./pages/MyOrders";
import ArchivedOrders from "./pages/OrdersArchived";

// Creates the theme for the application
let theme = createTheme(
  {
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: "Source Sans Pro !important",
          color: "#3A3A3C",
          fontWeight: 500,
        },
      },
    },
    typography: {
      h1: {
        fontSize: "24px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "40px",
        fontWeight: "bold !important",
        margin: "20px 0",
      },
      h3: {
        fontSize: "30px",
        fontWeight: "bold !important",
      },
      // Navigation
      h4: {
        fontSize: "20px",
        fontWeight: "bold !important",
      },
    },
    palette: {
      primary: {
        main: "#3A3A3C",
      },
      secondary: {
        main: "#44A247",
      },
      info: {
        // Gray color
        dark: "#3A3A3C80",
        main: "#3A3A3C50",
        light: "#3A3A3C10",
      },
    },
  },
  svSE
);

// Hide menu on login page
// const Navigation = () => {
//   const location = useLocation();
//   if (location.pathname === "/login" || location.pathname === "/") return null;
//   if(auth.user.isAdmin())return <Menu/>
//   else return <JourareMenu></JourareMenu>
// };

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthProvider>
          <ConfirmProvider>
            <MobileStateProvider>
              <BrowserRouter>
                <Navigation />
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route
                    path="/reset-password/:token"
                    element={<ResetPassword />}
                  />
                  <Route
                    path="/admin"
                    element={
                      <AdminRoute>
                        <Admin />
                      </AdminRoute>
                    }
                  />

                  <Route
                    path="/orders"
                    element={
                      <ProtectedRoute>
                        <Orders />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/my-orders"
                    element={
                      <ProtectedRoute>
                        <MyOrders />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/archive"
                    element={
                      <AdminRoute>
                        <ArchivedOrders />
                      </AdminRoute>
                    }
                  />

                  <Route
                    path="/companies"
                    element={
                      <AdminRoute>
                        <Companies />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <AdminRoute>
                        <Settings />
                      </AdminRoute>
                    }
                  />

                  <Route
                    path="/jour"
                    element={
                      <AdminRoute>
                        <Jour />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/report"
                    element={
                      <ProtectedRoute>
                        <JourReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </MobileStateProvider>
          </ConfirmProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
