import { useContext, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  InputLabel,
} from "@mui/material";
import { MobileStateContext } from "../contexts/MobileContext";
import LogoutIcon from "@mui/icons-material/Logout";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import UsersTable from "../components/admin/UsersTable";
import { useAuth } from "../auth/hooks/useAuth";
import { SnackbarContext } from "../contexts/SnackbarProvider";
import { useAPI } from "../API/useAPI";
import { isNullOrEmpty, isValidEmail } from "../utils/helpFunctions";

interface PasswordChange {
  password: string;
  password_confirm: string;
}

interface EmailChange {
  email: string;
  email_confirm: string;
}

const PASSWORD_STATES = {
  SHORT: "short",
  NOT_MATCHING: "not-matching",
  OK: "ok",
};

// interface stateProps {
//   email?: string;
//   password?: string;
// }

const boxStyle = {
  // width: "100%",
  border: 1,
  borderColor: "#e0e0e0",
  m: "10px 0",
  p: 2,
  boxSizing: "border-box",
  borderRadius: 2,
};

/**
 * Component for settings
 * @returns
 */
export default function Settings() {
  const { isMobile } = useContext(MobileStateContext);
  const auth = useAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [passwords, setPasswords] = useState<PasswordChange>({
    password: "",
    password_confirm: "",
  });
  const [emails, setEmails] = useState<EmailChange>({
    email: "",
    email_confirm: "",
  });
  const api = useAPI();
  const [pswError, setPswError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const snackBar = useContext(SnackbarContext);

  // const [state, setState] = useState<stateProps>({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const updateEmailAddress = async () => {
    if (!auth.user) return;

    if (validateEmail(emails) === PASSWORD_STATES.OK) {
      const res = await api.users.update(auth.user.id, {
        email: emails.email,
      });
      if (res.error) {
        snackBar.show(
          "Kunde inte byta emailadress. Kontrollera giltigheten.",
          "error"
        );
      } else {
        snackBar.show("Emailadress uppdaterad!", "success");
      }
    } else {
      snackBar.show(
        "Kunde inte byta emailadress. Kontrollera giltigheten.",
        "error"
      );
    }
    setEmails({ email: "", email_confirm: "" });
  };

  const validatePassword = (pws: PasswordChange) => {
    console.log(PASSWORD_STATES.SHORT);
    if (passwords.password !== passwords.password_confirm) {
      // setPswError("Lösenorden matchar inte");
      return PASSWORD_STATES.NOT_MATCHING;
      // } else if (passwords.password.trim() === "") {
    } else if (passwords.password.length < 8) {
      // setPswError("Lösenordet måste innehålla minst 8 tecken.");
      return PASSWORD_STATES.SHORT;
    }

    return PASSWORD_STATES.OK;
  };

  const validateEmail = (email: EmailChange) => {
    if (email.email !== email.email_confirm) {
      return PASSWORD_STATES.NOT_MATCHING;
    }

    return PASSWORD_STATES.OK;
  };

  const updatePassword = async () => {
    if (!auth.user) return;

    if (validatePassword(passwords) === PASSWORD_STATES.NOT_MATCHING) {
      snackBar.show("Lösenorden matchar inte", "error");
      return;
    } else if (validatePassword(passwords) === PASSWORD_STATES.SHORT) {
      snackBar.show("För kort lösenord", "error");
      return;
    }

    const res = await api.users.changePassword(
      auth.user.id,
      passwords.password,
      passwords.password_confirm
    );

    if (res.error) {
      snackBar.show("Det gick inte att byta lösenord", "error");
      return;
    }
    setPasswords({ password: "", password_confirm: "" });
    snackBar.show("Lösenordet har uppdaterats!", "success");
  };

  /**
   * Show error message in textfield if the password is less
   * than 6 characters or passwords not matching
   * @param event - from textfield
   */
  function handleEmailChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    console.log(event.target.id);
    setEmails({ ...emails, [event.target.id]: event.target.value });

    let compare =
      event.target.id === "email" ? emails.email_confirm : emails.email;

    if (!isNullOrEmpty(event.target.value)) {
      if (!isValidEmail(event.target.value))
        setEmailError("Skriv in en giltig emailadress.");
      else if (compare !== event.target.value)
        setEmailError("Emailadresserna matchar inte.");
      else setEmailError("");
    } else {
      setEmailError("");
    }
  }

  /**
   * Show error message in textfield if the password is less
   * than 6 characters or passwords not matching
   * @param event - from textfield
   */
  function handlePasswordChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    console.log(event.target.id);
    setPasswords({ ...passwords, [event.target.id]: event.target.value });

    let pswCompare =
      event.target.id === "password"
        ? passwords.password_confirm
        : passwords.password;

    if (!isNullOrEmpty(event.target.value)) {
      if (event.target.value.length < 6)
        setPswError("Lösenordet måste innehålla minst 6 tecken.");
      else if (pswCompare !== event.target.value)
        setPswError("Lösenorden matchar inte");
      else setPswError("");
    } else {
      setPswError("");
    }
  }

  return (
    <>
      <Grid
        sx={isMobile ? { p: "10px" } : { p: "30px 70px" }}
        display="flex"
        justifyContent="center"
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          // sx={{ width: "80%" }}
          alignItems="start"
          // alignItems={isMobile ? "center" : "flex-start"}
        >
          <Typography variant="h2">Inställningar</Typography>

          <Grid
            sx={{ width: "100%" }}
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={boxStyle}
              style={!isMobile ? { marginRight: "20px" } : {}}
              width={isMobile ? "100%" : "30%"}
              gap={2}
            >
              <InputLabel htmlFor="password">Ändra lösenord</InputLabel>
              <TextField
                id="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Lösenord"
                value={passwords.password}
                onChange={(e) => handlePasswordChange(e)}
              />
              <TextField
                id="password_confirm"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={pswError}
                error={pswError !== ""}
                placeholder="Bekräfta lösenord"
                value={passwords.password_confirm}
                onChange={(e) => handlePasswordChange(e)}
              />
              <Button
                onClick={updatePassword}
                disabled={
                  // validatePassword(passwords) === PASSWORD_STATES.SHORT ||
                  pswError !== "" ||
                  isNullOrEmpty(passwords.password) ||
                  isNullOrEmpty(passwords.password_confirm)
                }
                variant="contained"
                color="secondary"
              >
                Byt lösenord
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={boxStyle}
              style={!isMobile ? { marginRight: "20px" } : {}}
              width={isMobile ? "100%" : "30%"}
              gap={2}
            >
              <InputLabel htmlFor="newPassword">Ändra emailadress</InputLabel>
              <TextField
                id="email"
                placeholder="Emailadress"
                value={emails.email}
                type="email"
                onChange={(e) => handleEmailChange(e)}
              />
              <TextField
                id="email_confirm"
                value={emails.email_confirm}
                placeholder="Bekräfta emailadress"
                type={"email"}
                onChange={(e) => handleEmailChange(e)}
                helperText={emailError}
                error={emailError !== ""}
                // onChange={(
                //   event: React.ChangeEvent<
                //     HTMLTextAreaElement | HTMLInputElement
                //   >
                // ) => {
                //   setEmails((prev) => {
                //     return {
                //       email_confirm: event.target.value,
                //       email: prev.email,
                //     };
                //   });
                // }}
              />
              <Button
                onClick={updateEmailAddress}
                variant="contained"
                color="secondary"
                disabled={
                  // emails.email.trim() === "" ||
                  // emails.email_confirm.trim() === ""
                  pswError !== "" ||
                  isNullOrEmpty(emails.email) ||
                  isNullOrEmpty(emails.email_confirm)
                }
              >
                Byt emailadress
              </Button>
            </Box>
          </Grid>
          {!isMobile && <Divider style={{ margin: "15px", width: "100%" }} />}
          <Typography variant="h2">Användare</Typography>
          <UsersTable />
          <Typography variant="h2">Administratörer</Typography>
          <UsersTable showAdmin />
          {/* {!isMobile && <Divider style={{ margin: "15px", width: "100%" }} />} */}
          <Grid sx={{ width: "100%" }}>
            <Button
              id="demo-customized-button"
              variant="outlined"
              // onClick={handleClick}
              sx={{
                border: 1,
                borderColor: "#e0e0e0",
                m: "10px 0",
                p: 2,
                borderRadius: 2,
              }}
              startIcon={<LogoutIcon />}
              onClick={() => auth.logout()}
            >
              Logga ut
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
