import { useContext } from "react";
import { MobileStateContext } from "../../contexts/MobileContext";
import {
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CompanyForm from "./CompanyForm";
import { Company } from "../../utils/types";

interface Props {
  open: boolean;
  handleClose: () => void;
  company?: Company;
  getCompanies: () => void;
}

/** STYLE */
const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  overflowY: "scroll",
  maxHeight: "80vh",
  boxShadow: 24,
  p: 4,
};

/**
 * Form to add a new company
 * @returns
 */
export default function CompanyFormModal(props: Props) {
  const { isMobile, isIpad, isDesktop } = useContext(MobileStateContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    overflowY: "scroll",
    p: 4,
    // maxWidth: "600px",
    width: isMobile ? "100%" : "70%",
    height: isMobile ? "100%" : "80%",
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        // sx={[
        //   style,
        //   isIpad && { width: "80vw" },
        //   isMobile && { width: "100vw", height: "100vh" },
        // ]}
        sx={style}
      >
        <CardHeader
          title={
            <Typography variant="h3" align="center">
              {props.company
                ? `Redigera bolag ${props.company.name}`
                : "Lägg till nytt bolag"}
            </Typography>
          }
        />
        <IconButton
          aria-label="Example"
          onClick={props.handleClose}
          sx={closeButtonStyle}
        >
          <CloseIcon color="primary" sx={{ fontSize: 40 }} />
        </IconButton>
        <CardContent>
          <CompanyForm
            company={props.company}
            handleClose={props.handleClose}
            getCompanies={props.getCompanies}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
