import { Typography, Button, Modal, Tooltip, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useAPI } from "../../API/useAPI";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useConfirm } from "material-ui-confirm";

interface Props {
  ids: number[];
  signalFetchJourReports: () => void;
  fetchedArchive: boolean;
}

/**
 * Button to delete report
 *
 *
 * @param {id}
 * @returns
 */
export default function BulkToggleArchivedButton({
  ids,
  signalFetchJourReports,
  fetchedArchive,
}: Props) {
  const snackBar = useContext(SnackbarContext);
  const api = useAPI();
  const confirm = useConfirm();
  const [shouldToggleArchived, setShouldToggleArchived] = useState(false);

  useEffect(() => {
    const handleDelete = async () => {
      if (shouldToggleArchived) {
        setShouldToggleArchived(false);
        const res = await api.jourReports.toggleArchivedMany(ids);
        if (res.error) {
          snackBar.show(
            `Kunde inte ${fetchedArchive ? "dearkivera" : "arkivera"} rapport.`,
            "error"
          );
        } else {
          snackBar.show(
            `Rapport ${fetchedArchive ? "dearkiverad" : "arkiverad"}`,
            "success"
          );
          signalFetchJourReports();
        }
      }
    };

    handleDelete();
  }, [shouldToggleArchived, setShouldToggleArchived, ids, snackBar]);

  const handleButtonPress = () => {
    confirm({
      title: "Är du säker?",
      description: `Du är påväg att ${
        fetchedArchive ? "dearkivera" : "arkivera"
      } ${ids.length} rapporter`,
      confirmationText: `${
        fetchedArchive ? "Dearkivera" : "Arkivera"
      } rapporter`,
      cancellationText: "Avbryt",
      confirmationButtonProps: { autoFocus: true },
      allowClose: true,
    })
      .then(() => {
        console.log("arkivera report!");
        setShouldToggleArchived(true);
      })
      .catch(() => {
        console.log("Not archiving any report!");
      });
  };

  return (
    <Tooltip title="Arkivera/Dearkivera rapporter">
      <IconButton onClick={() => handleButtonPress()}>
        <ArchiveIcon />
      </IconButton>
    </Tooltip>
  );
}
