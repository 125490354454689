import { useContext } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { MobileStateContext } from "../../contexts/MobileContext";

import { Company } from "../../utils/types";

interface Props {
    company: Company;
    changeModal: (string, boolean) => void;
    active: boolean;
    setActiveCompany: (Company) => void;
}
export default function CompanyCard(props: Props) {
    const { isMobile, isDesktop } = useContext(MobileStateContext);

    function handleCompanyClick(isMobile: boolean, company: Company) {
        props.setActiveCompany(company);
        if (!isDesktop) props.changeModal("companyInfo", true);
    }

    return (
        <Grid item xs={isMobile ? 6 : 4}>
            <Button
                variant="outlined"
                onClick={() => handleCompanyClick(isMobile, props.company)}
                sx={[
                    {
                        borderRadius: "10px",
                        p: 2,
                        width: "100%",
                        height: "100%",
                        borderColor: "#e0e0e0",
                        "&:hover": {
                            background: "#f0f0f0",
                            borderColor: "#e0e0e0",
                        },
                    },
                    props.active
                        ? {
                              backgroundColor: "#f0f0f0",
                          }
                        : {},
                ]}
            >
                <Grid sx={{ width: "100%" }}>
                    <Typography
                        align="left"
                        sx={[
                            isMobile
                                ? { textAlign: "center" }
                                : {
                                      marginBottom: 2,
                                  },
                            {
                                overflowWrap: "break-word",
                                fontSize: "20px",
                                textTransform: "none",
                                fontWeight: 400,
                            },
                        ]}
                    >
                        {props.company.name}
                    </Typography>
                    {/* {!isMobile && (
                        <InfoIcons
                            company={props.company}
                            showText={false}
                            align="center"
                        />
                    )} */}
                </Grid>
            </Button>
        </Grid>
    );
}
