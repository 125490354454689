import { useContext, useEffect, useState } from "react";
import {
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Jourare, SuperUserCreate, User, UserUpdate } from "../../utils/types";
import CloseIcon from "@mui/icons-material/Close";

import { userFullname } from "../../utils/helpFunctions";
import { Box } from "@mui/system";
import { useAPI } from "../../API/useAPI";
import { useAuth } from "../../auth/hooks/useAuth";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { MobileStateContext } from "../../contexts/MobileContext";

interface Props {
  open: boolean;
  handleClose: () => void;
  user?: User;
  adminUser: boolean;
  users: User[];
}

/** STYLE */
const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
};

/**
 * Form to add a new user
 * @returns
 */

const emptyUser: UserUpdate = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
};

export default function EditAddUserModal(props: Props) {
  const [userData, setUserData] = useState<UserUpdate>(emptyUser);
  const api = useAPI();
  const snackBar = useContext(SnackbarContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useContext(MobileStateContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    p: 4,
    // maxWidth: "600px",
    width: isMobile ? "100vw" : "70%",
    height: isMobile ? "100%" : "80%",
  };

  useEffect(() => {
    if (props.user) {
      setUserData(props.user);
    }
  }, []);
  console.log(props.adminUser);
  console.log(userData);

  const updateOrCreateUser = async (e) => {
    e.preventDefault();

    console.log(emailExists());

    setLoading(true);
    if (props.user) {
      const res = await api.users.update(props.user.id, userData);
      if (!res.data) {
        snackBar.show("Kunde inte uppdatera användare. Försök igen.", "error");
      } else {
        snackBar.show("Användare uppdaterad!", "success");
        props.handleClose();
      }
    } else {
      // const payload: SuperUserCreate = {
      //   email: userData.email!,
      //   phone: userData.phone,
      //   first_name: userData.first_name,
      //   last_name: userData.last_name,
      //   password: "Hej123",
      // };
      if (emailExists()) {
        snackBar.show("En användare med emailadressen finns redan.", "error");
        return;
      }
      let res;
      if (props.adminUser) {
        res = await api.users.create_super_user({
          email: userData.email!,
          phone: userData.phone,
          first_name: userData.first_name,
          last_name: userData.last_name,
          password: "Hej123",
        });
      } else {
        res = await api.users.create_standard_user({
          email: userData.email,
          phone: userData.phone,
          first_name: userData.first_name,
          last_name: userData.last_name,
        });
      }
      if (!res.data) {
        console.log(res);
        snackBar.show("Kunde inte skapa användare. Försök igen.", "error");
      } else {
        snackBar.show(
          "Användare skapad! Ett email har skickats till " + userData.email,
          "success"
        );
        props.handleClose();
      }
    }
    setLoading(false);
  };
  console.log(props.users);

  function emailExists() {
    const existingUser = props.users.find(
      (user) => user.email === userData.email
    );
    console.log(`Existing: ${existingUser}`);
    return existingUser;
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <form onSubmit={updateOrCreateUser}>
          <CardHeader
            title={
              <Typography variant="h3" align="center">
                {props.user
                  ? "Redigera användare"
                  : props.adminUser
                  ? "Lägg till ny administratör"
                  : "Lägg till ny användare"}
              </Typography>
            }
          />
          <IconButton
            aria-label="Example"
            onClick={props.handleClose}
            sx={closeButtonStyle}
          >
            <CloseIcon color="primary" sx={{ fontSize: 40 }} />
          </IconButton>
          <CardContent>
            <Box
              flexDirection={"column"}
              display={"flex"}
              gap={2}
              justifyContent="center"
              alignItems={"center"}
            >
              <Box
                flexDirection={isMobile ? "column" : "row"}
                width="100%"
                display={"flex"}
                gap={2}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="Förnamn"
                  required
                  value={userData.first_name || ""}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        first_name: event.target.value,
                      };
                    });
                  }}
                />
                <TextField
                  fullWidth
                  label="Efternamn"
                  value={userData.last_name || ""}
                  required
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        last_name: event.target.value,
                      };
                    });
                  }}
                />
              </Box>
              <Box
                flexDirection={isMobile ? "column" : "row"}
                width={"100%"}
                display={"flex"}
                gap={2}
              >
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  required
                  value={userData.email || ""}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        email: event.target.value,
                      };
                    });
                  }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Telefon"
                  value={userData.phone || ""}
                  required
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        phone: event.target.value,
                      };
                    });
                  }}
                />
              </Box>
              <Button
                // onClick={updateOrCreateUser}
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
              >
                {loading ? <CircularProgress /> : "Spara"}
              </Button>
              {!props.user && (
                <Alert severity="success">
                  <AlertTitle>
                    Email kommer att skickas till användare
                  </AlertTitle>
                  När du skapar en ny användare skickas ett mail med
                  inloggningsinformation till den mailadress du har angivit.
                </Alert>
              )}
            </Box>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
