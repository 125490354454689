import { CircularProgress, Grid } from "@mui/material";
export default function LoadingScreen() {
  return (
    <Grid
      sx={{ width: "100vw", height: "100vh" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  );
}
