import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GenerateOrderPdfButton from "./GenerateOrderPdfButton";
import DeleteReportButton from "./DeleteReportButton";
import ArchiveReportButton from "./ArchiveReportButton";
import { useAuth } from "../../auth/hooks/useAuth";

interface Prop {
  id: number;
  custom_id: string;
  signalFetchJourReports: () => void;
  fetchedArchive: boolean;
}

export default function ReportActionMenu({
  id,
  custom_id,
  signalFetchJourReports,
  fetchedArchive,
}: Prop) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isAdmin } = useAuth();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <GenerateOrderPdfButton id={id} custom_id={custom_id} />
        </MenuItem>
        <MenuItem>
          <DeleteReportButton
            id={id}
            signalFetchJourReports={signalFetchJourReports}
          />
        </MenuItem>
        {isAdmin && (
          <MenuItem>
            <ArchiveReportButton
              id={id}
              signalFetchJourReports={signalFetchJourReports}
              fetchedArchive={fetchedArchive}
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
