export interface CompanyReport {
  filename: string;
  headers: string[];
  data: string[][];
}

export interface Order {
  id?: number;
  title: string;
  date: string;
  name: string;
  address: string;
  company: string;
  [key: string]: any;
}

export interface Jourare {
  name: string;
  email: string;
}

export interface Address {
  id: number;
  address_street: string;
  address_number: number;
  cost_center: string;
  company: Company;
  [key: string]: any;
}

export interface Company {
  id: number;
  name: string;
  billing_address?: string;
  billing_postal?: number;
  billing_city?: string;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  created_at?: string;
  [key: string]: any;
}

export interface Addresses {
  id: number;
  address_street: string;
  address_number: number;
  cost_center: string;
  company: Companies;
}

export interface AddressCreate {
  company: number;
  address_street: string;
  address_number: number[];
  cost_center: string;
}
export interface AddressUpdate {
  company: number;
  address_street: string;
  address_number: number[];
  cost_center: string;
}
export interface AddressUpdateSingle {
  company: number;
  address_street: string;
  address_number: number;
  cost_center: string;
}

export interface AddressBulkDelete {
  ids: number[];
}

export interface AddressBulkAlterCostCenter {
  ids: number[];
  new_cost_center: string;
}

export interface Companies {
  id: number;
  name: string;
  contact_email: string;
  created_at: string;
  contact_phone: string;
  contact_name: string;
  billing_address?: string;
  billing_postal?: number;
  billing_city?: string;
  archived?: boolean;
}

export interface CompanyCreate {
  name?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  billing_address?: string;
  billing_postal?: number;
  billing_city?: string;
  archived?: boolean;
}
export interface CompanyUpdate {
  name?: string;
  contact_email?: string;
  contact_name?: string;
  contact_phone?: string;
  billing_address?: string;
  billing_postal?: number;
  billing_city?: string;
  archived?: boolean;
}
// Handle orders
export interface PaginationInfo {
  totalPageCount?: number;
  activePage: number;
  objectsPerPage: number;
  totalObjectsCount: number;
  start: number;
  end: number;
  [key: string]: any;
}

export interface HeadCell {
  id: string;
  label: string;
}

// JOUR
export interface JourReport {
  custom_id?: number;
  apartment_number: number;
  address: string;
  port_id?: string;

  tenant_name: string;
  tenant_phone: string;
  debit_tenant: boolean;

  worker: string;
  additional_worker?: string;

  total_hours: number;
  additional_total_hours: number;

  date: Date;
  additional_date: Date;

  defect_description: string;
  actions_made: string;
  actions_to_be_made: string;
  [key: string]: any;
}

export interface JourReports {
  id: number;
  custom_id: string;
  apartment_number: number;
  address: Addresses;
  port_id?: string;

  tenant_name: string;
  tenant_phone: string;
  debit_tenant: boolean;

  worker: JourReportWorker;
  additional_workers?: JourReportWorker[];

  total_hours: number;

  created_at: string;

  defect_description: string;
  actions_made: string;
  actions_to_be_made: string;
  attahments: ReportAttachment[];
  archived: boolean;
}

export interface JourReportWorkerCreate {
  id: number;
  date: string;
  total_hours: number;
}
export interface JourReportWorker {
  worker: User;
  date: string; // YYYY-MM-DD
  total_hours: number;
  date_is_holiday?: boolean;
}

export interface JourReportCreate {
  apartment_number: string;
  address: number;
  port_id?: string;

  tenant_name: string;
  tenant_phone: string;
  debit_tenant: boolean;

  worker: JourReportWorkerCreate;
  additional_workers?: JourReportWorkerCreate[];

  defect_description: string;
  actions_made: string;
  actions_to_be_made: string;
}
export interface JourReportUpdate {
  apartment_number?: number;
  address?: number;
  port_id?: string;

  tenant_name?: string;
  tenant_phone?: string;
  debit_tenant?: boolean;

  worker?: JourReportWorker;
  additional_worker?: JourReportWorker[];

  defect_description?: string;
  actions_made?: string;
  actions_to_be_made?: string;
}

export interface JourReportBulkDelete {
  ids: number[];
}

export interface ReportAttachment {
  file_info: {
    name: string;
    url: string;
    size: number;
  };
}

// *** AUTH ***

export type User = {
  id: number;
  email: string;
  role: number;
  first_name?: string;
  last_name?: string;
  phone: string;
  created_date: string;
  modified_date: string;
  send_email_notifications: boolean;
};
export type Token = {
  access: string | null;
  refresh: string | null;
};

export type CreateUser = {
  email: string;
  password: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  send_email_notifications?: boolean;
};

export type UserCreate = {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
};

export type UserCreateWithoutPassword = {
  email: string;

  first_name: string;
  last_name: string;
  phone: string;
};
export type SuperUserCreate = {
  email: string;
  password: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
};
export type UserUpdate = {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  send_email_notifications?: boolean;
};

export type ChangePasswordRequest = {
  id: number;
  password: string;
  password_confirm: string;
};

// types to be used with /auth/password_reset, /auth/password_reset/validate and /auth/password_reset/confirm
export type ResetPasswordRequest = {
  email: string; // user email
};

export type ValidateTokenRequest = {
  token: string;
  password: string;
};
export type ConfirmResetPasswordRequest = {
  token: string;
  password: string;
};

export type JourDate = {
  id: number;
  worker: Jourare;
  date: string; //YY-MM-DD
  date_is_holiday?: boolean;
};

export type JourDateCreate = {
  worker: number;

  date: string[]; //YY-MM-DD
};
export type JourDateUpdate = {
  worker?: number;

  date?: string[]; //YY-MM-DD
};

// the different fields on a jourrepport by which the backend
// can sort
// the sorting logic for each of these fields needs to be implemented in the backend
export enum SortColumn {
  // sort numerically on year then on id
  CustomId = "custom_id",
  // sort chronologically
  Date = "date",
  // sort lexiographically
  Name = "name",
  // sort lexiographically
  // get the company name from the address object
  Company = "company",
}

export type SortDirection = "asc" | "desc" | null | undefined;
