import { Typography, Button, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import { useAPI } from "../../API/useAPI";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../contexts/SnackbarProvider";
import { useConfirm } from "material-ui-confirm";
import ArchiveIcon from "@mui/icons-material/Archive";

interface Props {
  id: number;
  signalFetchJourReports: () => void;
  fetchedArchive: boolean;
}

/**
 * Button to delete report
 *
 *
 * @param {id, refetchReports}
 * @returns
 */
export default function ArchiveReportButton({
  id,
  signalFetchJourReports,
  fetchedArchive,
}: Props) {
  const snackBar = useContext(SnackbarContext);
  const api = useAPI();
  const confirm = useConfirm();
  const [archiveReport, setArchiveReport] = useState(false);

  useEffect(() => {
    const handleArchive = async () => {
      if (archiveReport) {
        setArchiveReport(false);

        const res = await api.jourReports.toggleArchived(id);

        if (res.error) {
          snackBar.show(
            `Kunde inte ${fetchedArchive ? "dearkivera" : "arkivera"} rapport.`,
            "error"
          );
        } else {
          snackBar.show(
            `Rapport ${fetchedArchive ? "dearkiverad" : "arkiverad"}`,
            "success"
          );
        }
        signalFetchJourReports();
      }
    };

    handleArchive();
  }, [archiveReport, setArchiveReport, id, snackBar, fetchedArchive]);

  const handleButtonPress = () => {
    confirm({
      title: "Är du säker?",
      confirmationText: `${fetchedArchive ? "Dearkivera" : "Arkivera"} rapport`,
      cancellationText: "Avbryt",
      confirmationButtonProps: { autoFocus: true },
      allowClose: true,
    })
      .then(() => {
        console.log("archive/dearchive report!");
        setArchiveReport(true);
      })
      .catch(() => {
        console.log("not doing anything");
      });
  };

  return (
    <Button
      component={Link}
      to=""
      sx={{
        border: 1,
        borderColor: "info.light",
        borderRadius: "10px",
      }}
      startIcon={<ArchiveIcon />}
      variant="outlined"
      onClick={handleButtonPress}
    >
      <Typography>
        {fetchedArchive ? "Dearkivera rapport" : "Arkivera rapport"}
      </Typography>
    </Button>
  );
}
